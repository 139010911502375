import LayoutViewWrapperNarrow from "@/layouts/LayoutViewWrapperNarrow";
import LayoutViewWrapper from "@/layouts/LayoutViewWrapper";
import LayoutViewWrapperBlock11 from "@/layouts/LayoutViewWrapperBlock11";
import TheLayoutViewContent from "@/layouts/TheLayoutViewContent";
import BlockContent21 from "@/layouts/BlockContent21";
import BlockContent12 from "@/layouts/BlockContent12";
// import LayoutViewContentBlock11 from "@/layouts/LayoutViewContentBlock11";

export default [
  {
    name: "the-layout-view-content",
    component: TheLayoutViewContent,
  },
  {
    name: "layout-view-wrapper-narrow",
    component: LayoutViewWrapperNarrow,
  },
  {
    name: "layout-view-wrapper",
    component: LayoutViewWrapper,
  },
  {
    name: "block-content-21",
    component: BlockContent21,
  },
  {
    name: "block-content-12",
    component: BlockContent12,
  },
  // {
  //   name: "layout-view-content-block-11",
  //   component: LayoutViewContentBlock11,
  // },
  {
    name: "layout-view-wrapper-block-11",
    component: LayoutViewWrapperBlock11,
  },
];
