import { UPDATE_USER_WISHLIST } from "@/graphql/mutations/userWishlist";
import { GET_APPLICATIONS_NANNY } from "@/graphql/queries/applicationNanny";
import { GET_USER_WISHLIST } from "@/graphql/queries/userWishlist";
import { prepareApplicationNannyInstance } from "@/helpers";
import { apolloClient } from "@/plugins/apollo";
import { defineStore } from "pinia";

export const USER_WISHLIST_STORE_NAME = "USER_WISHLIST_STORE";

export const useUserWishlistStore = defineStore(USER_WISHLIST_STORE_NAME, {
  state: () => ({
    _id: null,
    user_id: "",
    applications: {
      nanny_ids: [],
      nanny_ids_populated_on_client: [],
    },
    offers: {
      nanny_ids: [],
      nanny_ids_populated_on_client: [],
    },
    class: "nanny",
    type: "application",
    storeMeta: {
      isInitializing: false,
      isInitialized: false,
      isLoading: false,
      isPopulating: false,
      isUpdating: false,
      error: null,
    },
  }),

  getters: {
    currentWishlistItems() {
      let wishlistItemsRaw;
      let wishlistItemsReady;
      let wishlistIsFetching;
      const wishlistType = this.type;
      const wishlistClass = this.class;

      if (wishlistType === "application") {
        if (wishlistClass === "nanny") {
          wishlistItemsRaw = this.applications.nanny_ids_populated_on_client;
          wishlistIsFetching =
            this.storeMeta.isLoading || this.storeMeta.isPopulating;
          wishlistItemsReady = wishlistItemsRaw.flatMap((applicationNanny) => {
            try {
              return prepareApplicationNannyInstance(applicationNanny);
            } catch (error) {
              console.log(error);
              return [];
            }
          });
        }
      }

      // if (wishlistType === "offer") {
      //   if (wishlistClass === "nanny") {
      //     wishlistItemsRaw = this.offers.nanny.value;
      //     wishlistIsFetching = this.offers.nanny.isFetching;
      //     wishlistItemsReady = wishlistItemsRaw.flatMap((offerNanny) => {
      //       try {
      //         return prepareOfferNannyInstance(offerNanny);
      //       } catch (error) {
      //         console.log(error);
      //         return [];
      //       }
      //     });
      //   }
      // }

      return {
        valueRaw: wishlistItemsRaw,
        valueReady: wishlistItemsReady,
        class: wishlistClass,
        type: wishlistType,
        isFetching: wishlistIsFetching,
      };
    },
  },

  actions: {
    resetStore() {
      console.log("RESET_USER_WISHLIST_STORE");
      this.$reset();
    },

    async initUserWishlist(user_wishlist_id) {
      try {
        if (this.storeMeta.isInitializing === true) {
          console.error("UserWishlist is already initializing!");
          return null;
        }

        if (this.storeMeta.isInitialized === true) {
          throw new Error("UserWishlist is already initialized!");
        }

        if (user_wishlist_id === null) {
          throw new Error(
            `Received 'null' instead of 'user_wishlist_id'! BTW, wishlist was already initialized...`
          );
        }

        this.storeMeta.isInitializing = true;
        this.storeMeta.isLoading = true;

        const queryUserWishlist = apolloClient.watchQuery({
          query: GET_USER_WISHLIST,
          variables: {
            id: user_wishlist_id,
          },
          fetchPolicy: "cache-and-network",
        });

        const setNewData = (data) => {
          if (data === null) {
            throw new Error("Response 'Data' is null!");
          }

          if ("userWishlist" in data === false) {
            throw new Error(
              "Response 'Data' misesses 'userWishlist' property!"
            );
          }
          console.log("New wishlist", data);

          const userWishlist = data.userWishlist;
          this._id = userWishlist._id;
          this.user_id = userWishlist.user_id;
          this.applications.nanny_ids = userWishlist.applications.nanny_ids;
          this.offers.nanny_ids = userWishlist.offers.nanny_ids;
        };

        const { data, error, errors } = await queryUserWishlist.result();

        if (error) {
          throw error;
        }

        if (errors) {
          console.log(errors);
          throw new Error("Graphql errors");
        }

        setNewData(data);

        queryUserWishlist.subscribe(
          (result) => {
            setNewData(result.data);
          },
          (error) => {
            this.storeMeta.isInitializing = false;
            this.storeMeta.isLoading = false;
            throw error;
          }
        );

        this.storeMeta.isInitialized = true;
        this.storeMeta.isInitializing = false;
        this.storeMeta.isLoading = false;

        return "OK";
      } catch (error) {
        this.storeMeta.isInitializing = false;
        this.storeMeta.isLoading = false;
        throw error;
      }
    },

    // Populate <itemClass>_ids_populated_on_client
    async populateUserWishlist() {
      try {
        this.storeMeta.isPopulating = true;

        if (this.storeMeta.isInitializing === true) {
          const waitForInit = () => {
            return new Promise((resolve) => {
              const killPopulation = () => {
                throw new Error(`You should init wishlist first!`);
              };

              const checkInit = (checker, killer) => {
                if (this.storeMeta.isInitialized === true) {
                  clearTimeout(killer);
                  clearInterval(checker);
                  resolve();
                }
              };

              const killer = setTimeout(killPopulation, 5000);
              const checker = setInterval(
                () => checkInit(checker, killer),
                200
              );
            });
          };

          await waitForInit();
        }

        if (this.storeMeta.isInitialized === false) {
          throw new Error(`You should init wishlist first!`);
        }

        const user_wishlist_id = this._id;

        if (user_wishlist_id === null) {
          throw new Error(
            `Received 'null' instead of 'user_wishlist_id'! BTW, wishlist was already initialized...`
          );
        }

        if (this.applications.nanny_ids.length > 0) {
          const queryApplicationsNanny = apolloClient.watchQuery({
            query: GET_APPLICATIONS_NANNY,
            variables: {
              ids: this.applications.nanny_ids,
              populatePath:
                "user_profile_id_populated recommendation_ids_populated",
            },
            fetchPolicy: "cache-and-network",
          });

          queryApplicationsNanny.subscribe({
            next(result) {
              const { data } = result;
              console.log(
                "New populatate for ApplicationsNanny (UPDATE): ",
                data
              );
              populateApplicationsNanny(data);
            },
            error(err) {
              console.log(err);
            },
          });

          const populateApplicationsNanny = (data) => {
            if ("applicationsNanny" in data === false) {
              throw new Error(
                "Response 'Data' misesses 'applicationsNanny' property!"
              );
            }

            const applicationsNannyRaw = data.applicationsNanny;
            const applicationsNannyIdsStore = this.applications.nanny_ids;
            this.applications.nanny_ids_populated_on_client =
              applicationsNannyIdsStore.map((id) => {
                const index = applicationsNannyRaw.findIndex(
                  (application) => application._id === id
                );
                return { ...applicationsNannyRaw[index] };
              });

            // [
            //   ...applicationsNannyRaw,
            // ];
          };
        }
      } finally {
        this.storeMeta.isPopulating = false;
      }
    },

    async updateUserWishlist() {
      try {
        this.storeMeta.isUpdating = true;

        const user_wishlist_id = this._id;

        if (this.storeMeta.isInitialized === false) {
          throw new Error(`Init wishlist first!`);
        }

        if (user_wishlist_id === null) {
          throw new Error(
            `Recieved 'null' instead of 'user_wishlist_id'! BTW, store was already initialized...`
          );
        }

        const { data } = await apolloClient.mutate({
          mutation: UPDATE_USER_WISHLIST,
          variables: {
            id: user_wishlist_id,
            payload: {
              applications: {
                nanny_ids: this.applications.nanny_ids,
              },
              offers: {
                nanny_ids: this.offers.nanny_ids,
              },
            },
          },
        });

        if (data === null) {
          throw new Error("Response 'Data' is 'null'!");
        }

        if ("updateUserWishlist" in data === false) {
          throw new Error(
            "Response 'Data' misesses 'updateUserWishlist' property!"
          );
        }

        if ("_id" in data.updateUserWishlist === false) {
          throw new Error("'Data.updateUserWishlist' misesses '_id' property!");
        }
      } finally {
        this.storeMeta.isUpdating = false;
      }
    },

    async pushApplicationToWishlist(application_id) {
      const ids = this.applications.nanny_ids;
      if (ids.includes(application_id)) {
        return;
      }
      ids.unshift(application_id);
      await this.updateUserWishlist();
    },

    async pullApplicationFromWishlist(application_id) {
      const index = this.applications.nanny_ids.findIndex(
        (id) => id === application_id
      );

      if (index === -1) {
        return null;
      }

      this.applications.nanny_ids.splice(index, 1);

      const indexPopulated =
        this.applications.nanny_ids_populated_on_client.findIndex(
          ({ _id: id }) => id === application_id
        );

      if (indexPopulated !== -1) {
        this.applications.nanny_ids_populated_on_client.splice(
          indexPopulated,
          1
        );
      }

      await this.updateUserWishlist();
    },
  },
});
