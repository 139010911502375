export const loadScript = (url) => {
  return new Promise((res) => {
    // Check if script was already loaded
    const scriptsLoaded = Array.from(document.querySelectorAll("script"));
    const scriptsLoadedUrls = scriptsLoaded.map((script) => script.src);

    // Resolve if loaded already
    if (scriptsLoadedUrls.includes(url)) {
      return res();
    }

    // Load script
    const script = document.createElement("script");
    script.setAttribute("src", url);
    document.head.appendChild(script);

    // Resolve on load
    script.onload = () => res();
  });
};

export const removeScript = (url) => {
  const scripts = Array.from(document.getElementsByTagName("script"));
  scripts.forEach((script) => {
    if (script.src === url) script.remove();
  });
};
