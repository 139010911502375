// Validating img file
export const isImgFileValid = (file, requirements) => {
  // Ensure file type is image
  if (file.type.indexOf("image/") === -1) {
    alert("Please select an image file");
    return false;
  }

  // Ensure image size is less than requirements.maxSizeBytes
  if (requirements.maxSizeBytes) {
    if (file.size > requirements.maxSizeBytes) {
      alert("File is too big! Please select a smaller one!");
      return false;
    }
  }
  return true;
};
