<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
  >
    <path
      d="M20.5595 3.9101C21.1495 4.5001 21.1495 5.4501 20.5595 6.0301L16.6695 9.9201L18.7895 19.1101L17.3795 20.5301L13.4995 13.1001L9.59945 17.0001L9.95945 19.4701L8.88945 20.5301L7.12945 17.3501L3.93945 15.5801L4.99945 14.5001L7.49945 14.8701L11.3695 11.0001L3.93945 7.0901L5.35945 5.6801L14.5495 7.8001L18.4395 3.9101C18.9995 3.3301 19.9995 3.3301 20.5595 3.9101Z"
      fill="black"
    />
  </svg>
</template>

<style scoped>
.svg-icon path {
  fill: var(--default-font-color);
}
</style>
