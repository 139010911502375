// Docs -> Privacy Policy
export const ROUTE_DOCS_PRIVACY_POLICY_NAME = "DocsPrivacyPolicy";
export const ROUTE_DOCS_PRIVACY_POLICY_PATH = "/privacy-policy";

// Docs -> Terms and Conditions
export const ROUTE_DOCS_TERMS_AND_CONDITIONS_NAME = "DocsTermsAndConditions";
export const ROUTE_DOCS_TERMS_AND_CONDITIONS_PATH = "/terms-and-conditions";

// Docs -> Cookies
export const ROUTE_DOCS_COOKIES_NAME = "DocsCookies";
export const ROUTE_DOCS_COOKIES_PATH = "/cookies";
