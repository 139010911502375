import authApi from "@/api/auth";
import { useUserStore } from "@/stores/user";
import { useUserProfileStore } from "@/stores/userProfile";
import jwtService from "./jwtService";
import piniaService from "./piniaService";

const authService = {
  async loginGoogle(idToken) {
    console.log(">>>LOGIN GOOGLE START<<<");
    const { jwt, user_id, user_profile_id, user_wishlist_id } =
      await authApi.loginGoogle(idToken);
    this._login(jwt, user_id, user_profile_id, user_wishlist_id);
    console.log(">>>LOGIN GOOGLE END<<<");
  },

  async loginLocal(email, password) {
    console.log(">>>LOGIN LOCAL START<<<");
    const { jwt, user_id, user_profile_id, user_wishlist_id } =
      await authApi.loginLocal(email, password);
    this._login(jwt, user_id, user_profile_id, user_wishlist_id);
    console.log(">>>LOGIN LOCAL END<<<");
  },

  async preRegisterLocal(loginEmail, password) {
    console.log(">>>PRE REGISTER LOCAL START<<<");
    const { oneTimeCode } = await authApi.preRegisterLocal({
      loginEmail,
      password,
    });

    console.log(">>>PRE REGISTER LOCAL END<<<");
    return {
      oneTimeCode,
    };
  },

  async registerLocal(oneTimeCodeValue) {
    console.log(">>>REGISTER LOCAL START<<<");
    const userStore = useUserStore();
    const userProfileStore = useUserProfileStore();
    const { jwt, user_id, user_profile_id, user_wishlist_id } =
      await authApi.registerLocal(
        oneTimeCodeValue,
        userStore,
        userProfileStore
      );
    await this._login(jwt, user_id, user_profile_id, user_wishlist_id);
    console.log(">>>REGISTER LOCAL END<<<");
  },

  async registerGoogle(idToken) {
    console.log(">>>REGISTER GOOGLE START<<<");
    const userStore = useUserStore();
    const userProfileStore = useUserProfileStore();
    const { jwt, user_id, user_profile_id, user_wishlist_id } =
      await authApi.registerGoogle(idToken, userStore, userProfileStore);
    await this._login(jwt, user_id, user_profile_id, user_wishlist_id);
    console.log(">>>REGISTER GOOGLE END<<<");
  },

  logout() {
    console.log(">>>LOGOUT START<<<");
    // Remove Access Token
    jwtService.removeAccessTokenFromLS();
    // Reset users stores
    piniaService.resetUserStores();
    console.log(">>>LOGOUT END<<<");
  },

  _cacheAccessToken(token) {
    jwtService.setAccessTokenToLS(token);
  },

  async _login(jwt, user_id, user_profile_id, user_wishlist_id) {
    // Caching jwt Access Token
    const accessToken = jwt.accessToken;
    this._cacheAccessToken(accessToken);
    // Init user stores
    await piniaService.initUserStores(
      user_id,
      user_profile_id,
      user_wishlist_id
    );
  },
};

export default authService;
