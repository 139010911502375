<template>
  <p v-if="text.length > 0" class="p" :class="isSmall ? 'p--small' : ''">
    {{ text }}
  </p>
  <p
    v-else-if="html.length > 0"
    v-html="html"
    class="p"
    :class="isSmall ? 'p--small' : ''"
  />
  <p v-else class="p" :class="isSmall ? 'p--small' : ''">
    <slot />
  </p>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    html: {
      type: String,
      default: "",
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.p {
  display: block;
  line-height: auto;
  white-space: pre-line;
}
.p--small {
  font: var(--font-small);
}
</style>
