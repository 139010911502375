const prepareListStoreParsed = (storeParsed, defaultStore) => {
  // TODO: check out the work of files in photoMain and Gallery !
  return {
    ...storeParsed,
    offers: {
      nanny: {
        ...defaultStore.offers.nanny,
        filter: {
          ...storeParsed.offers.nanny.filter,
        },
      },
    },
    applications: {
      nanny: {
        ...defaultStore.applications.nanny,
        filter: {
          ...storeParsed.applications.nanny.filter,
        },
      },
    },
    storeMeta: {
      ...defaultStore.storeMeta,
    },
  };
};

const listStoreService = {
  prepareListStoreParsed,
};

export default listStoreService;
