// Auth --> Registration
export const ROUTE_REGISTRATION_NAME = "AuthRegistration";
export const ROUTE_REGISTRATION_PATH = "/registration";

// Starting page (choosing registration strategy google/local (email))
// Auth --> Registration --> Start
export const ROUTE_REGISTRATION_START_PATH = "";
export const ROUTE_REGISTRATION_START_NAME = "AuthRegistrationStart";

// Email and password registration page
// Auth --> Registration --> Email
export const ROUTE_REGISTRATION_EMAIL_PATH = "email";
export const ROUTE_REGISTRATION_EMAIL_NAME = "AuthRegistrationEmail";

// Asking userType (client or contractor)
// Auth --> Registration --> Identification
export const ROUTE_REGISTRATION_IDENTIFICATION_NAME =
  "AuthRegistrationIdentification";
export const ROUTE_REGISTRATION_IDENTIFICATION_PATH = "identification";

// Asking profile details: name, ZIP, phone etc.
// Auth --> Registration --> Details
export const ROUTE_REGISTRATION_DETAILS_PATH = "details";
export const ROUTE_REGISTRATION_DETAILS_NAME = "AuthRegistrationDetails";

// Registration succeded. Welcome page with links to the next steps
// Auth --> Registration --> Welcome
export const ROUTE_REGISTRATION_WELCOME_PATH = "welcome";
export const ROUTE_REGISTRATION_WELCOME_NAME = "AuthRegistrationWelcome";
