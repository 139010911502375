import { UPDATE_USER_PROFILE } from "@/graphql/mutations/userProfile";
import { GET_USER_PROFILE } from "@/graphql/queries/userProfile";
import { apolloClient } from "@/plugins/apollo";
import { defineStore } from "pinia";
import { useUserStore } from "./user";

export const USER_PROFILE_STORE_NAME = "USER_PROFILE_STORE";

export const useUserProfileStore = defineStore(USER_PROFILE_STORE_NAME, {
  state: () => ({
    _id: null,
    user_id: null,
    nameFirst: "",
    nameLast: null,
    nameBusiness: null,
    zip: "",
    zipLocationPoint: null,
    sex: null,
    dob: null, // Date
    phone: null,
    lastSeen: () => Date.now(),
    storeMeta: {
      isInitializing: false,
      isInitialized: false,
      isLoading: false,
      isUpdating: false,
      error: null,
    },
  }),

  actions: {
    resetStore() {
      console.log("RESET_USER_PROFILE_STORE");
      this.$reset();
    },

    async initUserProfile(user_profile_id) {
      try {
        if (this.storeMeta.isInitializing === true) {
          console.error("UserProfile is already initializing!");
          return null;
        }

        if (this.storeMeta.isInitialized === true) {
          throw new Error("UserProfile is already initialized!");
        }

        if (user_profile_id === null) {
          throw new Error(
            `Received 'null' instead of 'user_profile_id'! BTW, profile was already initialized...`
          );
        }

        this.storeMeta.isInitializing = true;
        this.storeMeta.isLoading = true;

        const queryUserProfile = apolloClient.watchQuery({
          query: GET_USER_PROFILE,
          variables: {
            id: user_profile_id,
          },
          fetchPolicy: "cache-and-network",
        });

        const setNewData = (data) => {
          if (data === null) {
            throw new Error("Response 'Data' is null!");
          }

          if ("userProfile" in data === false) {
            throw new Error("Response 'Data' misesses 'userProfile' property!");
          }

          console.log("New profile", data);

          const userProfile = data.userProfile;
          this._id = userProfile._id;
          this.user_id = userProfile.user_id;
          this.nameFirst = userProfile.nameFirst;
          this.nameLast = userProfile.nameLast;
          this.nameBusiness = userProfile.nameBusiness;
          this.zip = userProfile.zip;

          if (userProfile.zipLocationPoint) {
            this.zipLocationPoint = {
              type: userProfile.zipLocationPoint.type,
              coordinates: userProfile.zipLocationPoint.coordinates,
            };
          } else {
            this.zipLocationPoint = null;
          }

          this.sex = userProfile.sex;
          if (userProfile.dob === null) {
            this.dob = null;
          } else {
            this.dob = new Date(userProfile.dob);
          }
          this.phone = userProfile.phone;
        };

        const { data, error, errors } = await queryUserProfile.result();

        if (error) {
          throw error;
        }

        if (errors) {
          console.log(errors);
          throw new Error("Graphql errors");
        }

        setNewData(data);

        queryUserProfile.subscribe(
          (result) => {
            setNewData(result.data);
          },
          (error) => {
            this.storeMeta.isInitializing = false;
            this.storeMeta.isLoading = false;
            throw error;
          }
        );

        this.storeMeta.isInitialized = true;
        this.storeMeta.isInitializing = false;
        this.storeMeta.isLoading = false;

        return "OK";
      } catch (error) {
        this.storeMeta.isInitializing = false;
        this.storeMeta.isLoading = false;
        throw error;
      }
    },

    async updateUserProfile() {
      try {
        this.storeMeta.isUpdating = true;

        const user_profile_id = this._id;

        if (this.storeMeta.isInitialized === false) {
          throw new Error(`Init profile first!`);
        }

        if (user_profile_id === null) {
          throw new Error(
            `Recieved 'null' instead of 'user_profile_id'! BTW, store was already initialized...`
          );
        }

        const { data } = await apolloClient.mutate({
          mutation: UPDATE_USER_PROFILE,
          variables: {
            id: user_profile_id,
            payload: {
              nameFirst: this.nameFirst,
              nameLast: this.nameLast,
              nameBusiness: this.nameBusiness,
              zip: this.zip,
              zipLocationPoint: this.zipLocationPoint,
              sex: this.sex,
              dob: this.dob,
              phone: this.phone,
              lastSeen: this.lastSeen,
            },
          },
        });

        if (data === null) {
          throw new Error("Response 'Data' is 'null'!");
        }

        if ("updateUserProfile" in data === false) {
          throw new Error(
            "Response 'Data' misesses 'updateUserProfile' property!"
          );
        }

        if ("_id" in data.updateUserProfile === false) {
          throw new Error("'Data.updateUserProfile' misesses '_id' property!");
        }
      } finally {
        this.storeMeta.isUpdating = false;
      }
    },

    getUserLocation({ adTypePriority, adClass }) {
      const DEFAULT_CITY_COORDINATES = [17.0385376, 51.1078852]; // Wroclaw center

      const user = useUserStore();
      const userOffersNanny = user.offers.nanny_ids_populated;
      const userApplicationsNanny = user.applications.nanny_id_populated;

      // TODO: merge this block with the next one
      if (adTypePriority === "offer") {
        if (adClass === "nanny") {
          if (userOffersNanny && userOffersNanny.length > 0) {
            return {
              type: userOffersNanny[0].details.location.point.type,
              coordinates:
                userOffersNanny[0].details.location.point.coordinates,
            };
          }

          if (userApplicationsNanny) {
            return {
              type: userApplicationsNanny.details.location.point.type,
              coordinates:
                userApplicationsNanny.details.location.point.coordinates,
            };
          }

          if (this.zipLocationPoint) {
            return this.zipLocationPoint;
          }

          // Default city location
          return {
            coordinates: DEFAULT_CITY_COORDINATES,
            type: "Point",
          };
        }
      }

      // TODO: merge this block
      if (adTypePriority === "application") {
        if (adClass === "nanny") {
          if (userApplicationsNanny) {
            return {
              type: userApplicationsNanny.details.location.point.type,
              coordinates:
                userApplicationsNanny.details.location.point.coordinates,
            };
          }

          if (userOffersNanny && userOffersNanny.length > 0) {
            return {
              type: userOffersNanny[0].details.location.point.type,
              coordinates:
                userOffersNanny[0].details.location.point.coordinates,
            };
          }

          if (this.zipLocationPoint) {
            return this.zipLocationPoint;
          }

          // Default city location
          return {
            coordinates: DEFAULT_CITY_COORDINATES,
            type: "Point",
          };
        }
      }
    },

    // async deleteUserProfile() {},
  },
});
