import gql from "graphql-tag";

export const GET_USER_PROFILE = gql`
  query UserProfile($id: ID!) {
    userProfile(_id: $id) {
      _id
      user_id
      nameFirst
      nameLast
      nameBusiness
      zip
      zipLocationPoint {
        type
        coordinates
      }
      sex
      dob
      phone
      lastSeen
    }
  }
`;
