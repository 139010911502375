import gql from "graphql-tag";

export const GET_USER = gql`
  query User($id: ID!, $populate: PopulateInput) {
    user(_id: $id, populate: $populate) {
      _id
      user_profile_id
      wishlist_id
      email
      emailIsVerified
      emailIsVerificationLinkSent
      role
      userType
      accountType
      websiteSettings {
        country
        language
        city
      }
      phoneVerifAttemptsLeft
      applications {
        nanny_id
        nanny_id_populated {
          _id
          user_id
          user_profile_id
          user_profile_id_populated {
            _id
            user_id
            nameFirst
            nameLast
            nameBusiness
            zip
            sex
            dob
            phone
            lastSeen
          }
          response_ids
          review_ids
          recommendation_ids
          meta {
            visitor_user_ids
            visitsAll
            visitsUnique
            clicksCtaUnique
            status
            createdAt
            refreshedAt
          }
          details {
            description
            location {
              city
              address
              point {
                type
                coordinates
              }
            }
            rate {
              hour
              month
            }
            photoMain {
              src
              srcsetJpg
              srcsetWebp
              alt
              originalFilename
              originalSrc
              originalMimetype
            }
            photoGallery {
              src
              srcsetJpg
              srcsetWebp
              alt
              originalFilename
            }
            aupair
            languages
          }
          employment {
            types
            regularities
          }
          responsibilities {
            careChild
            careHome
            activities
          }
          schedule {
            value
            comment
            dateStart
          }
          perks
          experience {
            working {
              isPresent
              years
              kidsAges
              comment
            }
            life
          }
          education {
            degree
            types
            courses
            comment
          }
        }
      }
      offers {
        nanny_ids
        nanny_ids_populated {
          _id
          user_id
          user_profile_id
          user_profile_id_populated {
            _id
            user_id
            nameFirst
            nameLast
            nameBusiness
            zip
            sex
            dob
            phone
            lastSeen
          }
          response_ids
          response_ids_populated {
            _id
            isSeen
            from_application_nanny_id
            message
            on_offer_nanny_id
          }
          meta {
            visitor_user_ids
            visitsAll
            visitsUnique
            clicksCtaUnique
            status
            createdAt
            refreshedAt
          }
          details {
            title
            description
            location {
              city
              address
              point {
                type
                coordinates
              }
            }
            rate {
              hour
              month
            }
            photoMain {
              src
              srcsetJpg
              srcsetWebp
              alt
              originalFilename
              originalSrc
              originalMimetype
            }
            phone
            aupair
            kids {
              id
              age
              sex
            }
            isSpecialCare
          }
          employment {
            type
            regularity
          }
          schedule {
            value
            comment
            dateStart
            period {
              term
              measure
              amount
            }
          }
          requirements {
            languages
            responsibilities {
              careChild
              careHome
              activities
            }
            perks
          }
        }
      }
    }
  }
`;
