<template>
  <div class="layout-view-content">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.layout-view-content {
  width: 100%;
  min-height: 400px;

  display: flex;
  flex-flow: column;
  align-items: center;
}
</style>
