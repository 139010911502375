import { GET_USER } from "@/graphql/queries/user";
import { apolloClient } from "@/plugins/apollo";

export const getUserWatchQueryObservable = ({
  user_id,
  populate = {
    path: "applications.nanny_id_populated offers.nanny_ids_populated",
    populate: {
      path: "user_profile_id_populated response_ids_populated",
    },
  },
}) => {
  const userQuery = apolloClient.watchQuery({
    query: GET_USER,
    variables: {
      id: user_id,
      populate,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "network-only",
  });

  return userQuery;
};

export const refetchUser = () => {
  return apolloClient.refetchQueries({
    include: [GET_USER],
  });
};
