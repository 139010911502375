<template>
  <div v-if="!isAccepted" class="gdpr-container">
    <p>
      This site uses cookies. Visit our
      <a
        :href="ROUTE_DOCS_COOKIES_PATH"
        target="_blank"
        rel="noopener noreferrer"
        >cookies policy page</a
      >
      for more info or to change your preferences.
    </p>
    <div class="buttons">
      <ButtonBasic
        :label="'Accept only essential cookies'"
        :size="'small'"
        @click="handleEssentialCookiesConsent"
      />
      <ButtonBasic
        :label="'Accept all cookies'"
        :size="'small'"
        @click="handleAllCookiesConsent"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import ButtonBasic from "../UI/Base/ButtonBasic.vue";
import {
  acceptAllCookies,
  acceptEssentialCookies,
  isAllCookiesAccepted,
  isEssentialCookiesAccepted,
} from "./helpers";
import { ROUTE_DOCS_COOKIES_PATH } from "@/router/constants";

export default {
  components: { ButtonBasic },
  setup() {
    const isAccepted = ref(
      isAllCookiesAccepted() || isEssentialCookiesAccepted()
    );

    const handleAllCookiesConsent = () => {
      acceptAllCookies();
      isAccepted.value = true;
    };

    const handleEssentialCookiesConsent = () => {
      acceptEssentialCookies();
      isAccepted.value = true;
    };

    return {
      isAccepted,
      handleAllCookiesConsent,
      handleEssentialCookiesConsent,
      ROUTE_DOCS_COOKIES_PATH,
    };
  },
};
</script>

<style scoped>
.gdpr-container {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  column-gap: 40px;
  width: 100vw;
  background-color: #fff;
  border-top: 1px solid rgba(22, 32, 94, 0.06);
  z-index: 999;
}
.buttons {
  display: flex;
  justify-content: center;
  flex-flow: row;
  gap: 20px;
}

@media (max-width: 1200px) {
  .gdpr-container {
    flex-flow: column;
    row-gap: 20px;
    bottom: var(--nav-mobile-height);
  }
  .buttons {
    flex-wrap: wrap;
  }
}
</style>
