import gql from "graphql-tag";

export const GET_APPLICATION_NANNY = gql`
  query ApplicationNanny($id: ID!, $populatePath: String) {
    applicationNanny(_id: $id, populatePath: $populatePath) {
      _id
      user_id
      user_profile_id
      user_profile_id_populated {
        _id
        user_id
        nameFirst
        nameLast
        nameBusiness
        zip
        zipLocationPoint {
          type
          coordinates
        }
        sex
        dob
        phone
        lastSeen
      }
      response_ids
      review_ids
      recommendation_ids
      recommendation_ids_populated {
        _id
        application_nanny_id
        source
        text
        rating
        proofName
        proofContact
        isVerified
      }
      meta {
        visitor_user_ids
        visitsAll
        visitsUnique
        clicksCtaUnique
        status
        createdAt
        refreshedAt
      }
      details {
        description
        location {
          city
          cityMarket
          address
          point {
            type
            coordinates
          }
        }
        rate {
          hour
          month
        }
        photoMain {
          src
          srcsetJpg
          srcsetWebp
          originalSrc
          originalFilename
          originalMimetype
          alt
        }
        photoGallery {
          src
          srcsetJpg
          srcsetWebp
          alt
          originalFilename
        }
        aupair
        languages
      }
      employment {
        types
        regularities
      }
      responsibilities {
        careChild
        careHome
        activities
      }
      schedule {
        value
        comment
        dateStart
      }
      perks
      experience {
        working {
          isPresent
          years
          kidsAges
          comment
        }
        life
      }
      education {
        degree
        types
        courses
        comment
      }
    }
  }
`;

export const GET_APPLICATIONS_NANNY = gql`
  query ApplicationsNanny(
    $ids: [ID!]
    $filters: ListApplicationNannyInput
    $populatePath: String
  ) {
    applicationsNanny(
      ids: $ids
      filters: $filters
      populatePath: $populatePath
    ) {
      _id
      user_id
      user_profile_id
      user_profile_id_populated {
        _id
        user_id
        nameFirst
        nameLast
        nameBusiness
        zip
        zipLocationPoint {
          type
          coordinates
        }
        sex
        dob
        phone
        lastSeen
      }
      response_ids
      review_ids
      recommendation_ids
      recommendation_ids_populated {
        _id
        application_nanny_id
        source
        text
        rating
        proofName
        proofContact
        isVerified
      }
      meta {
        visitor_user_ids
        visitsAll
        visitsUnique
        clicksCtaUnique
        status
        createdAt
        refreshedAt
      }
      details {
        description
        location {
          city
          cityMarket
          address
          point {
            type
            coordinates
          }
        }
        rate {
          hour
          month
        }
        photoMain {
          src
          srcsetJpg
          srcsetWebp
          originalSrc
          originalFilename
          originalMimetype
          alt
        }
        photoGallery {
          src
          srcsetJpg
          srcsetWebp
          alt
          originalFilename
        }
        aupair
        languages
      }
      employment {
        types
        regularities
      }
      responsibilities {
        careChild
        careHome
        activities
      }
      schedule {
        value
        comment
        dateStart
      }
      perks
      experience {
        working {
          isPresent
          years
          kidsAges
          comment
        }
        life
      }
      education {
        degree
        types
        courses
        comment
      }
    }
  }
`;

export const GET_APPLICATIONS_NANNY_FEED = gql`
  query ApplicationsNannyFeed(
    $userLocation: FeedLocationPointInput!
    $maxDistance: Int!
    $offset: Int!
    $limit: Int!
    $populatePath: String
    $filters: FiltersApplicationNannyInput
  ) {
    applicationsNannyFeed(
      userLocation: $userLocation
      maxDistance: $maxDistance
      offset: $offset
      limit: $limit
      populatePath: $populatePath
      filters: $filters
    ) {
      value {
        _id
        user_id
        user_profile_id
        user_profile_id_populated {
          _id
          user_id
          nameFirst
          nameLast
          nameBusiness
          zip
          zipLocationPoint {
            type
            coordinates
          }
          sex
          dob
          phone
          lastSeen
        }
        response_ids
        review_ids
        recommendation_ids
        recommendation_ids_populated {
          _id
          application_nanny_id
          source
          text
          rating
          proofName
          proofContact
          isVerified
        }
        meta {
          visitor_user_ids
          visitsAll
          visitsUnique
          clicksCtaUnique
          status
          createdAt
          refreshedAt
        }
        details {
          description
          location {
            city
            cityMarket
            address
            point {
              type
              coordinates
            }
          }
          rate {
            hour
            month
          }
          photoMain {
            src
            srcsetJpg
            srcsetWebp
            originalSrc
            originalFilename
            originalMimetype
            alt
          }
          photoGallery {
            src
            srcsetJpg
            srcsetWebp
            alt
            originalFilename
          }
          aupair
          languages
        }
        employment {
          types
          regularities
        }
        responsibilities {
          careChild
          careHome
          activities
        }
        schedule {
          value
          comment
          dateStart
        }
        perks
        experience {
          working {
            isPresent
            years
            kidsAges
            comment
          }
          life
        }
        education {
          degree
          types
          courses
          comment
        }
        distance {
          calculated
          location {
            type
            coordinates
          }
        }
      }
      offset
      limit
      totalCount
    }
  }
`;
