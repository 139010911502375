import gql from "graphql-tag";

export const CREATE_USER_PROFILE = gql`
  mutation CreateUserProfile($payload: CreateUserProfileInput!) {
    createUserProfile(payload: $payload) {
      _id
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($id: ID!, $payload: UpdateUserProfileInput!) {
    updateUserProfile(_id: $id, payload: $payload) {
      _id
    }
  }
`;
