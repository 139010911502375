import gql from "graphql-tag";

export const GET_OFFER_NANNY_RESPONSE = gql`
  query OfferNannyResponse($id: ID!) {
    offerNannyResponse(_id: $id) {
      _id
      from_application_nanny_id
      on_offer_nanny_id
      message
      isSeen
    }
  }
`;

export const GET_OFFER_NANNY_RESPONSES = gql`
  query OfferNannyResponses(
    $ids: [ID!]
    $filters: ListOfferNannyResponseInput
    $populatePath: String
    $populate: PopulateInput
  ) {
    offerNannyResponses(
      ids: $ids
      filters: $filters
      populatePath: $populatePath
      populate: $populate
    ) {
      _id
      from_application_nanny_id
      from_application_nanny_id_populated {
        _id
        user_id
        user_profile_id
        user_profile_id_populated {
          _id
          user_id
          nameFirst
          nameLast
          nameBusiness
          zip
          sex
          dob
          phone
          lastSeen
        }
        response_ids
        review_ids
        recommendation_ids
        recommendation_ids_populated {
          _id
          application_nanny_id
          source
          text
          rating
          proofName
          proofContact
          isVerified
        }
        meta {
          visitor_user_ids
          visitsAll
          visitsUnique
          clicksCtaUnique
          status
          createdAt
          refreshedAt
        }
        details {
          description
          location {
            city
            address
            point {
              type
              coordinates
            }
          }
          rate {
            hour
            month
          }
          photoMain {
            src
            srcsetJpg
            srcsetWebp
            originalSrc
            originalFilename
            originalMimetype
            alt
          }
          photoGallery {
            src
            srcsetJpg
            srcsetWebp
            alt
            originalFilename
          }
          aupair
          languages
        }
        employment {
          types
          regularities
        }
        responsibilities {
          careChild
          careHome
          activities
        }
        schedule {
          value
          comment
          dateStart
        }
        perks
        experience {
          working {
            isPresent
            years
            kidsAges
            comment
          }
          life
        }
        education {
          degree
          types
          courses
          comment
        }
      }
      on_offer_nanny_id
      on_offer_nanny_id_populated {
        _id
        user_id
        user_profile_id
        user_profile_id_populated {
          _id
          user_id
          nameFirst
          nameLast
          nameBusiness
          zip
          sex
          dob
          phone
          lastSeen
        }
        response_ids
        meta {
          visitor_user_ids
          visitsAll
          visitsUnique
          clicksCtaUnique
          status
          createdAt
          refreshedAt
        }
        details {
          title
          description
          location {
            city
            point {
              type
              coordinates
            }
          }
          rate {
            hour
            month
          }
          photoMain {
            src
            srcsetJpg
            srcsetWebp
            originalSrc
            originalFilename
            originalMimetype
            alt
          }
          phone
          aupair
          kids {
            id
            age
            sex
          }
          isSpecialCare
        }
        employment {
          type
          regularity
        }
        schedule {
          value
          comment
          dateStart
          period {
            term
            measure
            amount
          }
        }
        requirements {
          languages
          responsibilities {
            careChild
            careHome
            activities
          }
          perks
        }
      }
      message
      isSeen
    }
  }
`;
