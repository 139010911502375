import { ref } from "vue";
// import IMask from "imask/esm/imask"; // imports only factory

export default function useIMaskTelephone() {
  const countryPhoneTemplates = [
    {
      country: "Greece",
      code: "3\\0",
      masks: ["{21} 0 000 0000"],
    },
    {
      country: "Netherlands",
      code: "31",
      masks: ["00000000000"],
    },
    {
      country: "Belgium",
      code: "32",
      masks: ["00000000000"],
    },
    {
      country: "France",
      code: "33",
      masks: ["00000000000"],
    },
    {
      country: "Hungary",
      code: "36",
      masks: ["00000000000"],
    },
    {
      country: "Italy",
      code: "39",
      masks: ["00000000000"],
    },
    {
      country: "Romania",
      code: "4\\0",
      masks: ["00000000000"],
    },
    {
      country: "Austria",
      code: "43",
      masks: ["00000000000"],
    },
    {
      country: "Denmark",
      code: "45",
      masks: ["00000000000"],
    },
    {
      country: "Sweden",
      code: "46",
      masks: ["00000000000"],
    },
    {
      country: "Norway",
      code: "47",
      masks: ["00000000000"],
    },
    {
      country: "Poland",
      code: "48",
      masks: ["000 000 000"],
    },
    {
      country: "Germany",
      code: "49",
      masks: ["00000000000"],
    },
    {
      country: "Spain",
      code: "34",
      masks: ["00000000000"],
    },
    {
      country: "Portugal",
      code: "351",
      masks: ["00000000000"],
    },
    {
      country: "Luxembourg",
      code: "352",
      masks: ["00000000000"],
    },
    {
      country: "Ireland",
      code: "353",
      masks: ["00000000000"],
    },
    {
      country: "Iceland",
      code: "354",
      masks: ["00000000000"],
    },
    {
      country: "Malta",
      code: "356",
      masks: ["00000000000"],
    },
    {
      country: "Cyprus",
      code: "357",
      masks: ["00000000000"],
    },
    {
      country: "Finland",
      code: "358",
      masks: ["00000000000"],
    },
    {
      country: "Bulgaria",
      code: "359",
      masks: ["00000000000"],
    },
    {
      country: "Lithuania",
      code: "37\\0",
      masks: ["00000000000"],
    },
    {
      country: "Latvia",
      code: "371",
      masks: ["00000000000"],
    },
    {
      country: "Estonia",
      code: "372",
      masks: ["00000000000"],
    },
    {
      country: "Croatia",
      code: "385",
      masks: ["00000000000"],
    },
    {
      country: "Slovenia",
      code: "386",
      masks: ["00000000000"],
    },
    {
      country: "Czech Republic",
      code: "42\\0",
      masks: ["00000000000"],
    },
    {
      country: "Slovakia",
      code: "421",
      masks: ["00000000000"],
    },
    {
      country: "Liechtenstein",
      code: "423",
      masks: ["00000000000"],
    },
  ];

  const getCodeMasks = () => {
    let codeMasks = [];
    for (let template of countryPhoneTemplates) {
      codeMasks.push({ mask: "{" + template.code + "}" });
    }
    return codeMasks;
  };

  const masks = getCodeMasks();

  const maskRef = ref({
    mask: "PrefixCodeMask",
    blocks: {
      Prefix: {
        mask: "{+}",
      },
      Code: {
        mask: masks,
      },
      Mask: {
        mask: " 000 000 000[000]",
      },
    },
  });

  // mask: [
  //   { mask: " 000 000 000", overwrite: false },
  //   { mask: " 000[0000000]", overwrite: false },
  //   { mask: "000[00000000]", overwrite: false },
  // ],

  return {
    maskRef,
    countryPhoneTemplates,
  };
}
