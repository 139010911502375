const JWT_ACCESS_TOKEN_LS_KEY = "jwtAccessToken";

const jwtService = {
  setAccessTokenToLS(jwtAccessToken) {
    console.log("SET_ACCESS_TOKEN_TO_LS");
    localStorage.setItem(JWT_ACCESS_TOKEN_LS_KEY, jwtAccessToken);
  },

  getAccessTokenFromLS() {
    console.log("GET_ACCESS_TOKEN_FROM_LS");
    return localStorage.getItem(JWT_ACCESS_TOKEN_LS_KEY);
  },

  decodeJwtToPayload(tokenEncoded) {
    const payloadEconded = tokenEncoded.split(".")[1];
    const payloadDecoded = JSON.parse(Buffer.from(payloadEconded, "base64"));
    return payloadDecoded;
  },

  removeAccessTokenFromLS() {
    console.log("REMOVE_ACCESS_TOKEN_FROM_LS");
    localStorage.removeItem(JWT_ACCESS_TOKEN_LS_KEY);
  },
};

export default jwtService;
