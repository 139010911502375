// Argument "slugs" could be either Array or String (if only one slug)
// Returns undefined if nothing is found
export const getSlugsFromDict = (slugs, dict) => {
  try {
    if (!dict) throw Error("No dictionary provided!");

    if (slugs === null) return null;
    if (slugs.length === 0) return [];

    let result; // Undefined by default

    if (Array.isArray(slugs)) {
      result = [];
      slugs.forEach((slug) => {
        // Ensure we have this slug in our DICTIONARY
        if (dict[slug]) result.push(dict[slug]);
      });
      if (result.length === 0) return undefined; // Return undefined if nothing is found
    } else if (typeof slugs === "string") {
      let slug = slugs;
      if (dict[slug]) {
        result = dict[slug];
      } else return undefined; // Return undefined if nothing is found
    } else {
      throw Error('Type of parametr "slugs" is neither Array nor String!');
    }

    return result;
  } catch (error) {
    console.log(error);
    console.log("Slugs: " + slugs);
    console.log("Dict: " + dict);
  }
};
