<template>
  <InputText
    @input="changeHeight"
    @textareaOnMountedRef="getTextareaRef"
    :textarea="true"
    :name="name"
    :placeholder="placeholder"
    :rows="rows"
    :maxlength="maxlength"
    :autocomplete="'off'"
  />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    rows: {
      type: [String, Number],
      default: 6,
    },
    maxlength: {
      type: [String, Number],
      default: 200,
    },
    placeholder: String,
  },
  setup() {
    let textareaRef;

    const getTextareaRef = (childTextareaRef) => {
      textareaRef = childTextareaRef;
    };
    const changeHeight = () => {
      textareaRef.value.style.height = "auto";
      textareaRef.value.style.height =
        textareaRef.value.scrollHeight + 4 + "px";
    };

    return {
      changeHeight,
      getTextareaRef,
    };
  },
};
</script>

<style>
/* For styles loook into InputText < style > block */
</style>
