<template>
  <InputTextPostfixPrefix
    :prefix="prefix"
    :postfix="'zł/miesiąc'"
    :name="'month_fee'"
    :placeholder="placeholder"
    :maxlength="4"
    :mask="maskRef"
    :small="true"
  />
</template>

<script>
import useIMaskNumber from "@/composables/imask/useIMaskNumber";

export default {
  props: {
    prefix: {
      type: String,
      default: "Do",
    },
    placeholder: {
      type: String,
      default: "4000",
    },
  },
  setup() {
    const { maskRef } = useIMaskNumber({ min: 1, max: 9999, scale: 0 });

    return {
      maskRef,
    };
  },
};
</script>

<style scoped></style>
