import { defineAsyncComponent } from "@vue/runtime-core";

// Application --> Nanny
import ApplicationNannyCreateRoute from "@/router/routes/applicationNanny/applicationNannyCreate";
import {
  ROUTE_APPLICATION_NANNY_DETAILS_NAME,
  ROUTE_APPLICATION_NANNY_DETAILS_PATH,
  ROUTE_APPLICATION_NANNY_LIST_PATH,
  ROUTE_APPLICATION_NANNY_UPDATE_NAME,
  ROUTE_APPLICATION_NANNY_UPDATE_PATH,
  ROUTE_LIST_NANNY_NAME,
} from "@/router/constants";

const ApplicationNannyUpdate = () =>
  import("@/views/Application/Nanny/ApplicationNannyUpdate.vue");
const ApplicationNannyDetails = () =>
  import("@/views/Application/Nanny/Details/ApplicationNannyDetails.vue");

// Application --> Nanny
export default [
  ApplicationNannyCreateRoute,

  {
    path: ROUTE_APPLICATION_NANNY_UPDATE_PATH,
    name: ROUTE_APPLICATION_NANNY_UPDATE_NAME,
    component: ApplicationNannyUpdate,
    meta: {
      authGuard: true,
    },
  },

  {
    path: ROUTE_APPLICATION_NANNY_LIST_PATH,
    redirect: { name: ROUTE_LIST_NANNY_NAME }, // redirect to List view (aka "search page")
  },

  {
    path: ROUTE_APPLICATION_NANNY_DETAILS_PATH,
    name: ROUTE_APPLICATION_NANNY_DETAILS_NAME,
    component: ApplicationNannyDetails,
    meta: {
      layout: defineAsyncComponent({
        loader: () => import(`@/layouts/LayoutDetails.vue`),
        delay: 0,
      }),
    },
  },
];
