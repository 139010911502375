<template>
  <BaseButton
    :to="to"
    :label="label"
    :disabled="isDisabled"
    :class="['button-link', sizeClass]"
  >
    <slot />
  </BaseButton>
</template>

<script>
import BaseButton from "@/components/UI/Base/BaseButton.vue";
import { computed } from "vue";

export default {
  components: { BaseButton },

  props: {
    to: {
      type: [String, Object],
      required: false,
    },
    size: {
      type: String,
      default: "medium",
    },
    label: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const sizeClass = computed(() => {
      const { size } = props;
      if (size === "small") return "button-link--small";
      if (size === "x-small") return "button-link--x-small";

      return "";
    });

    return {
      sizeClass,
    };
  },
};
</script>

<style scoped>
/* Link Buttons  */
.button-link {
  display: flex;
  align-items: center;
  justify-content: center;

  width: max-content;
  height: auto;

  color: var(--button-link-font-color);
  font-size: var(--default-font-size);
  font-weight: var(--button-font-weight);
  text-align: center;

  background-color: var(--button-link-bg-color);
  border: var(--button-link-border);
  border-radius: var(--button-border-radius);
}

.button-link:hover {
  text-decoration: var(--button-link-hover-text-decoration);
  cursor: pointer;
}

.button-link--small {
  font-size: var(--button-small-font-size);
}

.button-link--x-small {
  font-size: var(--button-x-small-font-size);
}

.button-link.is-active {
  text-decoration: var(--button-link-hover-text-decoration);
}
</style>
