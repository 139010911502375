const LS_KEY = "LENLO__COOKIES_CONSENT";

const ACCEPTED_COOKIES = {
  ALL: "all",
  ESSENTIAL: "essential",
};

export const isAllCookiesAccepted = () => {
  const acceptedCookies = window.localStorage.getItem(LS_KEY);
  return acceptedCookies === ACCEPTED_COOKIES.ALL;
};

export const isEssentialCookiesAccepted = () => {
  const acceptedCookies = window.localStorage.getItem(LS_KEY);
  return acceptedCookies === ACCEPTED_COOKIES.ESSENTIAL;
};

export const acceptAllCookies = () => {
  window.localStorage.setItem(LS_KEY, ACCEPTED_COOKIES.ALL);
};

export const acceptEssentialCookies = () => {
  window.localStorage.setItem(LS_KEY, ACCEPTED_COOKIES.ESSENTIAL);
};
