import {
  ROUTE_LIST_NANNY_NAME,
  ROUTE_LIST_NANNY_PATH,
  ROUTE_NOTIFICATIONS_NAME,
  ROUTE_NOTIFICATIONS_PATH,
  ROUTE_PUSH_TO_PREMIUM_NAME,
  ROUTE_PUSH_TO_PREMIUM_PATH,
  ROUTE_WISHLIST_NAME,
  ROUTE_WISHLIST_PATH,
  ROUTE_CONTACT_US_NAME,
  ROUTE_CONTACT_US_PATH,
} from "../constants";
import authRoutes from "@/router/routes/auth/auth";
import applicationNannyRoutes from "@/router/routes/applicationNanny/applicationNanny";
import offerNannyRoutes from "@/router/routes/offerNanny/offerNanny";
import accountRoutes from "@/router/routes/account/account";
import landingRoutes from "@/router/routes/landing/landing";
import docsRoutes from "./docs/docs";
const ListNanny = () => import("@/views/List/ListNanny.vue");
const WishlistClient = () => import("@/views/WishlistClient.vue");
const Notifications = () => import("@/views/Notifications.vue");
const PushToPremium = () => import("@/views/PushToPremium.vue");
const ContactUs = () => import("@/views/ContactUs.vue");

const routes = [
  // TODO: change home route home view with landing + redirect authorised users to list view
  {
    path: "/",
    redirect: { name: ROUTE_LIST_NANNY_NAME },
  },

  ...authRoutes,

  ...applicationNannyRoutes,

  ...offerNannyRoutes,

  // List view for Nanny offers and applications
  {
    path: ROUTE_LIST_NANNY_PATH,
    name: ROUTE_LIST_NANNY_NAME,
    component: ListNanny,
    meta: {
      avoidSavedPosition: true,
    },
  },

  // Wishlist
  {
    path: ROUTE_WISHLIST_PATH,
    name: ROUTE_WISHLIST_NAME,
    component: WishlistClient,
    meta: {
      authGuard: true,
    },
  },

  // Notifications
  {
    path: ROUTE_NOTIFICATIONS_PATH,
    name: ROUTE_NOTIFICATIONS_NAME,
    component: Notifications,
    meta: {
      authGuard: true,
    },
  },

  // Account
  ...accountRoutes,

  // Push to premium
  {
    path: ROUTE_PUSH_TO_PREMIUM_PATH,
    name: ROUTE_PUSH_TO_PREMIUM_NAME,
    component: PushToPremium,
    meta: {
      authGuard: true,
    },
  },

  // Landings
  ...landingRoutes,

  // Docs
  ...docsRoutes,

  {
    path: ROUTE_CONTACT_US_PATH,
    name: ROUTE_CONTACT_US_NAME,
    component: ContactUs,
  },
];

export default routes;
