<template>
  <div class="layout-view-wrapper-narrow">
    <slot />
  </div>
</template>

<style scoped>
.layout-view-wrapper-narrow {
  min-height: var(--layout-view-wrapper-narrow--min-height);
  width: var(--layout-view-wrapper-narrow--width);

  margin: 0 auto;
  padding: var(--layout-view-wrapper-narrow--padding);

  display: flex;
  flex-flow: column;
  align-items: center;

  background-color: var(--layout-view-wrapper-narrow--bg-color);
  border-radius: var(--layout-view-wrapper-narrow--border-radius);
}
</style>
