<template>
  <div class="layout-view-wrapper-block-11">
    <div class="x1 layout-view-wrapper-block-11__x1">
      <slot name="left"></slot>
    </div>
    <div class="x1 layout-view-wrapper-block-11__x1">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.layout-view-wrapper-block-11 {
  width: 100%;

  display: flex;
  column-gap: var(--layout-view-wrapper-block-11--column-gap);
}
.layout-view-wrapper-block-11__x1 {
  display: flex;
  justify-content: center;

  flex-grow: 1;
}

@media (max-width: 1200px) {
  .layout-view-wrapper-block-11 {
    flex-wrap: wrap;
  }
  .layout-view-wrapper-block-11__x1 {
    width: var(--layout-view-block-11--column-width);

    flex-grow: unset;
  }
}
</style>
