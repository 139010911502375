import gql from "graphql-tag";

export const CREATE_USER = gql`
  mutation CreateUser($payload: CreateUserInput!) {
    createUser(payload: $payload) {
      _id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $payload: UpdateUserInput!) {
    updateUser(_id: $id, payload: $payload) {
      _id
    }
  }
`;
