import { UPDATE_APPLICATION_NANNY } from "@/graphql/mutations/applicationNanny";
import {
  GET_APPLICATIONS_NANNY_FEED,
  GET_APPLICATION_NANNY,
} from "@/graphql/queries/applicationNanny";
import { apolloClient } from "@/plugins/apollo";

export const getApplicationNannyWatchQuery = ({
  application_id,
  populatePath = "user_profile_id_populated recommendation_ids_populated",
}) => {
  const applicationNannyQuery = apolloClient.watchQuery({
    query: GET_APPLICATION_NANNY,
    variables: {
      id: application_id,
      populatePath,
    },
    fetchPolicy: "cache-and-network",
  });

  return applicationNannyQuery;
};

export const getApplicationNanny = ({
  application_id,
  populatePath = "user_profile_id_populated recommendation_ids_populated",
}) => {
  const applicationNannyQueryResult = apolloClient.query({
    query: GET_APPLICATION_NANNY,
    variables: {
      id: application_id,
      populatePath,
    },
    fetchPolicy: "network-only",
  });

  return applicationNannyQueryResult;
};

export const getApplicationsNannyFeed = ({
  filters,
  userLocation,
  maxDistance,
  populatePath,
  offset,
  limit,
}) => {
  return apolloClient.query({
    query: GET_APPLICATIONS_NANNY_FEED,
    variables: {
      filters: filters,
      userLocation: userLocation,
      maxDistance: maxDistance,
      offset: offset,
      limit: limit,
      populatePath: populatePath,
    },
    fetchPolicy: "network-only",
  });
};

export const updateApplicationNanny = async ({
  application_id,
  applicationNannyStore,
}) => {
  return await apolloClient.mutate({
    mutation: UPDATE_APPLICATION_NANNY,
    variables: {
      id: application_id,
      payload: {
        user_id: applicationNannyStore.user_id,
        user_profile_id: applicationNannyStore.user_profile_id,
        details: {
          description: applicationNannyStore.details.description,
          location: {
            city: applicationNannyStore.details.location.city,
            address: applicationNannyStore.details.location.address,
            point: {
              type: applicationNannyStore.details.location.point.type,
              coordinates:
                applicationNannyStore.details.location.point.coordinates,
            },
          },
          rate: {
            hour: applicationNannyStore.details.rate.hour,
            month: applicationNannyStore.details.rate.month,
          },
          aupair: applicationNannyStore.details.aupair,
          languages: applicationNannyStore.details.languages,
        },
        employment: {
          types: applicationNannyStore.employment.types,
          regularities: applicationNannyStore.employment.regularities,
        },
        responsibilities: {
          careChild: applicationNannyStore.responsibilities.careChild,
          careHome: applicationNannyStore.responsibilities.careHome,
          activities: applicationNannyStore.responsibilities.activities,
        },
        schedule: {
          value: applicationNannyStore.schedule.value,
          comment: applicationNannyStore.schedule.comment,
          dateStart: applicationNannyStore.schedule.dateStart,
        },
        perks: applicationNannyStore.perks,
        experience: {
          working: {
            isPresent: applicationNannyStore.experience.working.isPresent,
            years: applicationNannyStore.experience.working.years,
            kidsAges: applicationNannyStore.experience.working.kidsAges,
            comment: applicationNannyStore.experience.working.comment,
          },
          life: applicationNannyStore.experience.life,
        },
        education: {
          degree: applicationNannyStore.education.degree,
          types: applicationNannyStore.education.types,
          courses: applicationNannyStore.education.courses,
          comment: applicationNannyStore.education.comment,
        },
      },
    },
  });
};

export const archiveApplicationNanny = async ({ application_id }) => {
  await apolloClient.mutate({
    mutation: UPDATE_APPLICATION_NANNY,
    variables: {
      id: application_id,
      payload: {
        meta: {
          status: "archived",
        },
      },
    },
  });
  return "OK";
};

export const pauseApplicationNanny = async ({ application_id }) => {
  await apolloClient.mutate({
    mutation: UPDATE_APPLICATION_NANNY,
    variables: {
      id: application_id,
      payload: {
        meta: {
          status: "paused",
        },
      },
    },
  });
  return "OK";
};

export const runApplicationNanny = async ({ application_id }) => {
  await apolloClient.mutate({
    mutation: UPDATE_APPLICATION_NANNY,
    variables: {
      id: application_id,
      payload: {
        meta: {
          status: "published",
        },
      },
    },
  });
  return "OK";
};

export const refreshApplicationNanny = async ({ application_id }) => {
  await apolloClient.mutate({
    mutation: UPDATE_APPLICATION_NANNY,
    variables: {
      id: application_id,
      payload: {
        meta: {
          refreshedAt: Date.now(),
        },
      },
    },
  });
  return "OK";
};
