import { ref } from "vue";

export default function useIMaskNumber({
  max = 99999999,
  min = -99999999,
  scale = 0,
} = {}) {
  const maskRef = ref({
    mask: Number,
    min: min,
    max: max,
    scale: scale,
    overwrite: true,
  });

  return {
    maskRef,
  };
}
