<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
  >
    <path
      d="M10.25 13C10.25 13.1642 10.2177 13.3267 10.1548 13.4784C10.092 13.63 9.99996 13.7678 9.88388 13.8839C9.76781 14 9.63001 14.092 9.47835 14.1548C9.3267 14.2177 9.16415 14.25 9 14.25C8.66848 14.25 8.35054 14.1183 8.11612 13.8839C7.8817 13.6495 7.75 13.3315 7.75 13C7.75 12.6685 7.8817 12.3505 8.11612 12.1161C8.35054 11.8817 8.66848 11.75 9 11.75C9.16415 11.75 9.3267 11.7823 9.47835 11.8452C9.63001 11.908 9.76781 12 9.88388 12.1161C9.99996 12.2322 10.092 12.37 10.1548 12.5216C10.2177 12.6733 10.25 12.8358 10.25 13ZM15 11.75C14.6685 11.75 14.3505 11.8817 14.1161 12.1161C13.8817 12.3505 13.75 12.6685 13.75 13C13.75 13.3315 13.8817 13.6495 14.1161 13.8839C14.3505 14.1183 14.6685 14.25 15 14.25C15.3315 14.25 15.6495 14.1183 15.8839 13.8839C16.1183 13.6495 16.25 13.3315 16.25 13C16.25 12.6685 16.1183 12.3505 15.8839 12.1161C15.6495 11.8817 15.3315 11.75 15 11.75ZM22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22C10.6868 22 9.38642 21.7413 8.17317 21.2388C6.95991 20.7362 5.85752 19.9997 4.92893 19.0711C3.05357 17.1957 2 14.6522 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2C13.3132 2 14.6136 2.25866 15.8268 2.7612C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7362 6.95991 21.2388 8.17317C21.7413 9.38642 22 10.6868 22 12ZM10.66 4.12C12.06 6.44 14.6 8 17.5 8C17.96 8 18.41 7.95 18.84 7.88C17.44 5.56 14.9 4 12 4C11.54 4 11.09 4.05 10.66 4.12ZM4.42 9.47C6.13 8.5 7.45 6.92 8.08 5.03C6.37 6 5.05 7.58 4.42 9.47ZM20 12C20 11.22 19.88 10.47 19.67 9.76C18.97 9.91 18.25 10 17.5 10C14.37 10 11.58 8.56 9.74 6.31C8.69 8.87 6.6 10.88 4 11.86C4 11.9 4 11.95 4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12Z"
      fill="black"
    />
  </svg>
</template>

<style scoped>
.svg-icon path {
  fill: var(--default-font-color);
}
</style>
