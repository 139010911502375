import {
  ROUTE_APPLICATION_NANNY_CREATE_ABOUT_ME_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_ABOUT_ME_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_ADDRESS_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_ADDRESS_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_AUPAIR_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_AUPAIR_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_COURSES_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_COURSES_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_DEGREE_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_DEGREE_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_TYPE_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_TYPE_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_TYPE_STUDENT_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_TYPE_STUDENT_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_EMPLOYMENT_REGULARITY_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_EMPLOYMENT_REGULARITY_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_EMPLOYMENT_TYPE_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_EMPLOYMENT_TYPE_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_OWN_KIDS_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_OWN_KIDS_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_SPECIAL_CARE_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_SPECIAL_CARE_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_TWINS_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_TWINS_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_WORKING_KIDS_AGES_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_WORKING_KIDS_AGES_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_WORKING_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_WORKING_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_WORKING_YEARS_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_WORKING_YEARS_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_FINISH_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_FINISH_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_PERKS_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_PERKS_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_PHOTO_GALLERY_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_PHOTO_GALLERY_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_PHOTO_MAIN_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_PHOTO_MAIN_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_RECOMMENDATIONS_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_RECOMMENDATIONS_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_RESPONSIBILITIES_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_RESPONSIBILITIES_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_SALARY_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_SALARY_PATH,
  ROUTE_APPLICATION_NANNY_CREATE_SCHEDULE_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_SCHEDULE_PATH,
} from "@/router/constants";
import { defineAsyncComponent } from "@vue/runtime-core";

const ApplicationNannyCreate = () =>
  import("@/views/Application/Nanny/Create/ApplicationNannyCreate.vue");
const ApplicationNannyCreateAddress = () =>
  import("@/views/Application/Nanny/Create/ApplicationNannyCreateAddress.vue");
const ApplicationNannyCreateAupair = () =>
  import("@/views/Application/Nanny/Create/ApplicationNannyCreateAupair.vue");
const ApplicationNannyCreateEmploymentType = () =>
  import(
    "@/views/Application/Nanny/Create/ApplicationNannyCreateEmploymentType.vue"
  );
const ApplicationNannyCreateEmploymentRegularity = () =>
  import(
    "@/views/Application/Nanny/Create/ApplicationNannyCreateEmploymentRegularity.vue"
  );
const ApplicationNannyCreateSchedule = () =>
  import("@/views/Application/Nanny/Create/ApplicationNannyCreateSchedule.vue");
const ApplicationNannyCreateSalary = () =>
  import("@/views/Application/Nanny/Create/ApplicationNannyCreateSalary.vue");
const ApplicationNannyCreateExperienceWorking = () =>
  import(
    "@/views/Application/Nanny/Create/ApplicationNannyCreateExperienceWorking.vue"
  );
const ApplicationNannyCreateExperienceWorkingKidsAges = () =>
  import(
    "@/views/Application/Nanny/Create/ApplicationNannyCreateExperienceWorkingKidsAges.vue"
  );
const ApplicationNannyCreateExperienceWorkingYears = () =>
  import(
    "@/views/Application/Nanny/Create/ApplicationNannyCreateExperienceWorkingYears.vue"
  );
const ApplicationNannyCreateExperienceOwnKids = () =>
  import(
    "@/views/Application/Nanny/Create/ApplicationNannyCreateExperienceOwnKids.vue"
  );
const ApplicationNannyCreateExperienceTwins = () =>
  import(
    "@/views/Application/Nanny/Create/ApplicationNannyCreateExperienceTwins.vue"
  );
const ApplicationNannyCreateExperienceSpecialCare = () =>
  import(
    "@/views/Application/Nanny/Create/ApplicationNannyCreateExperienceSpecialCare.vue"
  );
const ApplicationNannyCreateEducationDegree = () =>
  import(
    "@/views/Application/Nanny/Create/ApplicationNannyCreateEducationDegree.vue"
  );
const ApplicationNannyCreateEducationType = () =>
  import(
    "@/views/Application/Nanny/Create/ApplicationNannyCreateEducationType.vue"
  );
const ApplicationNannyCreateEducationTypeStudent = () =>
  import(
    "@/views/Application/Nanny/Create/ApplicationNannyCreateEducationTypeStudent.vue"
  );
const ApplicationNannyCreateEducationCourses = () =>
  import(
    "@/views/Application/Nanny/Create/ApplicationNannyCreateEducationCourses.vue"
  );
const ApplicationNannyCreateResponsibilities = () =>
  import(
    "@/views/Application/Nanny/Create/ApplicationNannyCreateResponsibilities.vue"
  );
const ApplicationNannyCreatePerks = () =>
  import("@/views/Application/Nanny/Create/ApplicationNannyCreatePerks.vue");
const ApplicationNannyCreateAboutMe = () =>
  import("@/views/Application/Nanny/Create/ApplicationNannyCreateAboutMe.vue");
const ApplicationNannyCreatePhotoMain = () =>
  import(
    "@/views/Application/Nanny/Create/ApplicationNannyCreatePhotoMain.vue"
  );
const ApplicationNannyCreatePhotoGallery = () =>
  import(
    "@/views/Application/Nanny/Create/ApplicationNannyCreatePhotoGallery.vue"
  );
const ApplicationNannyCreateRecommendations = () =>
  import(
    "@/views/Application/Nanny/Create/ApplicationNannyCreateRecommendations.vue"
  );
const ApplicationNannyCreateFinish = () =>
  import("@/views/Application/Nanny/Create/ApplicationNannyCreateFinish.vue");

export default {
  path: ROUTE_APPLICATION_NANNY_CREATE_PATH,
  name: ROUTE_APPLICATION_NANNY_CREATE_NAME,
  component: ApplicationNannyCreate,
  redirect: { name: ROUTE_APPLICATION_NANNY_CREATE_ADDRESS_NAME },
  meta: {
    layout: defineAsyncComponent({
      loader: () => import(`@/layouts/LayoutCreate.vue`),
      delay: 0,
    }),
    authGuard: true,
  },
  children: [
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_ADDRESS_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_ADDRESS_NAME,
      component: ApplicationNannyCreateAddress,
      meta: {
        progress: 0,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_AUPAIR_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_AUPAIR_NAME,
      component: ApplicationNannyCreateAupair,
      meta: {
        progress: 18,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_EMPLOYMENT_TYPE_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_EMPLOYMENT_TYPE_NAME,
      component: ApplicationNannyCreateEmploymentType,
      meta: {
        progress: 25,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_EMPLOYMENT_REGULARITY_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_EMPLOYMENT_REGULARITY_NAME,
      component: ApplicationNannyCreateEmploymentRegularity,
      meta: {
        progress: 33,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_SCHEDULE_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_SCHEDULE_NAME,
      component: ApplicationNannyCreateSchedule,
      meta: {
        progress: 37,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_SALARY_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_SALARY_NAME,
      component: ApplicationNannyCreateSalary,
      meta: {
        progress: 43,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_WORKING_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_WORKING_NAME,
      component: ApplicationNannyCreateExperienceWorking,
      meta: {
        progress: 48,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_WORKING_KIDS_AGES_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_WORKING_KIDS_AGES_NAME,
      component: ApplicationNannyCreateExperienceWorkingKidsAges,
      meta: {
        progress: 52,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_WORKING_YEARS_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_WORKING_YEARS_NAME,
      component: ApplicationNannyCreateExperienceWorkingYears,
      meta: {
        progress: 53,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_OWN_KIDS_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_OWN_KIDS_NAME,
      component: ApplicationNannyCreateExperienceOwnKids,
      meta: {
        progress: 55,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_TWINS_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_TWINS_NAME,
      component: ApplicationNannyCreateExperienceTwins,
      meta: {
        progress: 57,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_SPECIAL_CARE_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_SPECIAL_CARE_NAME,
      component: ApplicationNannyCreateExperienceSpecialCare,
      meta: {
        progress: 60,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_DEGREE_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_DEGREE_NAME,
      component: ApplicationNannyCreateEducationDegree,
      meta: {
        progress: 62,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_TYPE_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_TYPE_NAME,
      component: ApplicationNannyCreateEducationType,
      meta: {
        progress: 64,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_TYPE_STUDENT_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_TYPE_STUDENT_NAME,
      component: ApplicationNannyCreateEducationTypeStudent,
      meta: {
        progress: 64,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_COURSES_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_COURSES_NAME,
      component: ApplicationNannyCreateEducationCourses,
      meta: {
        progress: 67,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_RESPONSIBILITIES_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_RESPONSIBILITIES_NAME,
      component: ApplicationNannyCreateResponsibilities,
      meta: {
        progress: 72,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_PERKS_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_PERKS_NAME,
      component: ApplicationNannyCreatePerks,
      meta: {
        progress: 79,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_ABOUT_ME_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_ABOUT_ME_NAME,
      component: ApplicationNannyCreateAboutMe,
      meta: {
        progress: 85,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_PHOTO_MAIN_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_PHOTO_MAIN_NAME,
      component: ApplicationNannyCreatePhotoMain,
      meta: {
        progress: 90,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_PHOTO_GALLERY_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_PHOTO_GALLERY_NAME,
      component: ApplicationNannyCreatePhotoGallery,
      meta: {
        progress: 93,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_RECOMMENDATIONS_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_RECOMMENDATIONS_NAME,
      component: ApplicationNannyCreateRecommendations,
      meta: {
        progress: 97,
      },
    },
    {
      path: ROUTE_APPLICATION_NANNY_CREATE_FINISH_PATH,
      name: ROUTE_APPLICATION_NANNY_CREATE_FINISH_NAME,
      component: ApplicationNannyCreateFinish,
      meta: {
        progress: 100,
      },
    },
  ],
};
