import gql from "graphql-tag";

export const LOGIN_LOCAL = gql`
  mutation LoginLocal($payload: AuthLocalInput!) {
    loginLocal(payload: $payload) {
      jwt {
        accessToken
      }
      user_id
      user_profile_id
      user_wishlist_id
    }
  }
`;

export const LOGIN_GOOGLE = gql`
  mutation LoginGoogle($payload: AuthGoogleInput!) {
    loginGoogle(payload: $payload) {
      jwt {
        accessToken
      }
      user_id
      user_profile_id
      user_wishlist_id
    }
  }
`;

export const PRE_REGISTER_LOCAL = gql`
  mutation PreRegisterLocal($payload: AuthLocalInput!) {
    preRegisterLocal(payload: $payload) {
      oneTimeCode {
        value
        expiryTimeString
      }
    }
  }
`;

export const REGISTER_LOCAL = gql`
  mutation RegisterLocal(
    $payloadAuth: AuthRegistrationLocalInput!
    $payloadUser: CreateUserInput!
    $payloadUserProfile: CreateUserProfileInput!
  ) {
    registerLocal(
      payloadAuth: $payloadAuth
      payloadUser: $payloadUser
      payloadUserProfile: $payloadUserProfile
    ) {
      jwt {
        accessToken
      }
      user_id
      user_profile_id
      user_wishlist_id
    }
  }
`;

export const REGISTER_GOOGLE = gql`
  mutation RegisterGoogle(
    $payloadAuth: AuthGoogleInput!
    $payloadUser: CreateUserInput!
    $payloadUserProfile: CreateUserProfileInput!
  ) {
    registerGoogle(
      payloadAuth: $payloadAuth
      payloadUser: $payloadUser
      payloadUserProfile: $payloadUserProfile
    ) {
      jwt {
        accessToken
      }
      user_id
      user_profile_id
      user_wishlist_id
    }
  }
`;
