import {
  ROUTE_EMAIL_VERIFICATION_SEND_LINK_NAME,
  ROUTE_EMAIL_VERIFICATION_SEND_LINK_PATH,
  ROUTE_EMAIL_VERIFICATION_VERIFY_LINK_NAME,
  ROUTE_EMAIL_VERIFICATION_VERIFY_LINK_PATH,
} from "@/router/constants";

const AuthEmailVerificationSendLink = () =>
  import("@/views/Auth/EmailVerification/AuthEmailVerificationSendLink.vue");
const AuthEmailVerificationVerifyLink = () =>
  import("@/views/Auth/EmailVerification/AuthEmailVerificationVerifyLink.vue");

const emailVerificationRoutes = [
  {
    // Asking about email verification (send verification link)
    path: ROUTE_EMAIL_VERIFICATION_SEND_LINK_PATH,
    name: ROUTE_EMAIL_VERIFICATION_SEND_LINK_NAME,
    component: AuthEmailVerificationSendLink,
    meta: {
      authGuard: true,
    },
  },
  {
    // User lands on this view from verification link
    // Verify email by processing verification key
    path: ROUTE_EMAIL_VERIFICATION_VERIFY_LINK_PATH,
    name: ROUTE_EMAIL_VERIFICATION_VERIFY_LINK_NAME,
    component: AuthEmailVerificationVerifyLink,
  },
];

export default emailVerificationRoutes;
