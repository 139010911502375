import { createRouter, createWebHistory } from "vue-router";

import routes from "./routes/routes";

const scrollBehavior = (to, from, savedPosition) => {
  // savedPosition is only available for popstate navigations
  if (savedPosition && !to.meta.avoidSavedPosition) {
    console.log(">>>RETURN TO SAVED", { savedPosition });
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(savedPosition);
      }, 10);
    });
  }

  // Save position if params.savePosition
  if (to.params.savePosition) return false;

  // Return position from params
  if (to.params.position) return to.params.position;

  // Scroll to anchor by returning the selector
  if (to.hash) {
    let position = {
      el: to.hash,
      top: 80,
      behavior: "smooth",
    };
    // specify offset of the element
    // if (to.hash === '#anchor2') {
    //   position.top = 100,
    // }
    return position;
  }

  // Save position if fullPath did not changed
  if (to.fullPath === from.fullPath) return {};

  // Scroll to top by default
  return { top: 0 };
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

router.beforeEach((to) => {
  if (to.params.stop) return false;
});

export default router;
