const lStorageService = {
  setWithExpiry(key, value, ttl) {
    // Save item to local storage with TTL expiry in ms

    const currentTime = new Date().getTime();
    const item = {
      value: value,
      expiryTime: currentTime + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  },

  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (itemStr === null) {
      // If the item doesn't exist, return null
      return null;
    }
    const item = JSON.parse(itemStr);
    const itemExp = item.expiryTime;
    if (itemExp === undefined) {
      throw new Error("Item doesn't have any expiry");
    }
    const currentTime = new Date().getTime();
    if (currentTime > item.expiryTime) {
      // If the item is expired, return null and delete the item from local storage
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  },

  updateWithTheSameExpiry(key, value) {
    // Update the item, but leave expiry untouched

    const itemStr = localStorage.getItem(key);

    // If the item doesn't exist or doesn't have any expiry, throw an Error
    if (itemStr === null) {
      throw new Error("Item doesn't exist in local storage");
    }
    const item = JSON.parse(itemStr);
    if (item.expiryTime === undefined) {
      throw new Error("Item doesn't have any expiry");
    }

    Object.assign(item, { value });
    localStorage.setItem(key, JSON.stringify(item));
  },
};

export default lStorageService;
