<template>
  <!-- TAB SLIDER -->
  <div class="tab-slider">
    <label
      v-for="(option, index) in options"
      :key="typeof option.value !== 'undefined' ? option.value : option.slug"
      :class="`item tab-slider__item tab-slider__item--${index + 1}`"
      :checked="
        (typeof option.value !== 'undefined' ? option.value : option.slug) ===
        checked
      "
    >
      <input
        class="dot item__dot item__dot--hide"
        type="radio"
        :name="name"
        :value="
          typeof option.value !== 'undefined' ? option.value : option.slug
        "
        v-model="checked"
        @change="$emit('update:modelValue', checked)"
        :checked="
          (typeof option.value !== 'undefined' ? option.value : option.slug) ===
          checked
        "
      />
      <span class="text-wrapper item__text-wrapper">{{ option.label }}</span>
    </label>
    <div class="chosen-bg tab-slider__chosen-bg"></div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
export default {
  props: {
    modelValue: {
      type: [String, Number, Boolean],
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },

  emits: ["update:modelValue"],

  setup(props) {
    const checked = ref(props.modelValue);

    watch(
      () => props.modelValue,
      () => {
        checked.value = props.modelValue;
      }
    );
    const itemWidthPerc = computed(() => {
      return 96 / props.options.length + "%";
    });
    const translateX = computed(() => {
      return (
        100 +
        (4 / (props.options.length - 1) / (96 / props.options.length)) * 100 +
        "%"
      );
    });

    return { checked, itemWidthPerc, translateX };
  },
};
</script>

<style scoped>
/* TAB SLIDER  */

.tab-slider {
  width: 100%;
  height: var(--tab-slider-height);

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;

  background-color: var(--tab-slider-bg-color);
  border: var(--tab-slider-border);
  box-sizing: border-box;
  border-radius: var(--tab-slider-border-radius);

  font-size: var(--tab-slider-font-size);
}

.tab-slider__item {
  width: v-bind(itemWidthPerc);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.item__dot--hide {
  display: none;
}

.item__text-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;
  border-radius: var(--tab-slider-border-radius);
}

.item__text-wrapper:hover {
  background-color: var(--tab-slider-hover-bg-color);
  cursor: pointer;
}

.item__dot:checked + .item__text-wrapper {
  font-weight: 500;
  background-color: transparent;
}

.tab-slider__chosen-bg {
  width: v-bind(itemWidthPerc);
  height: 100%;

  display: block;
  position: absolute;

  border: var(--tab-slider-active-border);
  box-shadow: 0px 0px 0px 1px var(--tab-slider-active-border-color);
  border-radius: var(--tab-slider-border-radius);
  background-color: var(--tab-slider-active-bg-color);
  box-sizing: border-box;

  transition: transform 0.4s cubic-bezier(0.52, -0.05, 0.32, 1.26);
  transform: translate(0px);
}

.tab-slider__item--1[checked="true"] ~ .tab-slider__chosen-bg {
  transform: translateX(0);
}
.tab-slider__item--2[checked="true"] ~ .tab-slider__chosen-bg {
  transform: translateX(v-bind(translateX));
}
.tab-slider__item--3[checked="true"] ~ .tab-slider__chosen-bg {
  transform: translateX(calc(v-bind(translateX) * 2));
}
.tab-slider__item--4[checked="true"] ~ .tab-slider__chosen-bg {
  transform: translateX(calc(v-bind(translateX) * 3));
}
.tab-slider__item--5[checked="true"] ~ .tab-slider__chosen-bg {
  transform: translateX(calc(v-bind(translateX) * 4));
}
.tab-slider__item--6[checked="true"] ~ .tab-slider__chosen-bg {
  transform: translateX(calc(v-bind(translateX) * 5));
}
</style>
