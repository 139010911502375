import { prepareOfferNannyResponseInstance } from "@/helpers";
import { defineStore } from "pinia";
import { useUserStore } from "./user";
import responsesApi from "@/api/responses";
export const USER_RESPONSES_STORE_NAME = "USER_RESPONSES_STORE";

export const useUserResponsesStore = defineStore(USER_RESPONSES_STORE_NAME, {
  state: () => ({
    incoming: {
      // TODO: for future growth
      // onUserApplications: {
      //   // Placeholder: nanny: [{application: {}, responses: [{}]}]
      //   nanny: null,
      // },
      onUserOffers: {
        nanny: [], // [{onOffer: {}, responses: [{}]}]
      },
    },
    outgoing: {
      fromUserApplications: {
        nanny: null, // { fromApplication: {}, responses: [] },
      },
      // TODO: for future growth
      // fromUserOffers: {
      //   // Placeholder: nanny: [{offer: {}, responses: [{}]}]
      //   nanny: [],
      // },
    },
    class: "nanny",
    type: "offer",
    direction: "outgoing",
    storeMeta: {
      // isInitializing: false,
      // isInitialized: false,
      isLoading: false,
      // isPopulating: false,
      isUpdating: false,
      error: null,
    },
  }),

  getters: {
    currentResponsesIncoming() {
      let responsesLocalRaw = [];
      let responsesLocalReady = [];
      const responsesIsFetching = this.storeMeta.isLoading;
      const classResponses = this.class;
      const typeResponses = this.type;
      const direction = "incoming";

      if (typeResponses === "application") {
        if (classResponses === "nanny") {
          responsesLocalRaw = this.incoming.onUserOffers.nanny;
          responsesLocalReady = responsesLocalRaw.map((responseLocal) => {
            let onOffer = responseLocal.onOffer;
            let responsesRaw = responseLocal.responses;
            let responsesReady = [];

            if (Array.isArray(responsesRaw) === true) {
              responsesReady = responsesRaw.flatMap((responseRaw) => {
                try {
                  return prepareOfferNannyResponseInstance(responseRaw);
                } catch (error) {
                  console.log(error);
                  return [];
                }
              });
            }

            return {
              onOffer: onOffer,
              responses: responsesReady,
            };
          });
        }
      }

      return {
        value: responsesLocalReady,
        class: classResponses,
        type: typeResponses,
        direction: direction,
        isFetching: responsesIsFetching,
      };
    },

    currentResponsesOutgoing() {
      // let responsesLocalRaw;
      let responsesLocalReady;
      const responsesIsFetching = this.storeMeta.isLoading;
      const classResponses = this.class;
      const typeResponses = this.type;
      const direction = "outgoing";

      if (typeResponses === "offer") {
        if (classResponses === "nanny") {
          let responseLocalRaw = this.outgoing.fromUserApplications.nanny;

          if (responseLocalRaw !== null) {
            let fromApplication = responseLocalRaw.fromApplication;
            let responsesRaw = responseLocalRaw.responses;
            let responsesReady = [];

            if (Array.isArray(responsesRaw) === true) {
              responsesReady = responsesRaw.flatMap((responseRaw) => {
                try {
                  return prepareOfferNannyResponseInstance(responseRaw);
                } catch (error) {
                  console.log(error);
                  return [];
                }
              });
            }

            responsesLocalReady = [
              {
                fromApplication: fromApplication,
                responses: responsesReady,
              },
            ];
          } else {
            responsesLocalReady = [];
          }
        }
      }

      return {
        value: responsesLocalReady,
        class: classResponses,
        type: typeResponses,
        direction: direction,
        isFetching: responsesIsFetching,
      };
    },

    currentResponses() {
      const direction = this.direction;

      if (direction === "incoming") {
        return this.currentResponsesIncoming;
      }

      if (direction === "outgoing") {
        return this.currentResponsesOutgoing;
      }

      throw new Error(
        `Wrong 'direction'! Expected 'incoming' or 'outgoing', but received direction === '${direction}'`
      );
    },
  },

  actions: {
    // TODO: bad naming
    async fetchIncomingApplicationNannyResponses(onOffers) {
      try {
        this.storeMeta.isLoading = true;
        const responseIds = onOffers.flatMap((offer) => offer.response_ids);

        if (responseIds.length === 0) {
          return;
        }

        const responses =
          await responsesApi.fetchIncomingApplicationNannyResponses(
            responseIds
          );

        const incomingApplicationResponses = onOffers.map((offer) => {
          const offerResponseIds = offer.response_ids;

          if (offerResponseIds.length === 0) {
            return { onOffer: offer, responses: [] };
          }

          const currentOfferResponses = responses.filter((response) =>
            offerResponseIds.includes(response._id)
          );

          return { onOffer: offer, responses: currentOfferResponses };
        });

        this.incoming.onUserOffers.nanny = incomingApplicationResponses;
      } finally {
        this.storeMeta.isLoading = false;
      }
    },

    async fetchOutgoingOfferNannyResponses(fromApplication) {
      try {
        this.storeMeta.isLoading = true;

        if (fromApplication === null) {
          return;
        }

        const outgoingResponses =
          await responsesApi.fetchOutgoingOfferNannyResponses(
            fromApplication._id
          );

        this.outgoing.fromUserApplications.nanny = {
          fromApplication,
          responses: [...outgoingResponses],
        };
      } finally {
        this.storeMeta.isLoading = false;
      }
    },

    async initAccountResponsesView() {
      // TODO: this is a temporary fix, since we don't have a dynamic change of direction yet !
      const userStore = useUserStore();

      await userStore.refetchUserStore();

      this.direction =
        userStore.userType === "contractor" ? "outgoing" : "incoming";
      this.type = userStore.userType === "contractor" ? "offer" : "application";

      const direction = this.direction;
      const typeResponses = this.type;
      const classResponses = this.class;

      if (direction === "incoming") {
        if (typeResponses === "application") {
          if (classResponses === "nanny") {
            return this.fetchIncomingApplicationNannyResponses(
              userStore.offers.nanny_ids_populated
            );
          }
        }
      }

      if (direction === "outgoing") {
        if (typeResponses === "offer") {
          if (classResponses === "nanny") {
            return this.fetchOutgoingOfferNannyResponses(
              userStore.applications.nanny_id_populated
            );
          }
        }
      }

      throw new Error(
        `Wrong 'direction' === '${direction}' OR 'typeResponses' === '${typeResponses}' OR 'classResponses' === '${classResponses}'!'`
      );
    },
  },
});
