import { UPDATE_OFFER_NANNY } from "@/graphql/mutations/offerNanny";
import {
  GET_OFFERS_NANNY_FEED,
  GET_OFFER_NANNY,
} from "@/graphql/queries/offerNanny";
import { apolloClient } from "@/plugins/apollo";

export const getOfferNannyWatchQuery = ({
  offer_id,
  populatePath = "user_profile_id_populated",
}) => {
  const offerNannyQuery = apolloClient.watchQuery({
    query: GET_OFFER_NANNY,
    variables: {
      id: offer_id,
      populatePath,
    },
    fetchPolicy: "cache-and-network",
  });

  return offerNannyQuery;
};

export const getOfferNanny = async ({
  offer_id,
  populatePath = "user_profile_id_populated",
}) => {
  const { data } = await apolloClient.query({
    query: GET_OFFER_NANNY,
    variables: {
      id: offer_id,
      populatePath,
    },
    fetchPolicy: "network-only",
  });

  return JSON.parse(JSON.stringify(data.offerNanny));
};

export const getOffersNannyFeedWatchQuery = ({
  userLocation,
  maxDistance,
  populatePath,
  offset,
  limit,
}) => {
  return apolloClient.watchQuery({
    query: GET_OFFERS_NANNY_FEED,
    variables: {
      userLocation: userLocation,
      maxDistance: maxDistance,
      offset: offset,
      limit: limit,
      populatePath: populatePath,
    },
    fetchPolicy: "network-only",
    // nextFetchPolicy: "network-only",
  });
};

export const getOffersNannyFeed = ({
  filters,
  userLocation,
  maxDistance,
  populatePath,
  offset,
  limit,
}) => {
  return apolloClient.query({
    query: GET_OFFERS_NANNY_FEED,
    variables: {
      filters: filters,
      userLocation: userLocation,
      maxDistance: maxDistance,
      offset: offset,
      limit: limit,
      populatePath: populatePath,
    },
    fetchPolicy: "network-only",
  });
};

export const updateOfferNanny = async ({ offer_id, offerNannyStore }) => {
  console.log({ offer_id });
  console.log({ offerNannyStore });
  return await apolloClient.mutate({
    mutation: UPDATE_OFFER_NANNY,
    variables: {
      id: offer_id,
      payload: {
        user_id: offerNannyStore.user_id,
        user_profile_id: offerNannyStore.user_profile_id,
        details: {
          title: offerNannyStore.details.title,
          description: offerNannyStore.details.description,
          location: {
            city: offerNannyStore.details.location.city,
            address: offerNannyStore.details.location.address,
            point: {
              type: offerNannyStore.details.location.point.type,
              coordinates: offerNannyStore.details.location.point.coordinates,
            },
          },
          rate: {
            hour: offerNannyStore.details.rate.hour,
            month: offerNannyStore.details.rate.month,
          },
          phone: offerNannyStore.details.phone,
          aupair: offerNannyStore.details.aupair,
          kids: offerNannyStore.details.kids,
          isSpecialCare: offerNannyStore.details.isSpecialCare,
        },
        employment: {
          type: offerNannyStore.employment.type,
          regularity: offerNannyStore.employment.regularity,
        },
        schedule: {
          value: offerNannyStore.schedule.value,
          comment: offerNannyStore.schedule.comment,
          dateStart: offerNannyStore.schedule.dateStart,
          period: {
            term: offerNannyStore.schedule.period.term,
            measure: offerNannyStore.schedule.period.measure,
            amount: offerNannyStore.schedule.period.amount,
          },
        },
        requirements: {
          languages: offerNannyStore.requirements.languages,
          responsibilities: {
            careChild: offerNannyStore.requirements.responsibilities.careChild,
            careHome: offerNannyStore.requirements.responsibilities.careHome,
            activities:
              offerNannyStore.requirements.responsibilities.activities,
          },
          perks: offerNannyStore.requirements.perks,
        },
      },
    },
  });
};

export const archiveOfferNanny = async ({ offer_id }) => {
  await apolloClient.mutate({
    mutation: UPDATE_OFFER_NANNY,
    variables: {
      id: offer_id,
      payload: {
        meta: {
          status: "archived",
        },
      },
    },
  });
  return "OK";
};

export const pauseOfferNanny = async ({ offer_id }) => {
  await apolloClient.mutate({
    mutation: UPDATE_OFFER_NANNY,
    variables: {
      id: offer_id,
      payload: {
        meta: {
          status: "paused",
        },
      },
    },
  });
  return "OK";
};

export const runOfferNanny = async ({ offer_id }) => {
  await apolloClient.mutate({
    mutation: UPDATE_OFFER_NANNY,
    variables: {
      id: offer_id,
      payload: {
        meta: {
          status: "published",
        },
      },
    },
  });
  return "OK";
};

export const refreshOfferNanny = async ({ offer_id }) => {
  await apolloClient.mutate({
    mutation: UPDATE_OFFER_NANNY,
    variables: {
      id: offer_id,
      payload: {
        meta: {
          refreshedAt: Date.now(),
        },
      },
    },
  });
  return "OK";
};
