<template>
  <input-text
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    @focus="onFocus($event)"
    :type="'text'"
    :name="'client_tel'"
    :autocomplete="'off'"
    :class="'text-input--tel'"
    :label="'Telefon'"
    :small="true"
    :placeholder="'+48 123 456 789'"
    :mask="maskRef"
  >
  </input-text>
</template>

<script>
import useIMaskTelephone from "@/composables/imask/useIMaskTelephone";
import { addEditableImaskPlaceholder } from "@/helpers";

export default {
  props: {
    modelValue: String,
  },

  emits: ["update:modelValue"],

  setup() {
    const { maskRef } = useIMaskTelephone();
    const predefinedPhoneCode = "+48 ";

    const onFocus = (event) => {
      let el = event.target;
      if (!el.value) {
        addEditableImaskPlaceholder(event, predefinedPhoneCode);
      }
    };

    return {
      maskRef,
      onFocus,
    };
  },
};
</script>

<style>
/* For styles loook into InputText < style > block */
</style>
