<template>
  <InputText
    :type="'text'"
    :name="'client_tel_code'"
    :autocomplete="'off'"
    :class="'text-input--tel-code'"
    :label="'Kod z SMS'"
    :small="true"
    :placeholder="'000 - 000'"
    :mask="maskRef"
    :resultIsMasked="false"
    @focus="onFocus"
  />
</template>

<script>
import useIMaskTelephoneCode from "@/composables/imask/useIMaskTelephoneCode";
import useActivateIMaskPattern from "@/composables/imask/useActivateIMaskPattern";
import InputText from "./Base/InputText.vue";

export default {
  components: { InputText },

  setup() {
    const { maskRef } = useIMaskTelephoneCode();
    const { activateIMaskPattern } = useActivateIMaskPattern();

    const onFocus = () => {
      activateIMaskPattern(maskRef);
    };

    return {
      maskRef,
      onFocus,
    };
  },
};
</script>

<style>
/* For styles loook into InputText < style > block */
</style>
