import { toFirstCharUpper } from "@/helpers";

export const prepareFullName = ({
  fullName,
  firstName,
  lastName,
  isShortLastName = true,
}) => {
  fullName = fullName || "";
  firstName = firstName || "";
  lastName = lastName || "";

  let readyFullName = "";
  let readyFirstName = "";
  let readyLastName = "";

  if (fullName) {
    fullName = fullName.trim();
    let splittedFullName = fullName.split(" ");
    splittedFullName.forEach((name, index) => {
      if (index === 0) {
        readyFullName = toFirstCharUpper(name.trim());
      } else {
        if (isShortLastName) {
          let firstCharUpper = name.trim().charAt(0).toUpperCase();
          readyFullName = readyFullName + " " + firstCharUpper + ".";
        } else if (!isShortLastName) {
          let preparedCurrentName = toFirstCharUpper(name.trim());
          readyFullName = readyFullName + " " + preparedCurrentName;
        }
      }
    });
  } else {
    // Ensure that first name has a form of "Name" or "Name1 Name2 ..."
    firstName = firstName.trim();
    let splittedFirstName = firstName.split(/[ -]/);
    if (splittedFirstName.length > 1) {
      splittedFirstName.forEach((name, index) => {
        if (index === splittedFirstName.length - 1) {
          readyFirstName = readyFirstName + toFirstCharUpper(name.trim());
        } else {
          readyFirstName = readyFirstName + toFirstCharUpper(name.trim()) + " ";
        }
      });
    } else {
      readyFirstName = toFirstCharUpper(firstName);
    }

    // Ensure that last name has a form of "Name" or "Name1 Name2 ..."
    lastName = lastName.trim();
    let splittedLastName = lastName.split(/[ -]/);
    if (splittedLastName.length > 1) {
      splittedLastName.forEach((name, index) => {
        if (index === splittedLastName.length - 1) {
          readyLastName = readyLastName + toFirstCharUpper(name.trim());
        } else {
          readyLastName = readyLastName + toFirstCharUpper(name.trim()) + "-";
        }
      });
    } else {
      readyLastName = toFirstCharUpper(lastName.trim());
    }

    if (isShortLastName) {
      readyFullName = readyFirstName + " " + readyLastName.charAt(0) + ".";
    } else if (!isShortLastName) {
      readyFullName = readyFirstName + " " + readyLastName;
    }
  }
  return readyFullName;
};
