import authService from "@/services/authService";
import jwtService from "@/services/jwtService";
import {
  ApolloClient,
  InMemoryCache,
  // createHttpLink,
  ApolloLink,
} from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import createUploadLink from "apollo-upload-client/public/createUploadLink";

const cache = new InMemoryCache({
  // typePolicies: {
  //   Query: {
  //     fields: {
  //       offersNannyFeed: {
  //         // Don't cache separate results based on
  //         // any of this field's arguments.
  //         keyArgs: false,
  //         merge(existing, incoming) {
  //           console.log("HERE!!!!!!!!");
  //           console.log({ existing });
  //           console.log({ incoming });
  //           const newOffersNannyFeed = incoming;
  //           const prevOffersNannyFeed = existing
  //             ? existing
  //             : {
  //                 limit: newOffersNannyFeed.limit,
  //                 offset: newOffersNannyFeed.offset,
  //                 totalCount: newOffersNannyFeed.totalCount,
  //                 value: [],
  //               };
  //           JSON.parse(JSON.stringify(prevOffersNannyFeed));
  //           const mergedOffersNannyFeed = Object.assign(
  //             {},
  //             prevOffersNannyFeed,
  //             newOffersNannyFeed,
  //             {
  //               value: [
  //                 ...prevOffersNannyFeed.value,
  //                 ...newOffersNannyFeed.value,
  //               ],
  //             }
  //           );
  //           Object.freeze(mergedOffersNannyFeed);
  //           // Object.defineProperty(mergedOffersNannyFeed, "value", {
  //           //   writable: true,
  //           //   enumerable: true,
  //           //   configurable: true,
  //           // });
  //           // console.log(
  //           //   Object.getOwnPropertyDescriptor(mergedOffersNannyFeed, "value")
  //           // );
  //           return mergedOffersNannyFeed;
  //         },
  //       },
  //     },
  //   },
  // },
});

const getHeaders = () => {
  const headers = {};
  const accessToken = jwtService.getAccessTokenFromLS();
  if (accessToken) headers["Authorization"] = `Bearer ${accessToken}`;
  return headers;
};

function createLink() {
  // const httpLink = createHttpLink({
  //   uri: process.env.VUE_APP_GRAPHQL_URL,
  //   credentials: "include",
  // });
  const httpLink = createUploadLink({
    uri: process.env.VUE_APP_GRAPHQL_URL,
    credentials: "include",
  });
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...getHeaders(),
      },
    }));
    return forward(operation);
  });

  const handleGraphQLError = (code) => {
    switch (code) {
      case "UNAUTHENTICATED":
        console.log(code);
        authService.logout();
        return "OK";
      case 500:
        //
        break;
      case 429:
        //
        break;
      case undefined:
        //
        break;
      default:
        console.log(code);
        break;
    }
  };

  const handleNetworkError = (code) => {
    switch (code) {
      case 401:
        authService.logout();
        return "OK";
      // case 500:
      //   break;
      // case 429:
      //   break;
      // case undefined:
      //   break;
      default:
        console.log(code);
        break;
    }
  };

  const logoutLink = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
      console.log(graphQLErrors);
      for (let {
        extensions: { code },
      } of graphQLErrors) {
        const errorHandleStatus = handleGraphQLError(code);
        if (errorHandleStatus === "OK") {
          // response.errors = null;
        }
      }
    }

    if (networkError) {
      const errorHandleStatus = handleNetworkError(networkError.statusCode);
      if (errorHandleStatus === "OK") {
        // response.errors = null;
      }
    }
  });

  return ApolloLink.from([logoutLink, authLink, httpLink]);
}

export const apolloClient = new ApolloClient({
  cache,
  link: createLink(),
});
