// Validating img file
export const isFileTypeOfImage = (file) => {
  // Ensure file type is image
  if (file.type.indexOf("image/") === -1) {
    alert("Please select an image file");
    return false;
  }

  return true;
};

// Validating img file
export const isImageFileSizeValid = (file, maxSizeBytes) => {
  // Ensure image size is less than requirements.maxSizeBytes
  if (maxSizeBytes) {
    if (file.size > maxSizeBytes) {
      alert("File is too big! Please select a smaller one!");
      return false;
    }
  }
  return true;
};

export const validateImageFile = (imageFile, maxSizeBytes) => {
  if (isFileTypeOfImage(imageFile) === false) {
    throw Error("File is not an image!");
  }

  if (isImageFileSizeValid(imageFile, maxSizeBytes) === false) {
    throw Error(`File exceeds maximum allowed size of ${maxSizeBytes} bytes!`);
  }
};

// Determining image orientation (vertical or horizontal)
export const getImgOrientationFromSrc = async (src) => {
  try {
    const img = new Image();
    img.src = src;
    await img.decode();
    if (img.width > img.height) {
      return "horizontal";
    } else {
      return "vertical";
    }
  } catch (error) {
    console.log(error);
  }
};

export const fileToDataUrl = async (file) => {
  // Checking browser compatibility
  if (typeof FileReader !== "function") {
    alert("Sorry, FileReader API not supported");
    throw new Error("FileReader API not supported");
  }

  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = (event) => {
      resolve(event.target.result);
    };

    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const dataUrlToImage = async (dataUrl) => {
  const img = new Image();
  img.src = dataUrl;
  await img.decode();
  return img;
};

export const validateImageWithMinDimensions = (
  image,
  { minWidth, minHeight }
) => {
  let imgWidth = image.width;
  let imgHeight = image.height;

  // Ensure image width is more than minWidth
  if (imgWidth < minWidth) {
    throw new Error("Use another photo that matches minimal dimensions!");
  }

  //  Ensure image height is more than minHeight
  if (imgHeight < minHeight) {
    throw new Error("Use another photo that matches minimal dimensions!");
  }

  return "OK";
};

// TODO: WTF ??? why not check both width and height ???
export const resizeImageByMaxDimensions = (image, { maxWidth, maxHeight }) => {
  const imageWidth = image.width;
  const imageHeight = image.height;

  // Resizing image if its smallest side is larger than maxHeight OR maxWidth (to save memory)
  // Vertical
  if (imageWidth < imageHeight) {
    if (imageWidth > maxWidth) {
      image.height *= maxWidth / imageWidth;
      image.width = maxWidth;
    }
    // Horizontal
  } else {
    if (imageHeight > maxHeight) {
      image.width *= maxHeight / imageHeight;
      image.height = maxHeight;
    }
  }

  return image;
};

export const imageToCanvas = (image, width, height) => {
  let canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  canvas.getContext("2d").drawImage(image, 0, 0, width, height);
  return canvas;
};

export const imageFileToImage = async (imageFile) => {
  const imgDataUrl = await fileToDataUrl(imageFile);
  // Converting img src to Image
  return dataUrlToImage(imgDataUrl);
};

export const prepareCanvasFromImageFile = async (
  file,
  { minWidth, minHeight, maxWidth, maxHeight, maxSizeBytes }
) => {
  // Validate image file
  validateImageFile(file, maxSizeBytes);

  // Convert file to Image
  const image = await imageFileToImage(file);

  // Validate image with min dimensions
  validateImageWithMinDimensions(image, { minWidth, minHeight });

  // Resize image by max dimensions if needed
  const imageReady = resizeImageByMaxDimensions(image, {
    maxWidth,
    maxHeight,
  });

  // Draw canvas from image and return it
  const canvas = imageToCanvas(imageReady, imageReady.width, imageReady.height);

  return canvas;
};

// export const imageFileToCanvas = async (imageFile) => {
//   const image = await imageFileToImage(imageFile);

//   // // Validate with min dimensions
//   validateImageWithMinDimensions(image, { minWidth, minHeight });

//   const imageReady = resizeImageByMaxDimensions(image, {
//     maxWidth,
//     maxHeight,
//   });

//   return imageToCanvas(imageReady, imageReady.width, imageReady.height);
// };

// Getting validated and resized Img in base64 format
// export const getImageSrcReadyFromFileAsync = async (
//   file,
//   { minWidth, minHeight, maxWidth, maxHeight }
// ) => {
//   // Getting prepared image src
//   const imgSrcReady = imgOriginalReadyCanvas.toDataURL(file.type);

//   let imgFileReady;
//   imgOriginalReadyCanvas.toBlob((blob) => {
//     imgFileReady = new File([blob], file.name, { type: blob.type });
//   });

//   // Returning prepared image src
//   return {
//     src: imgSrcReady,
//     imgFile: imgFileReady,
//   };
// };
