// Asking for email verification (push to check out mail)
// Auth --> Email verification (request)
export const ROUTE_EMAIL_VERIFICATION_SEND_LINK_PATH = "/email-verification";
export const ROUTE_EMAIL_VERIFICATION_SEND_LINK_NAME =
  "AuthEmailVerificationSendLink";

// Verify email by verification key. Notify about Success / Failure
// Auth --> Email verification ( processing -> Success / Failure )
export const ROUTE_EMAIL_VERIFICATION_VERIFY_LINK_PATH = "/verify-email";
export const ROUTE_EMAIL_VERIFICATION_VERIFY_LINK_NAME =
  "AuthEmailVerificationVerifyLink";
