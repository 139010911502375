import { onBeforeMount, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useUserStore } from "@/stores/user";
import useAuth from "@/composables/useAuth";

import {
  ROUTE_LOGOUT_NAME,
  ROUTE_EMAIL_VERIFICATION_SEND_LINK_NAME,
  ROUTE_EMAIL_VERIFICATION_VERIFY_LINK_NAME,
  ROUTE_REGISTRATION_NAME,
  ROUTE_REGISTRATION_START_NAME,
} from "@/router/constants";

const ROUTE_EMAIL_VERIFICATION_SEND_LINK = {
  name: ROUTE_EMAIL_VERIFICATION_SEND_LINK_NAME,
};

export default function useEmailVerificationGuard() {
  const userStore = useUserStore();

  if (userStore.emailIsVerified === true) {
    return;
  }

  const allowedRouteNames = [
    ROUTE_EMAIL_VERIFICATION_SEND_LINK_NAME,
    ROUTE_EMAIL_VERIFICATION_VERIFY_LINK_NAME,
    ROUTE_LOGOUT_NAME,
    ROUTE_REGISTRATION_NAME,
    ROUTE_REGISTRATION_START_NAME,
  ];

  const { isAuth } = useAuth();
  const router = useRouter();
  const route = useRoute();
  let __stopRouterGuard = null;

  watch(
    () => userStore.emailIsVerified,
    (isEmailVerified) => {
      if (isEmailVerified === true) {
        stopRouterGuard();
      }
    }
  );

  watch(isAuth, (newIsAuth) => {
    if (newIsAuth && userStore.emailIsVerified === false) {
      startRouterGuard();
    } else {
      stopRouterGuard();
    }
  });

  const startRouterGuard = () => {
    if (__stopRouterGuard) {
      // Guard was already started. Do NOT start it again...
      return;
    }
    __stopRouterGuard = router.beforeEach((to) => {
      if (isAuth.value === false) {
        stopRouterGuard();
        return true;
      }

      if (isRouteAllowed(to.name)) {
        return true;
      }

      return ROUTE_EMAIL_VERIFICATION_SEND_LINK;
    });
  };

  const stopRouterGuard = () => {
    const stop = __stopRouterGuard;
    if (stop !== null) {
      __stopRouterGuard === null;
      stop();
    }
  };

  const redirectToEmailVerification = () => {
    router.push(ROUTE_EMAIL_VERIFICATION_SEND_LINK);
  };

  const isRouteAllowed = (routeName) => {
    return allowedRouteNames.includes(routeName);
  };

  onBeforeMount(() => {
    if (isAuth.value === true) {
      startRouterGuard();
      if (!isRouteAllowed(route.name)) {
        redirectToEmailVerification();
      }
    }
  });
}
