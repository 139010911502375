<template>
  <!-- CHECKBOXES STICKED -->
  <div class="checkbox-container" :class="containerClass">
    <label
      v-for="option in options"
      :key="option.value || option.slug"
      class="checkbox-button checkbox-container__checkbox-button"
    >
      <input
        v-model="result"
        @change="$emit('update:modelValue', result)"
        type="checkbox"
        :name="name"
        :value="option.value || option.slug"
        class="dot checkbox-button__dot checkbox-button__dot--hide"
      />
      <span class="text-wrapper checkbox-button__text-wrapper">{{
        option.label
      }}</span>
    </label>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";

export default {
  props: {
    modelValue: {
      type: [Array],
      default: () => [],
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      required: true,
      type: Array,
    },
    columns: {
      type: [Number],
      default: 1,
    },
  },

  emits: ["update:modelValue"],

  setup(props) {
    const result = ref(props.modelValue);

    watch(
      () => props.modelValue,
      () => {
        result.value = props.modelValue;
      }
    );

    const containerClass = computed(() => {
      return `checkbox-container--${props.columns}-columns`;
    });

    return {
      result,
      containerClass,
    };
  },
};
</script>

<style scoped>
/* CHECKBOX STICKED */

.checkbox-container {
  width: fit-content;
  display: grid;
  grid-auto-rows: auto;

  justify-content: center;
}

.checkbox-container__checkbox-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 10px;
  margin: -2px 0 0 -2px;
}

/* * 1 column  */
.checkbox-container--1-columns {
  grid-template-columns: 260px;
}

.checkbox-container--1-columns
  .checkbox-container__checkbox-button:nth-child(1),
.checkbox-container--1-columns
  .checkbox-container__checkbox-button:nth-child(1)
  .checkbox-button__text-wrapper {
  border-radius: var(--button-border-radius) var(--button-border-radius) 0 0;
}
.checkbox-container--1-columns
  .checkbox-container__checkbox-button:nth-last-child(1),
.checkbox-container--1-columns
  .checkbox-container__checkbox-button:nth-last-child(1)
  .checkbox-button__text-wrapper {
  border-radius: 0 0 var(--button-border-radius) var(--button-border-radius);
}
/* * 1 column END  */

/* * 2 columns  */
.checkbox-container--2-columns {
  grid-template-columns: 180px 180px;
}

.checkbox-container--2-columns
  .checkbox-container__checkbox-button:nth-child(1),
.checkbox-container--2-columns
  .checkbox-container__checkbox-button:nth-child(1)
  .checkbox-button__text-wrapper {
  border-radius: var(--button-border-radius) 0 0 0;
}

.checkbox-container--2-columns
  .checkbox-container__checkbox-button:nth-child(2),
.checkbox-container--2-columns
  .checkbox-container__checkbox-button:nth-child(2)
  .checkbox-button__text-wrapper {
  border-radius: 0 var(--button-border-radius) 0 0;
}

.checkbox-container--2-columns
  .checkbox-container__checkbox-button:nth-last-child(1),
.checkbox-container--2-columns
  .checkbox-container__checkbox-button:nth-last-child(1)
  .checkbox-button__text-wrapper {
  border-radius: 0 0 var(--button-border-radius) 0;
}

.checkbox-container--2-columns
  .checkbox-container__checkbox-button:nth-last-child(2),
.checkbox-container--2-columns
  .checkbox-container__checkbox-button:nth-last-child(2)
  .checkbox-button__text-wrapper {
  border-radius: 0 0 0 var(--button-border-radius);
}

/* * 2 columns END  */

/* * 3 columns  */
.checkbox-container--3-columns {
  grid-template-columns: 186px 186px 186px;
}

.checkbox-container--3-columns
  .checkbox-container__checkbox-button:nth-child(1),
.checkbox-container--3-columns
  .checkbox-container__checkbox-button:nth-child(1)
  .checkbox-button__text-wrapper {
  border-radius: var(--button-border-radius) 0 0 0;
}

.checkbox-container--3-columns
  .checkbox-container__checkbox-button:nth-child(3),
.checkbox-container--3-columns
  .checkbox-container__checkbox-button:nth-child(3)
  .checkbox-button__text-wrapper {
  border-radius: 0 var(--button-border-radius) 0 0;
}

.checkbox-container--3-columns
  .checkbox-container__checkbox-button:nth-last-child(1),
.checkbox-container--3-columns
  .checkbox-container__checkbox-button:nth-last-child(1)
  .checkbox-button__text-wrapper {
  border-radius: 0 0 var(--button-border-radius) 0;
}

.checkbox-container--3-columns
  .checkbox-container__checkbox-button:nth-last-child(3),
.checkbox-container--3-columns
  .checkbox-container__checkbox-button:nth-last-child(3)
  .checkbox-button__text-wrapper {
  border-radius: 0 0 0 var(--button-border-radius);
}
/* * 3 columns END  */

.checkbox-button__text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: fit-content;
  width: 100%;
  height: var(--button-height);

  color: var(--checkbox-button-font-color);
  font-family: var(--default-font-family);
  font-size: var(--checkbox-button-font-size);
  font-weight: var(--checkbox-button-font-weight);
  text-align: center;

  background-color: var(--main-bg-color);
  border: 1px solid var(--checkbox-button-stroke-color);
  box-sizing: border-box;
}

.checkbox-button__text-wrapper:hover {
  border: var(--checkbox-button-hover-border);
  z-index: 10;
  cursor: pointer;
}
.checkbox-button__dot--hide {
  display: none;
}

.checkbox-button__dot:checked + .checkbox-button__text-wrapper {
  border: var(--checkbox-button-active-border);
  background-color: var(--checkbox-button-active-bg-color);
  z-index: 11;

  font-weight: var(--checkbox-button-active-font-weight);
}

@media (max-width: 1200px) {
  /* CHECKBOXES STICKED MOBILE */

  /* * 1 column  */
  .checkbox-container--1-columns {
    grid-template-columns: 212px;
  }
  /* * 1 columns END  */

  /* * 2 columns  */
  .checkbox-container--2-columns {
    grid-template-columns: 125px 125px;
  }

  /* * 2 columns END  */

  /* * 3 columns  */
  .checkbox-container--3-columns {
    grid-template-columns: 115px 115px 115px;
  }
  /* * 3 columns END  */
}
</style>
