import { defineAsyncComponent } from "@vue/runtime-core";

// Offer --> Nanny
import OfferNannyCreateRoute from "@/router/routes/offerNanny/offerNannyCreate";
import {
  ROUTE_LIST_NANNY_NAME,
  ROUTE_OFFER_NANNY_DETAILS_NAME,
  ROUTE_OFFER_NANNY_DETAILS_PATH,
  ROUTE_OFFER_NANNY_LIST_PATH,
  ROUTE_OFFER_NANNY_UPDATE_NAME,
  ROUTE_OFFER_NANNY_UPDATE_PATH,
} from "@/router/constants";

const OfferNannyUpdate = () =>
  import("@/views/Offer/Nanny/OfferNannyUpdate.vue");
const OfferNannyDetails = () =>
  import("@/views/Offer/Nanny/Details/OfferNannyDetails.vue");

// Offer --> Nanny
export default [
  // Offer Nanny
  OfferNannyCreateRoute,
  {
    path: ROUTE_OFFER_NANNY_UPDATE_PATH,
    name: ROUTE_OFFER_NANNY_UPDATE_NAME,
    component: OfferNannyUpdate,
    meta: {
      authGuard: true,
    },
  },
  {
    path: ROUTE_OFFER_NANNY_LIST_PATH,
    redirect: { name: ROUTE_LIST_NANNY_NAME }, // redirect to List view (aka "search page")
  },
  {
    path: ROUTE_OFFER_NANNY_DETAILS_PATH,
    name: ROUTE_OFFER_NANNY_DETAILS_NAME,
    component: OfferNannyDetails,
    meta: {
      layout: defineAsyncComponent({
        loader: () => import(`@/layouts/LayoutDetails.vue`),
        delay: 0,
      }),
    },
  },
];
