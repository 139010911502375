<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
  >
    <path
      d="M16 13C19.09 10.19 22 7.56 22 5.3C22 3.45 20.55 2 18.7 2C17.66 2 16.65 2.49 16 3.25C15.34 2.49 14.34 2 13.3 2C11.45 2 10 3.45 10 5.3C10 7.56 12.91 10.19 16 13ZM13.3 4C13.74 4 14.19 4.21 14.48 4.55L16 6.34L17.52 4.55C17.81 4.21 18.26 4 18.7 4C19.44 4 20 4.56 20 5.3C20 6.42 17.96 8.47 16 10.29C14.04 8.47 12 6.41 12 5.3C12 4.56 12.56 4 13.3 4Z"
      fill="black"
    />
    <path
      d="M19 16H17C17 14.8 16.25 13.72 15.13 13.3L8.97 11H1V22H7V20.56L14 22.5L22 20V19C22 17.34 20.66 16 19 16ZM3 20V13H5V20H3ZM13.97 20.41L7 18.48V13H8.61L14.43 15.17C14.77 15.3 15 15.63 15 16C15 16 13.01 15.95 12.7 15.85L10.32 15.06L9.69 16.96L12.07 17.75C12.58 17.92 13.11 18.01 13.65 18.01H19C19.39 18.01 19.74 18.24 19.9 18.57L13.97 20.41Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style scoped>
.svg-icon path {
  fill: var(--default-font-color);
}
</style>
