const AuthWelcome = () => import("@/views/Auth/AuthWelcome.vue");
import { ROUTE_WELCOME_NAME, ROUTE_WELCOME_PATH } from "@/router/constants";

import logoutRoute from "@/router/routes/auth/authLogout";
import loginRoute from "@/router/routes/auth/authLogin";
import registrationRoute from "@/router/routes/auth/authRegistration";
import emailVerificationRoutes from "@/router/routes/auth/authEmailVerification";
import passwordResetRoutes from "@/router/routes/auth/authPasswordReset";

// Auth
export default [
  {
    path: ROUTE_WELCOME_PATH,
    name: ROUTE_WELCOME_NAME,
    component: AuthWelcome,
  },

  logoutRoute,
  loginRoute,
  registrationRoute,
  ...emailVerificationRoutes,
  ...passwordResetRoutes,
];
