export * from "./applicationNannyCreate";

// Application Nanny --> Update
export const ROUTE_APPLICATION_NANNY_UPDATE_NAME = "ApplicationNannyUpdate";
export const ROUTE_APPLICATION_NANNY_UPDATE_PATH =
  "/application/nanny/update/id-:id";

// Application Nanny --> List
export const ROUTE_APPLICATION_NANNY_LIST_PATH = "/applications/nanny";

// Application Nanny --> Details
export const ROUTE_APPLICATION_NANNY_DETAILS_NAME = "ApplicationNannyDetails";
export const ROUTE_APPLICATION_NANNY_DETAILS_PATH =
  "/applications/nanny/id-:id";
