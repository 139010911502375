import SvgIconsDrivingLicense24 from "@/components/Svg/Icons/SvgIconsDrivingLicense24";
import SvgIconsNonSmoking24 from "@/components/Svg/Icons/SvgIconsNonSmoking24";
import SvgIconsOwnCar24 from "@/components/Svg/Icons/SvgIconsOwnCar24";
import SvgIconsOwnKids24 from "@/components/Svg/Icons/SvgIconsOwnKids24";
import SvgIconsReadyToTravel24 from "@/components/Svg/Icons/SvgIconsReadyToTravel24";
import SvgIconsSpecialCare24 from "@/components/Svg/Icons/SvgIconsSpecialCare24";
import SvgIconsTwins24 from "@/components/Svg/Icons/SvgIconsTwins24";

import SvgIconsArtistic24 from "@/components/Svg/Icons/SvgIconsArtistic24";
import SvgIconsMedical24 from "@/components/Svg/Icons/SvgIconsMedical24";
import SvgIconsMusical24 from "@/components/Svg/Icons/SvgIconsMusical24";
import SvgIconsTeaching24 from "@/components/Svg/Icons/SvgIconsTeaching24";
import SvgIconsPsychological24 from "@/components/Svg/Icons/SvgIconsPsychological24";

import { markRaw } from "vue";

export default function useApplicationNannyDictionary() {
  const DICTIONARY = {
    listItemsTypes: {
      offer: {
        slug: "offer",
        label: "Ogłoszenia Rodziców",
      },
      application: {
        slug: "application",
        label: "Profile Opiekunek",
      },
    },
    aupair: {
      not_in_aupair: {
        slug: "not_in_aupair",
        label: "Bez zamieszkania",
      },
      in_aupair: {
        slug: "in_aupair",
        label: "Z zamieszkaniem",
      },
    },
    activeness: {
      actual: {
        slug: "actual",
        label: "aktualna ankieta",
      },
      outdated: {
        slug: "outdated",
        label: "OUTDATED",
      },
    },
    with_photo_only: {
      slug: "with_photo_only",
      label: "Tylko ze zdjęciem",
    },
    languages: {
      en: {
        iconSrc: require("@/assets/images/icons/languages/en.png"),
        label: "English",
        slug: "en",
      },
      it: {
        // iconSrc: require("@/assets/images/icons/languages/it.png"),
        label: "Italiano",
        slug: "it",
      },
      fr: {
        // iconSrc: require("@/assets/images/icons/languages/fr.png"),
        label: "Français",
        slug: "fr",
      },
      de: {
        // iconSrc: require("@/assets/images/icons/languages/de.png"),
        label: "Deutsch",
        slug: "de",
      },
      es: {
        iconSrc: require("@/assets/images/icons/languages/es.png"),
        label: "Español",
        slug: "es",
      },
      ru: {
        iconSrc: require("@/assets/images/icons/languages/ru.png"),
        label: "Русский",
        slug: "ru",
      },
      pl: {
        iconSrc: require("@/assets/images/icons/languages/pl.png"),
        label: "Polski",
        slug: "pl",
      },
      ch: {
        // iconSrc: require("@/assets/images/icons/languages/ch.png"),
        label: "漢語",
        slug: "ch",
      },
      ua: {
        // iconSrc: require("@/assets/images/icons/languages/ua.png"),
        label: "Українська",
        slug: "ua",
      },
    },
    employment: {
      types: {
        full_time: {
          slug: "full_time",
          label: "Pełen etat",
          labelShort: "Pełen",
        },
        part_time: {
          slug: "part_time",
          label: "Część etatu",
          labelShort: "Część",
        },
      },
      regularities: {
        occasionally: {
          slug: "occasionally",
          label: "Dorywczo",
        },
        regularly: {
          slug: "regularly",
          label: "Regularnie",
        },
        baby_sitting: {
          slug: "baby_sitting",
          label: "Jednorazowo",
        },
      },
    },
    perks: {
      non_smoking: {
        slug: "non_smoking",
        label: "niepaląca",
        labelShort: "Niepaląca",
      },
      driver_license: {
        slug: "driver_license",
        label: "posiada prawo jazdy",
        labelShort: "Prawo jazdy",
      },
      own_car: {
        slug: "own_car",
        label: "posiada własny samochód",
        labelShort: "Własny samochód",
      },
      ready_to_travel: {
        slug: "ready_to_travel",
        label: "gotowa do podroży",
        labelShort: "Gotowa do podroży",
      },
    },
    advantages: {
      non_smoking: {
        slug: "non_smoking",
        label: "Niepaląca",
        labelShort: "Niepaląca",
        iconComponent: markRaw(SvgIconsNonSmoking24),
      },
      driver_license: {
        slug: "driver_license",
        label: "Posiadam prawo jazdy",
        labelShort: "Prawo jazdy",
        iconComponent: markRaw(SvgIconsDrivingLicense24),
      },
      own_car: {
        slug: "own_car",
        label: "Posiadam własny samochód",
        labelShort: "Własny samochód",
        iconComponent: markRaw(SvgIconsOwnCar24),
      },
      ready_to_travel: {
        slug: "ready_to_travel",
        label: "Gotowa do podroży",
        labelShort: "Gotowa do podroży",
        iconComponent: markRaw(SvgIconsReadyToTravel24),
      },
      own_kids: {
        slug: "own_kids",
        label: "Posiadam własnych dzieci",
        labelShort: "Własne dzieci",
        iconComponent: markRaw(SvgIconsOwnKids24),
      },
      twins: {
        slug: "twins",
        label: "Doświadczenie w opiece blizniakami",
        labelShort: "Doświadczenie w opiece blizniakami",
        iconComponent: markRaw(SvgIconsTwins24),
      },
      special_care: {
        slug: "special_care",
        label: "Doświadczenie w opiece nad dziećmi specjalnej troski",
        labelShort: "Doświadczenie w opiece nad dziećmi specjalnej troski",
        iconComponent: markRaw(SvgIconsSpecialCare24),
      },
    },
    responsibilities: {
      childCare: {
        hygiene: {
          label: "higiena",
          slug: "hygiene",
        },
        feeding: {
          label: "karmenie",
          slug: "feeding",
        },
        walks: {
          label: "spacery",
          slug: "walks",
        },
        safeguarding: {
          label: "towarzyszenie i zabezpieczenie",
          slug: "safeguarding",
        },
      },
      homeCare: {
        laundry: {
          label: "Pranie",
          slug: "laundry",
          iconSrc: require("@/assets/images/icons/home-care/basket_1f9fa.png"),
        },
        ironing: {
          label: "Prasowanie",
          slug: "ironing",
          iconSrc: require("@/assets/images/icons/home-care/necktie_1f454.png"),
        },
        cleaning: {
          label: "Sprzątanie",
          slug: "cleaning",
          iconSrc: require("@/assets/images/icons/home-care/broom_1f9f9.png"),
        },
        maintaining_cleaniness: {
          label: "Utrzymanie czystości",
          slug: "maintaining_cleaniness",
          iconSrc: require("@/assets/images/icons/home-care/sponge_1f9fd.png"),
        },
        cooking: {
          label: "Gotowanie",
          slug: "cooking",
          iconSrc: require("@/assets/images/icons/home-care/cooking_1f373.png"),
        },
        pet_care: {
          label: "Opieka nad zwierzętami",
          slug: "pet_care",
          iconSrc: require("@/assets/images/icons/home-care/paw-prints_1f43e.png"),
        },
        plant_care: {
          label: "Pielęgnacja roślin",
          slug: "plant_care",
          iconSrc: require("@/assets/images/icons/home-care/potted-plant_1fab4.png"),
        },
        shopping: {
          label: "Zakupy",
          slug: "shopping",
          iconSrc: require("@/assets/images/icons/home-care/shopping-cart_1f6d2.png"),
        },
      },
      activities: {
        education: {
          slug: "education",
          label: "Zajęcia edukacją",
          labelShort: "edukacją",
        },
        creativity: {
          slug: "creativity",
          label: "Zajęcia kreatywnośćą",
          labelShort: "kreatywnośćą",
        },
        music: {
          slug: "music",
          label: "Zajęcia muzyką",
          labelShort: "muzyką",
        },
        games: {
          slug: "games",
          label: "Zajęcia grami i zabawami",
          labelShort: "grami i zabawami",
        },
        homework: {
          slug: "homework",
          label: "Zajęcia pomocą w odrabianiu lekcji",
          labelShort: "pomocą w odrabianiu lekcji",
        },
      },
    },
    experience: {
      working: {
        kidsAges: {
          "0_3": "0-3 lata",
          "4_7": "4-7 lat",
          "8_10": "8-10 lat",
          "10_": "10+ lat",
          "0_7": "0-7 lat",
          "0_10": "0-10 lat",
          "0-10_": "0-10+ lat",
          "4_10": "4-10 lat",
          "4_10_": "4-10+ lat",
          "8_10_": "8-10+ lat",
        },
      },
      life: {
        own_kids: {
          slug: "own_kids",
          label: "własne dzieci",
          labelShort: "rodzicа",
        },
        special_care: {
          slug: "special_care",
          label: "doświadczenie z dziećmi specjalnej troski",
          labelShort: "z dziećmi specjalnej troski",
        },
        twins: {
          slug: "twins",
          label: "doświadczenie w opiece blizniakami",
          labelShort: "z bliźniakami",
        },
      },
      filter: {
        working_experience: {
          slug: "working_experience",
          label: "Doświadczenie zawodowe w pracy z dziećmi",
          labelShort: "zawodowe w pracy z dziećmi",
        },
        own_kids: {
          slug: "own_kids",
          label: "Opiekunka posiada własnych dzieci",
          labelShort: "rodzica (posiada własnych dzieci)",
        },
        special_care: {
          slug: "special_care",
          label: "Doświadczenie z dziećmi specjalnej troski",
          labelShort: "z dziećmi specjalnej troski",
        },
        twins: {
          slug: "twins",
          label: "Doświadczenie z bliźniakami",
          labelShort: "z bliźniakami",
        },
      },
    },
    education: {
      degrees: {
        university: {
          slug: "university",
          label: "Wyzsze",
        },
        student: {
          slug: "student",
          label: "Student",
        },
        college: {
          slug: "college",
          label: "Zawodowe",
        },
        school: {
          slug: "school",
          label: "Podstawowe",
        },
      },
      types: {
        artistic: {
          slug: "artistic",
          label: "Artystyczne",
          labelStudent: "na studiach <b>artystycznych</b>",
          iconComponent: markRaw(SvgIconsArtistic24),
        },
        medical: {
          slug: "medical",
          label: "Medyczne",
          labelStudent: "na studiach <b>medycznych</b>",
          iconComponent: markRaw(SvgIconsMedical24),
        },
        musical: {
          slug: "musical",
          label: "Muzyczne",
          labelStudent: "na studiach <b>muzycznych</b>",
          iconComponent: markRaw(SvgIconsMusical24),
        },
        teaching: {
          slug: "teaching",
          label: "Pedagogiczne",
          labelStudent: "na studiach <b>pedagogicznych</b>",
          iconComponent: markRaw(SvgIconsTeaching24),
        },
        psychological: {
          slug: "psychological",
          label: "Psychologiczne",
          labelStudent: "na studiach <b>psychologicznych</b>",
          iconComponent: markRaw(SvgIconsPsychological24),
        },
      },
      courses: {
        montessori: {
          slug: "montessori",
          label: "Montessori",
        },
        first_aid: {
          slug: "first_aid",
          label: "pierwszej pomocy",
        },
        child_care: {
          slug: "child_care",
          label: "opieki dzieckiem",
        },
      },
    },
  };

  return { DICTIONARY };
}
