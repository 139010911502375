<template>
  <router-link
    @click.stop="handleClick"
    :to="to === null || disabled ? { params: { stop: true } } : to"
    class="router-link"
    :class="{ 'router-link-disabled': disabled }"
  >
    <button class="button" :class="{ 'button-disabled': disabled }">
      <slot>{{ label }}</slot>
    </button>
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: [String, Object],
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["click"],

  setup(props, { emit }) {
    const handleClick = (event) => {
      if (props.disabled) {
        return;
      }
      emit("click", event);
    };

    return {
      handleClick,
    };
  },
};
</script>

<style scoped>
.router-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.button-disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.8;
}
.router-link-disabled {
  pointer-events: none;
  cursor: default;
}
</style>
