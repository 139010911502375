import { getOfferNanny, updateOfferNanny } from "@/api/offerNanny";
import {
  CREATE_OFFER_NANNY,
  UPLOAD_OFFER_NANNY_PHOTO_MAIN,
} from "@/graphql/mutations/offerNanny";
import { apolloClient } from "@/plugins/apollo";
import { defineStore, storeToRefs } from "pinia";
import { watch } from "vue";
import { useUserStore } from "./user";
import { useUserProfileStore } from "./userProfile";

export const OFFER_NANNY_STORE_NAME = "OFFER_NANNY_STORE";

export const useOfferNannyStore = defineStore(OFFER_NANNY_STORE_NAME, {
  state: () => ({
    _id: null,
    user_id: null,
    user_profile_id: null,
    // user_profile_id_populated: {
    //   _id: "",
    //   user_id: "",
    //   nameFirst: "",
    //   nameLast: "",
    //   nameBusiness: null,
    //   zip: "",
    //   sex: null,
    //   dob: null,
    //   phone: null,
    //   lastSeen: null, // Date
    // },
    response_ids: [],
    // response_ids_populated: [],
    // outgoing_response_ids_populated: [],
    meta: {
      visitor_user_ids: [],
      visitsAll: 0,
      visitsUnique: 0,
      clicksCtaUnique: 0,
      status: "draft", // paused, published, archived, draft
      // createdAt: null, // Date
      refreshedAt: () => Date.now(),
    },
    details: {
      title: "",
      description: "",
      location: {
        city: "",
        cityMarket: "",
        address: null,
        point: {
          type: "Point",
          coordinates: [],
        },
      },
      rate: {
        hour: 0,
        month: null,
      },
      photoMain: {
        src: "",
        file: null, // local field
        originalSrc: "",
        originalFile: null, // local field
        originalFilename: "",
        originalMimetype: "",
        alt: "",
      },
      // isPhotoMainUpdated: false, // local field
      phone: null,
      phoneProven: null,
      aupair: "",
      kids: [],
      isSpecialCare: false,
    },
    employment: {
      type: "",
      regularity: "",
    },
    schedule: {
      value: [],
      comment: null,
      dateStart: "", // Date
      period: {
        term: "",
        measure: null,
        amount: 3, // Default value; Could be null.
      },
    },
    requirements: {
      languages: [],
      responsibilities: {
        careChild: [],
        careHome: [],
        activities: [],
      },
      perks: [],
    },
    storeMeta: {
      isInitializing: false,
      isInitialized: false,
      isLoading: false,
      isUpdating: false,
      error: null,
      watcherStopHandlers: [],
    },
  }),

  getters: {},

  actions: {
    async reset() {
      console.log("RESET_OFFER_NANNY_STORE");

      this.storeMeta.watcherStopHandlers.forEach((stopWatch) => stopWatch());

      this.$reset();
    },

    async createOffer() {
      this.storeMeta.isLoading = true;

      const user = useUserStore();
      const userId = user._id;

      if (userId === null) {
        this.storeMeta.isLoading = false;
        throw new Error(
          "User misses '_id' property. Probably user was not created!"
        );
      }

      this.user_id = userId;

      const userProfile = useUserProfileStore();
      const userProfileId = userProfile._id;

      if (userProfileId === null) {
        this.storeMeta.isLoading = false;
        throw new Error(
          "UserProfile misses '_id' property. Probably user profile was not created!"
        );
      }

      this.user_profile_id = userProfileId;

      const { data } = await apolloClient.mutate({
        mutation: CREATE_OFFER_NANNY,
        variables: {
          payload: {
            user_id: this.user_id,
            user_profile_id: this.user_profile_id,
            meta: {
              status: this.meta.status,
              createdAt: () => Date.now(),
              refreshedAt: this.meta.refreshedAt,
            },
            details: {
              title: this.details.title,
              description: this.details.description,
              location: {
                city: this.details.location.city,
                cityMarket: this.details.location.cityMarket,
                address: this.details.location.address,
                point: {
                  type: this.details.location.point.type,
                  coordinates: this.details.location.point.coordinates,
                },
              },
              rate: {
                hour: this.details.rate.hour,
                month: this.details.rate.month,
              },
              phone: this.details.phone,
              aupair: this.details.aupair,
              kids: this.details.kids,
              isSpecialCare: this.details.isSpecialCare,
            },
            employment: {
              type: this.employment.type,
              regularity: this.employment.regularity,
            },
            schedule: {
              value: this.schedule.value,
              comment: this.schedule.comment,
              dateStart: this.schedule.dateStart,
              period: {
                term: this.schedule.period.term,
                measure: this.schedule.period.measure,
                amount: this.schedule.period.amount,
              },
            },
            requirements: {
              languages: this.requirements.languages,
              responsibilities: {
                careChild: this.requirements.responsibilities.careChild,
                careHome: this.requirements.responsibilities.careHome,
                activities: this.requirements.responsibilities.activities,
              },
              perks: this.requirements.perks,
            },
          },
        },
      });

      if (data === null) {
        this.storeMeta.isLoading = false;
        throw new Error("Response 'Data' is empty!");
      }

      if ("createOfferNanny" in data === false) {
        this.storeMeta.isLoading = false;
        throw new Error(
          "Response 'Data' misesses 'createOfferNanny' property!"
        );
      }

      if ("_id" in data.createOfferNanny === false) {
        this.storeMeta.isLoading = false;
        throw new Error("'Data.createOfferNanny' misesses '_id' property!");
      }

      const newOfferId = data.createOfferNanny._id;
      console.log("createOfferNanny ID", newOfferId);

      if (newOfferId === null) {
        this.storeMeta.isLoading = false;
        throw new Error("Returned OfferNanny '_id' is null!");
      }

      this._id = newOfferId;

      user.offers.nanny_ids.push(newOfferId);
      this.storeMeta.isLoading = false;
      return newOfferId;
    },

    async updatePhotoMain() {
      try {
        this.storeMeta.isLoading = true;

        const offer_id = this._id;

        if (offer_id === null) {
          throw new Error("Offer id is null!");
        }

        const user_id = this.user_id;

        if (user_id === null) {
          throw new Error("User id is null!");
        }

        const croppedFile = this.details.photoMain.file;
        const originalFile = this.details.photoMain.originalFile;

        if (!croppedFile && !originalFile) {
          throw new Error("No files provided!");
        }

        const { data, errors } = await apolloClient.mutate({
          mutation: UPLOAD_OFFER_NANNY_PHOTO_MAIN,
          variables: {
            offerId: offer_id,
            userId: user_id,
            fileOriginal: originalFile,
            fileCropped: croppedFile,
          },
        });

        if (errors) {
          console.log(errors);
          throw new Error("Graphql errors");
        }

        if (!data.uploadOfferNannyPhotoMain) {
          throw new Error(
            `Cannot upload files, returned '${data.uploadOfferNannyPhotoMain}, while no errors from server side... Magic!'`
          );
        }

        return "OK";
      } finally {
        this.storeMeta.isLoading = false;
      }
    },

    async initOfferDraft() {
      console.log("INIT_OFFER_NANNY_DRAFT");

      // Reset current state
      this.reset();

      const user = useUserStore();
      const userProfile = useUserProfileStore();

      const initDraft = () => {
        const user_id = user._id;
        const user_profile_id = userProfile._id;
        const locationCityMarket = user.websiteSettings.city;

        if (!user_id) {
          throw new Error("OfferNanny_id is missed!");
        }

        if (!user_profile_id) {
          throw new Error("OfferNanny_profile_id is missed!");
        }

        if (!locationCityMarket) {
          throw new Error("LocationCity is missed!");
        }

        this.user_id = user_id;
        this.user_profile_id = user_profile_id;
        this.details.location.cityMarket = locationCityMarket;

        this.storeMeta.isInitialized = true;
      };

      const waitForPiniaInit = () => {
        const { _id: user_id } = storeToRefs(user);
        const { _id: user_profile_id } = storeToRefs(userProfile);

        let isOfferDraftInitialized = false;
        // Wait for user and userProfile stores to load
        return watch(
          [user_id, user_profile_id],
          ([userId, profileId]) => {
            if (isOfferDraftInitialized === true) return;

            if (userId && profileId) {
              isOfferDraftInitialized = true;
              initDraft();
            }
          },
          { immediate: true }
        );
      };

      const stopHandler = waitForPiniaInit();

      this.storeMeta.watcherStopHandlers.push(stopHandler);
    },

    setDataToOfferNannyStore(offerNanny) {
      this._id = offerNanny._id;
      this.user_id = offerNanny.user_id;
      this.user_profile_id = offerNanny.user_profile_id;
      this.response_ids = offerNanny.response_ids;
      this.meta.visitor_user_ids = offerNanny.meta.visitor_user_ids;
      this.meta.visitsAll = offerNanny.meta.visitsAll;
      this.meta.visitsUnique = offerNanny.meta.visitsUnique;
      this.meta.clicksCtaUnique = offerNanny.meta.clicksCtaUnique;
      this.meta.status = offerNanny.meta.status;
      this.meta.createdAt = new Date(offerNanny.meta.createdAt);
      this.meta.refreshedAt = new Date(offerNanny.meta.refreshedAt);
      this.details.title = offerNanny.details.title;
      this.details.description = offerNanny.details.description;
      this.details.location.city = offerNanny.details.location.city;
      this.details.location.cityMarket = offerNanny.details.location.cityMarket;
      this.details.location.point.type = offerNanny.details.location.point.type;
      this.details.location.point.coordinates =
        offerNanny.details.location.point.coordinates;
      this.details.rate.hour = offerNanny.details.rate.hour;
      this.details.rate.month = offerNanny.details.rate.month;

      if (offerNanny.details.photoMain === null) {
        this.details.photoMain.src = "";
        this.details.photoMain.originalSrc = "";
        this.details.photoMain.originalFilename = "";
        this.details.photoMain.originalMimetype = "";
        this.details.photoMain.alt = "";
      } else {
        this.details.photoMain.src = offerNanny.details.photoMain.src;
        this.details.photoMain.originalSrc =
          offerNanny.details.photoMain.originalSrc;
        this.details.photoMain.originalFilename =
          offerNanny.details.photoMain.originalFilename;
        this.details.photoMain.originalMimetype =
          offerNanny.details.photoMain.originalMimetype;
        this.details.photoMain.alt = offerNanny.details.photoMain.alt;
      }

      this.details.phone = offerNanny.details.phone;
      this.details.phoneProven = offerNanny.details.phone;
      this.details.aupair = offerNanny.details.aupair;

      if (offerNanny.details.kids && offerNanny.details.kids.length > 0) {
        this.details.kids = offerNanny.details.kids.map((kid) => ({
          id: kid.id,
          sex: kid.sex,
          age: kid.age,
        }));
      } else {
        this.details.kids = [];
      }

      this.details.isSpecialCare = offerNanny.details.isSpecialCare;
      this.employment.type = offerNanny.employment.type;
      this.employment.regularity = offerNanny.employment.regularity;
      this.schedule.value = offerNanny.schedule.value;
      this.schedule.comment = offerNanny.schedule.comment;
      this.schedule.dateStart = new Date(offerNanny.schedule.dateStart);
      this.schedule.period.term = offerNanny.schedule.period.term;
      this.schedule.period.measure = offerNanny.schedule.period.measure;
      this.schedule.period.amount = offerNanny.schedule.period.amount;
      this.requirements.languages = offerNanny.requirements.languages;
      this.requirements.responsibilities.careChild =
        offerNanny.requirements.responsibilities.careChild;
      this.requirements.responsibilities.careHome =
        offerNanny.requirements.responsibilities.careHome;
      this.requirements.responsibilities.activities =
        offerNanny.requirements.responsibilities.activities;
      this.requirements.perks = offerNanny.requirements.perks;
    },

    async initOffer(offer_id) {
      try {
        if (this.storeMeta.isInitializing === true) {
          console.warn("OfferNanny is already initializing!");
          return;
        }

        if (this.storeMeta.isInitialized === true) {
          throw new Error("OfferNanny is already initialized!");
        }

        if (!offer_id) {
          throw new Error(
            `Provide a correct offer_id. Received '${offer_id}'!`
          );
        }

        this.storeMeta.isInitializing = true;
        this.storeMeta.isLoading = true;

        const data = await getOfferNanny({
          offer_id,
        });

        this.setDataToOfferNannyStore(data);

        this.storeMeta.isInitialized = true;

        return "OK";
      } finally {
        this.storeMeta.isInitializing = false;
        this.storeMeta.isLoading = false;
      }
    },

    async updateOffer() {
      try {
        this.storeMeta.isUpdating = true;

        const offer_id = this._id;

        if (this.storeMeta.isInitialized === false) {
          throw new Error(`Init offerNanny first!`);
        }

        if (offer_id === null) {
          throw new Error(
            `Recieved 'null' instead of 'offer_id'! BTW, store was already initialized...`
          );
        }

        const { data } = await updateOfferNanny({
          offer_id,
          offerNannyStore: this,
        });

        if (data === null) {
          throw new Error("Response 'Data' is 'null'!");
        }

        if ("updateOfferNanny" in data === false) {
          throw new Error(
            "Response 'Data' misesses 'updateOfferNanny' property!"
          );
        }

        if ("_id" in data.updateOfferNanny === false) {
          throw new Error("'Data.updateOfferNanny' misesses '_id' property!");
        }
      } finally {
        this.storeMeta.isUpdating = false;
      }
    },
  },
});
