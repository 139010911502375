<template>
  <input
    v-model="result"
    type="checkbox"
    :id="id"
    :name="name"
    :value="value"
    @change="$emit('update:modelValue', result)"
    :checked="checked"
  />
</template>

<script>
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
export default {
  props: {
    id: {
      type: String,
      required: false,
    },
    modelValue: {
      type: [String, Boolean],
    },
    value: {
      type: [String, Boolean],
      default: "",
    },
    name: {
      required: true,
      type: String,
    },
    checked: {
      type: Boolean,
      default: null,
    },
  },

  emits: ["update:modelValue"],

  setup(props) {
    const result = ref(props.modelValue);

    watch(
      () => props.modelValue,
      () => {
        result.value = props.modelValue;
      }
    );

    return {
      result,
    };
  },
};
</script>

<style scoped></style>
