import { GET_OFFER_NANNY_RESPONSES } from "@/graphql/queries/offerNannyResponse";
import { apolloClient } from "@/plugins/apollo";

const responsesApi = {
  async fetchOutgoingOfferNannyResponses(fromApplicationNannyId) {
    if (!fromApplicationNannyId) {
      throw new Error("No fromApplicationNannyId provided!");
    }

    const { data } = await apolloClient.query({
      query: GET_OFFER_NANNY_RESPONSES,
      variables: {
        filters: {
          from_application_nanny_id: fromApplicationNannyId,
        },
        populate: {
          path: "on_offer_nanny_id_populated",
          populate: {
            path: "user_profile_id_populated",
          },
        },
      },
      fetchPolicy: "network-only",
    });

    return data.offerNannyResponses;
  },

  async fetchIncomingApplicationNannyResponses(responseIds) {
    if (responseIds.length === 0) {
      return [];
    }

    const { data } = await apolloClient.query({
      query: GET_OFFER_NANNY_RESPONSES,
      variables: {
        ids: responseIds,
        populate: {
          path: "from_application_nanny_id_populated",
          populate: {
            path: "user_profile_id_populated",
          },
        },
      },
      fetchPolicy: "network-only",
    });

    return data.offerNannyResponses;
  },
};

export default responsesApi;
