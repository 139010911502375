<template>
  <label class="checkbox">
    <InputCheckbox
      class="dot checkbox__dot"
      :name="name"
      @update:model-value="$emit('update:modelValue', $event)"
      :model-value="modelValue"
      :value="value"
      :checked="checked"
    />
    <span :class="['button checkbox__button', sizeClass]">
      {{ label }}
    </span>
  </label>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    modelValue: {
      type: [String, Boolean],
    },
    value: {
      type: [String, Boolean],
      default: "",
    },
    label: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    checked: {
      type: Boolean,
      default: null,
    },
    size: {
      type: String,
      default: "medium",
    },
  },

  emits: ["update:modelValue"],

  setup(props) {
    const sizeClass = computed(() => {
      const { size } = props;
      if (size === "small") return "checkbox__button--small";
      if (size === "x-small") return "checkbox__button--x-small";

      return "";
    });

    return {
      sizeClass,
    };
  },
};
</script>

<style scoped>
.checkbox {
  display: flex;
}

.checkbox__button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--checkbox-button--width);
  height: var(--checkbox-button--height);

  color: var(--checkbox--font-color);
  font-family: var(--default-font-family);
  font-weight: var(--checkbox-button--font-weight);

  background-color: var(--checkbox--bg-color);
  border: var(--checkbox--border);
  border-radius: var(--checkbox-button--border-radius);

  cursor: pointer;
}

.checkbox__button:hover {
  border: var(--checkbox-hover--border);
}

.checkbox__dot--hide {
  display: none;
}

.checkbox__dot:checked + .checkbox__button {
  border: var(--checkbox-active--border);
  background-color: var(--checkbox-active--bg-color);
}

.checkbox__button--small {
  height: var(--button-small-height);
  min-width: var(--button-width);
  font-size: var(--button-small-font-size);
}

.checkbox__button--x-small {
  height: var(--button-x-small-height);
  min-width: var(--button-width);
  font-size: var(--button-x-small-font-size);
}
</style>
