import { prepareApplicationNannyInstance } from "./prepareApplicationNannyInstance";
import { prepareOfferNannyInstance } from "./prepareOfferNannyInstance";

export const prepareApplicationNannyResponseInstance = (responseRaw) => {
  return {
    _id: responseRaw._id,
    get id() {
      return this._id;
    },
    on_application_nanny_id: responseRaw.on_application_nanny_id,
    on_application_nanny_id_populated:
      responseRaw.on_application_nanny_id_populated
        ? prepareApplicationNannyInstance(
            responseRaw.on_application_nanny_id_populated
          )
        : null,
    from_offer_nanny_id: responseRaw.from_offer_nanny_id,
    from_offer_nanny_id_populated: responseRaw.from_offer_nanny_id_populated
      ? prepareOfferNannyInstance(responseRaw.from_offer_nanny_id_populated)
      : null,
    message: responseRaw.message,
  };
};
