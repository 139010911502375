import { getCircularReplacer } from "@/helpers";
import { useUserStore } from "@/stores/user";
import { useUserProfileStore } from "@/stores/userProfile";
import { useUserWishlistStore } from "@/stores/userWishlist";

const PINIA_STATE_LS_KEY = "PINIA_LOCAL_STATE";
const PINIA_STATE_LS_VERSION_KEY = "PINIA_LOCAL_STATE_VERSION";
const CURRENT_PINIA_STATE_LS_VERSION = process.env.APP_VERSION;

const piniaService = {
  setStateToLS(state) {
    // Persist the whole state to the local storage whenever it changes
    localStorage.setItem(
      PINIA_STATE_LS_KEY,
      JSON.stringify(state, getCircularReplacer())
    );
    localStorage.setItem(
      PINIA_STATE_LS_VERSION_KEY,
      CURRENT_PINIA_STATE_LS_VERSION
    );
  },

  getStateFromLS() {
    return localStorage.getItem(PINIA_STATE_LS_KEY);
  },

  removeStateFromLS() {
    console.log("REMOVE_PINIA_STATE_FROM_LS");
    localStorage.removeItem(PINIA_STATE_LS_KEY);
    localStorage.removeItem(PINIA_STATE_LS_VERSION_KEY);
  },

  checkStateVersion() {
    console.log("CHECK_PINIA_STATE_VERSION");

    const piniaStateVersion = localStorage.getItem(PINIA_STATE_LS_VERSION_KEY);
    console.log("SAVED_PINIA_STATE_VERSION: ", piniaStateVersion);
    console.log(
      "CURRENT_PINIA_STATE_VERSION: ",
      CURRENT_PINIA_STATE_LS_VERSION
    );

    return piniaStateVersion === CURRENT_PINIA_STATE_LS_VERSION;
  },

  async initUserStores(user_id, user_profile_id, user_wishlist_id) {
    const userInitPromise = useUserStore().initUser(user_id);
    const userProfileInitPromise =
      useUserProfileStore().initUserProfile(user_profile_id);
    const userWishlistInitPromise =
      useUserWishlistStore().initUserWishlist(user_wishlist_id);

    return Promise.all([
      userInitPromise,
      userProfileInitPromise,
      userWishlistInitPromise,
    ]);
  },

  resetUserStores() {
    const userStores = [
      useUserStore(),
      useUserProfileStore(),
      useUserWishlistStore(),
    ];
    userStores.forEach((store) => store.resetStore());
  },
};

export default piniaService;
