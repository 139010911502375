<template>
  <div class="radio-button-container radio-button-container--horizontal">
    <InputRadio
      v-for="option in options"
      :key="option.value ?? option.slug"
      :model-value="modelValue"
      @update:model-value="$emit('update:modelValue', $event)"
      :name="name"
      :value="option.value ?? option.slug"
      :label="option.label"
      :class="buttonClass"
    />
  </div>
</template>

<script>
import { computed } from "vue";

import InputRadio from "./InputRadio.vue";

export default {
  components: { InputRadio },

  props: {
    modelValue: [String, Number, Array, Boolean],
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    widthSmall: {
      type: Boolean,
      default: false,
    },
    heightSmall: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update:modelValue"],

  setup(props) {
    const buttonClass = computed(() => {
      return {
        "radio-button--height-small": props.heightSmall,
        "radio-button--width-small": props.widthSmall,
        "radio-button--dot-hide": true,
      };
    });
    return {
      buttonClass,
    };
  },
};
</script>

<style scoped>
.radio-button-container {
  display: flex;
  flex-flow: column;
  row-gap: 30px;
}
.radio-button-container--horizontal {
  flex-flow: row;
  column-gap: var(--radio-button-horizontal--column-gap);
}
</style>
