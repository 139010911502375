import SvgIconsDrivingLicense24 from "@/components/Svg/Icons/SvgIconsDrivingLicense24";
import SvgIconsNonSmoking24 from "@/components/Svg/Icons/SvgIconsNonSmoking24";
import SvgIconsOwnCar24 from "@/components/Svg/Icons/SvgIconsOwnCar24";
import SvgIconsReadyToTravel24 from "@/components/Svg/Icons/SvgIconsReadyToTravel24";
import SvgIconsSpecialCare24 from "@/components/Svg/Icons/SvgIconsSpecialCare24";

import SvgIconsGirl24 from "@/components/Svg/Icons/SvgIconsGirl24";
import SvgIconsBoy24 from "@/components/Svg/Icons/SvgIconsBoy24";

import { markRaw } from "vue";

export default function useOfferNannyDictionary() {
  const DICTIONARY = {
    listItemsTypes: {
      offer: {
        slug: "offer",
        label: "Ogłoszenia Rodziców",
      },
      application: {
        slug: "application",
        label: "Profile Opiekunek",
      },
    },
    aupair: {
      not_in_aupair: {
        slug: "not_in_aupair",
        label: "Bez zamieszkania",
      },
      in_aupair: {
        slug: "in_aupair",
        label: "Z zamieszkaniem",
      },
    },
    activeness: {
      actual: {
        slug: "actual",
        label: "aktualna ankieta",
      },
      outdated: {
        slug: "outdated",
        label: "OUTDATED",
      },
    },
    with_photo_only: {
      slug: "with_photo_only",
      label: "Tylko ze zdjęciem",
    },
    languages: {
      en: {
        iconSrc: require("@/assets/images/icons/languages/en.png"),
        label: "English",
        slug: "en",
      },
      it: {
        // iconSrc: require("@/assets/images/icons/languages/it.png"),
        label: "Italiano",
        slug: "it",
      },
      fr: {
        // iconSrc: require("@/assets/images/icons/languages/fr.png"),
        label: "Français",
        slug: "fr",
      },
      de: {
        // iconSrc: require("@/assets/images/icons/languages/de.png"),
        label: "Deutsch",
        slug: "de",
      },
      es: {
        iconSrc: require("@/assets/images/icons/languages/es.png"),
        label: "Español",
        slug: "es",
      },
      ru: {
        iconSrc: require("@/assets/images/icons/languages/ru.png"),
        label: "Русский",
        slug: "ru",
      },
      pl: {
        iconSrc: require("@/assets/images/icons/languages/pl.png"),
        label: "Polski",
        slug: "pl",
      },
      ch: {
        // iconSrc: require("@/assets/images/icons/languages/ch.png"),
        label: "漢語",
        slug: "ch",
      },
      ua: {
        // iconSrc: require("@/assets/images/icons/languages/ua.png"),
        label: "Українська",
        slug: "ua",
      },
    },
    kids: {
      sex: {
        boy: {
          slug: "boy",
          iconComponent: markRaw(SvgIconsBoy24),
        },
        girl: {
          slug: "girl",
          iconComponent: markRaw(SvgIconsGirl24),
        },
      },
    },
    employment: {
      types: {
        full_time: {
          slug: "full_time",
          label: "Pełen etat",
          labelShort: "Pełen",
        },
        part_time: {
          slug: "part_time",
          label: "Część etatu",
          labelShort: "Część",
        },
        baby_sitting: {
          slug: "baby_sitting",
          label: "Jednorazowo",
          labelShort: "Jednorazowo",
        },
      },
      regularities: {
        occasionally: {
          slug: "occasionally",
          label: "Dorywczo",
        },
        regularly: {
          slug: "regularly",
          label: "Regularnie",
        },
      },
    },
    perks: {
      non_smoking: {
        slug: "non_smoking",
        label: "niepaląca niania",
        labelShort: "Niepaląca",
        iconComponent: markRaw(SvgIconsNonSmoking24),
      },
      driver_license: {
        slug: "driver_license",
        label: "wymagane prawo jazdy",
        labelShort: "Prawo jazdy",
        iconComponent: markRaw(SvgIconsDrivingLicense24),
      },
      own_car: {
        slug: "own_car",
        label: "wymagane własny samochód",
        labelShort: "Własny samochód",
        iconComponent: markRaw(SvgIconsOwnCar24),
      },
      ready_to_travel: {
        slug: "ready_to_travel",
        label: "niania gotowa do podroży",
        labelShort: "Gotowa do podroży",
        iconComponent: markRaw(SvgIconsReadyToTravel24),
      },
    },
    special_care: {
      slug: "special_care",
      label: "Doświadczenie w opiece nad dziećmi specjalnej troski",
      labelShort: "Potrzebna specjalna troska",
      iconComponent: markRaw(SvgIconsSpecialCare24),
    },
    get advantages() {
      return {
        ...this.perks,
        special_care: this.special_care,
      };
    },
    responsibilities: {
      childCare: {
        hygiene: {
          label: "higiena",
          slug: "hygiene",
        },
        feeding: {
          label: "karmenie",
          slug: "feeding",
        },
        walks: {
          label: "spacery",
          slug: "walks",
        },
        safeguarding: {
          label: "towarzyszenie i zabezpieczenie",
          slug: "safeguarding",
        },
      },
      homeCare: {
        laundry: {
          label: "Pranie",
          slug: "laundry",
          iconSrc: require("@/assets/images/icons/home-care/basket_1f9fa.png"),
        },
        ironing: {
          label: "Prasowanie",
          slug: "ironing",
          iconSrc: require("@/assets/images/icons/home-care/necktie_1f454.png"),
        },
        cleaning: {
          label: "Sprzątanie",
          slug: "cleaning",
          iconSrc: require("@/assets/images/icons/home-care/broom_1f9f9.png"),
        },
        maintaining_cleaniness: {
          label: "Utrzymanie czystości",
          slug: "maintaining_cleaniness",
          iconSrc: require("@/assets/images/icons/home-care/sponge_1f9fd.png"),
        },
        cooking: {
          label: "Gotowanie",
          slug: "cooking",
          iconSrc: require("@/assets/images/icons/home-care/cooking_1f373.png"),
        },
        pet_care: {
          label: "Opieka nad zwierzętami",
          slug: "pet_care",
          iconSrc: require("@/assets/images/icons/home-care/paw-prints_1f43e.png"),
        },
        plant_care: {
          label: "Pielęgnacja roślin",
          slug: "plant_care",
          iconSrc: require("@/assets/images/icons/home-care/potted-plant_1fab4.png"),
        },
        shopping: {
          label: "Zakupy",
          slug: "shopping",
          iconSrc: require("@/assets/images/icons/home-care/shopping-cart_1f6d2.png"),
        },
      },
      activities: {
        education: {
          slug: "education",
          label: "Zajęcia edukacją",
          labelShort: "edukacją",
        },
        creativity: {
          slug: "creativity",
          label: "Zajęcia kreatywnośćą",
          labelShort: "kreatywnośćą",
        },
        music: {
          slug: "music",
          label: "Zajęcia muzyką",
          labelShort: "muzyką",
        },
        games: {
          slug: "games",
          label: "Zajęcia grami i zabawami",
          labelShort: "grami i zabawami",
        },
        homework: {
          slug: "homework",
          label: "Zajęcia pomocą w odrabianiu lekcji",
          labelShort: "pomocą w odrabianiu lekcji",
        },
      },
    },
    period: {
      term: {
        long_term: {
          slug: "long_term",
          label: "długi",
        },
        custom: {
          slug: "custom",
          label: "Określić",
        },
      },
      measureLong: {
        weeks: {
          slug: "weeks",
          label: "Tygodnie",
        },
        months: {
          slug: "months",
          label: "Miesięcy",
        },
      },
      measureShort: {
        hours: {
          slug: "hours",
          label: "Godziny",
        },
        days: {
          slug: "days",
          label: "Dni",
        },
      },
      get measure() {
        let measureLong = this.measureLong;
        let measureShort = this.measureShort;
        return { ...measureLong, ...measureShort };
      },
    },
  };

  return { DICTIONARY };
}
