import gql from "graphql-tag";

export const GET_USER_WISHLIST = gql`
  query UserWishlist($id: ID!) {
    userWishlist(_id: $id) {
      _id
      user_id
      applications {
        nanny_ids
      }
      offers {
        nanny_ids
      }
    }
  }
`;
