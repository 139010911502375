<template>
  <div
    class="text-input"
    :class="[$attrs.class, containerClass]"
    :disabled="disabled"
  >
    <textarea
      v-if="textarea"
      ref="textareaRef"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      :type="type"
      :name="name"
      :id="name"
      class="field text-input__field"
      :placeholder="placeholder"
      :required="required"
      :rows="rows"
      :minlength="minlength"
      :maxlength="maxlength"
      :autocomplete="autocomplete"
    ></textarea>
    <input
      v-else
      v-imask="mask"
      :value="mask && !resultIsMasked ? maskedValue : modelValue"
      @accept="if (mask) onAccept($event);"
      @input="if (!mask) $emit('update:modelValue', $event.target.value);"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      :type="type"
      :name="name"
      :id="name"
      class="field text-input__field"
      :class="inputClass"
      :placeholder="placeholder"
      :pattern="pattern"
      :rows="rows"
      :minlength="minlength"
      :maxlength="maxlength"
      :autocomplete="autocomplete"
      :spellcheck="spellcheck"
      :required="required"
    />
    <slot name="after-input"></slot>

    <div
      v-if="textarea && maxlength"
      class="word-counter text-input__word-counter"
    >
      {{ modelValue?.length ?? "0" }}/{{ maxlength }}
    </div>

    <label v-if="label" :for="name" class="label text-input__label">
      <span v-if="asterisk" class="asterisk label__asterisk">*</span>
      {{ label }}
    </label>
    <transition>
      <div v-if="$slots.caption && caption" class="caption text-input__caption">
        <slot name="caption"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { computed, ref, onMounted } from "vue";
import { IMaskDirective } from "vue-imask";

export default {
  directives: {
    imask: IMaskDirective,
  },
  props: {
    mask: {
      type: [Object, Boolean],
      default: false,
    },
    resultIsMasked: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    caption: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    pattern: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: null,
    },
    rows: {
      type: [String, Number],
      default: null,
    },
    maxlength: {
      type: [String, Number],
      default: null,
    },
    minlength: {
      type: [String, Number],
      default: null,
    },
    autocomplete: {
      type: String,
      default: "on",
    },
    spellcheck: {
      type: Boolean,
      default: null,
    },
    asterisk: {
      type: Boolean,
      default: false,
    },
    geo: {
      type: Boolean,
      default: false,
    },
    isSuggestions: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const maskedValue = ref("");
    const onAccept = (event) => {
      maskedValue.value = event.detail.value;
      if (props.resultIsMasked) {
        emit("update:modelValue", event.detail.value);
      } else {
        emit("update:modelValue", event.detail.unmaskedValue);
      }
    };
    const textareaRef = ref(null);
    const initialInputType = Object.assign({}, { type: props.type }).type;

    const isPassword = computed(() => initialInputType === "password");
    const inputClass = computed(() => {
      return {
        "text-input__field--password": isPassword.value,
      };
    });
    const containerClass = computed(() => {
      return {
        "text-input--small": props.small,
        "text-input--long": props.textarea,
        "text-input--geo": props.geo,
        "text-input--suggestions": props.isSuggestions,
        "text-input--error": props.error,
        "text-input--success": props.success,
      };
    });

    onMounted(() => {
      emit("textareaOnMountedRef", textareaRef);
    });

    return {
      maskedValue,
      onAccept,
      textareaRef,
      isPassword,
      inputClass,
      containerClass,
    };
  },
};
</script>

<style scoped>
.v-enter-active {
  transition: all 0.4s;
  transform: translateZ(0);
}
.v-leave-active {
  transition: all 0.2s;
  transform: translateZ(0);
}
.v-enter-to,
.v-leave-from {
  opacity: 1;
  max-height: 500px;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
  max-height: 0px;
}

/* TEXT INPUT GENERAL  */

.text-input {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: var(--text-input--width);
}

.text-input--small {
  width: var(--text-input__small--width);
}

.text-input__label {
  width: fit-content;
  position: relative;
  order: -1;
  padding: 0 0 7px 10px;

  font: var(--input__label--font);

  cursor: pointer;
}

/* .text-input__label--hide {
  display: none;
} */

.text-input__caption {
  font: var(--text-input__caption--font);
  margin: 7px 10px 0 10px;
}

.label__asterisk {
  display: block;
  position: absolute;
  font-size: 26px;
  font-weight: 400;
  color: #fe881c;
  left: -7px;
  top: -3px;
}

/* .text-input__field:required + .text-input__label .label__asterisk {
  display: block;
} */

.text-input__field {
  height: var(--text-input__field--height);
  padding: 0 var(--text-input__field--padding) 2px
    var(--text-input__field--padding);

  font: var(--text-input__field--font);
  color: var(--text-input__field--font-color);

  border: var(--text-input__field--border);
  border-radius: var(--text-input__field--border-radius);

  transition: border-radius 0.25s;
}

.text-input__field:hover {
  border: var(--text-input__field--hover--border);
  padding: 0 calc(var(--text-input__field--padding) - 1px) 2px
    calc(var(--text-input__field--padding) - 1px);
}

.text-input__field:focus {
  border: var(--text-input__field--focus--border);
  padding: 0 calc(var(--text-input__field--padding) - 1px) 2px
    calc(var(--text-input__field--padding) - 1px);

  background-color: #fff;
}

/* *Success and valid  */
.text-input--success,
.text-input--success .text-input__field {
  border-color: rgba(112, 164, 111, 0.5);
  color: rgba(112, 164, 111, 1);
}

.text-input--success .text-input__field:hover {
  border-color: rgba(112, 164, 111, 0.5);
}
.text-input--success .text-input__field:focus {
  border-color: rgba(112, 164, 111, 0.8);
}

/* .text-input__field:required:valid,
.text-input__field:required:valid ~ .text-input__label,
.text-input__field:required:valid ~ .text-input__caption {
  border-color: rgba(112, 164, 111, 0.5);
  color: rgba(112, 164, 111, 1);
}
.text-input__field:required:valid:hover,
.text-input__field:required:valid:hover ~ .text-input__label,
.text-input__field:required:valid:hover ~ .text-input__caption {
  border-color: rgba(112, 164, 111, 0.5);
}
.text-input__field:required:valid:focus,
.text-input__field:required:valid:focus ~ .text-input__label,
.text-input__field:required:valid:focus ~ .text-input__caption {
  border-color: rgba(112, 164, 111, 0.8);
} */

/* Errors */
.text-input--error,
.text-input--error .text-input__field {
  border-color: rgba(195, 63, 63, 0.5);
  color: rgba(195, 63, 63, 1);
}

.text-input--error .text-input__field:hover {
  border-color: rgba(195, 63, 63, 0.5);
}
.text-input--error .text-input__field:focus {
  border-color: rgba(195, 63, 63, 0.8);
}

/* Disabled style */
.text-input[disabled="true"] {
  pointer-events: none !important;
}
.text-input[disabled="true"] .text-input__field {
  border: unset !important;
  pointer-events: none !important;
  background-color: transparent !important;
  opacity: 0.5 !important;
  font-weight: 300 !important;
}

/* * INPUT PLACEHOLDERS  */

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font: var(--text-input__placeholder--font);
  color: var(--text-input__placeholder--color);
  opacity: var(--text-input__placeholder--opacity);
}
::-moz-placeholder {
  /* Firefox 19+ */
  font: var(--text-input__placeholder--font);
  color: var(--text-input__placeholder--color);
  opacity: var(--text-input__placeholder--opacity);
}
:-ms-input-placeholder {
  /* IE 10+ */
  font: var(--text-input__placeholder--font);
  color: var(--text-input__placeholder--color);
  opacity: var(--text-input__placeholder--opacity);
}
:-moz-placeholder {
  /* Firefox 18- */
  font: var(--text-input__placeholder--font);
  color: var(--text-input__placeholder--color);
  opacity: var(--text-input__placeholder--opacity);
}

/* * INPUT PLACEHOLDERS END */

/* PASSWORD INPUT fix  */

/* todo CHECK IN FireFox! */
.text-input__field[type="password"]:not(:placeholder-shown) {
  font-size: 40px;
  letter-spacing: 1px;
  padding-bottom: 8px;
}

/* PASSWORD INPUT fix END */

/* PHONE INPUT  */
.text-input--tel .text-input__field {
  font-size: 17px;
  padding: 0 5px 2px 12px;
}
.text-input--tel .text-input__field:hover {
  padding: 0 4px 2px 11px;
}

.text-input--tel .text-input__field:focus {
  padding: 0 4px 2px 11px;
}

::-webkit-input-placeholder {
  font-size: 17px;
}
::-moz-placeholder {
  font-size: 17px;
}
:-ms-input-placeholder {
  font-size: 17px;
}
:-moz-placeholder {
  font-size: 17px;
}

/* TEL CODE INPUT  */
.text-input--tel-code .text-input__field {
  padding: 0 20px 2px 41px;
}
.text-input--tel-code .text-input__field:hover {
  padding: 0 19px 2px 40px;
}
.text-input--tel-code .text-input__field:focus {
  padding: 0 19px 2px 40px;
}

/* ZIP INPUT  */
.text-input--zip .text-input__field {
  padding: 0 20px 2px 47px;
}
.text-input--zip .text-input__field:hover {
  padding: 0 19px 2px 46px;
}
.text-input--zip .text-input__field:focus {
  padding: 0 19px 2px 46px;
}

/* INPUT WITH PREFIX & POSTFIX (HOURLY RATE) */
.text-input--prefix {
  display: flex;
  justify-content: center;
}

.text-input--prefix .text-input__field {
  text-align: center;
  padding: 0 calc(80px + 4px + var(--text-input__field--padding)) 2px 13px;
}

.text-input--prefix .text-input__label {
  position: absolute;
  padding: 0;
  left: -42px;

  font: var(--text-input__prefix--font);
}

/* TEXTAREA  */
.text-input--long .text-input__field {
  padding-top: var(--text-input__textarea--padding-top);
  padding-bottom: var(--text-input__textarea--padding-bottom);

  overflow-wrap: break-word;
  overflow-y: hidden;
  resize: none;
  height: unset;
}

.text-input--long .text-input__field:hover {
  padding-top: calc(var(--text-input__textarea--padding-top) - 1px);
  padding-bottom: calc(var(--text-input__textarea--padding-bottom) - 1px);
}

.text-input--long .text-input__field:focus {
  padding-top: calc(var(--text-input__textarea--padding-top) - 1px);
  padding-bottom: calc(var(--text-input__textarea--padding-bottom) - 1px);
}

.text-input__word-counter {
  align-self: flex-end;
  margin-top: 7px;
  font: var(--text-input__word-counter--font);
  opacity: var(--text-input__placeholder--opacity);
}

.text-input--long .text-input__field:hover ~ .text-input__word-counter {
  opacity: 0.65;
}

.text-input--long .text-input__field:focus ~ .text-input__word-counter {
  opacity: 1;
}

/* GEO INPUT  */

.text-input--geo.text-input--suggestions .text-input__field:focus {
  border-radius: var(--text-input__field--border-radius)
    var(--text-input__field--border-radius) 0 0;
}
</style>
