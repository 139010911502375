<template>
  <div class="container-number-spinner">
    <ButtonCircle
      @mousedown.prevent="emitDecrement()"
      :minus="true"
      :small="small"
      :disabled="isDisabledButtonMinus"
    />
    <input
      v-if="isMasked"
      v-imask="maskRef"
      type="text"
      :name="name"
      :value="modelValue"
      @accept="$emit('update:modelValue', Number($event.detail.value))"
      class="input container-number-spinner__input"
    />
    <input
      v-if="!isMasked"
      type="text"
      :name="name"
      :value="modelValue"
      @input="$emit('update:modelValue', Number($event.target.value))"
      class="input container-number-spinner__input container-number-spinner__input--disabled"
    />
    <ButtonCircle
      @mousedown.prevent="emitIncrement()"
      :plus="true"
      :small="small"
      :disabled="isDisabledButtonPlus"
    />
  </div>
</template>

<script>
import { watch } from "@vue/runtime-core";
import { computed } from "vue";

import { IMaskDirective } from "vue-imask";

import useIMaskNumber from "@/composables/imask/useIMaskNumber";
import useInputNumberSpinner from "@/composables/useInputNumberSpinner";

import ButtonCircle from "./ButtonCircle.vue";

export default {
  components: { ButtonCircle },

  directives: {
    imask: IMaskDirective,
  },

  props: {
    modelValue: {
      type: [Number, String],
      default: 1,
    },
    name: {
      type: String,
      required: true,
    },
    isMasked: {
      type: Boolean,
      default: true,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const { maskRef } = useIMaskNumber({ min: props.min, max: props.max });

    // TODO: put it on the higher level... too close relationship!
    // Calculate current value from modelValue
    const currentValue = computed(() => {
      if (props.modelValue === "<1") {
        return 0;
      } else return props.modelValue;
    });

    // Spinner Buttons functionality
    const {
      changedValue,
      isDisabledButtonMinus,
      isDisabledButtonPlus,
      emitIncrement,
      emitDecrement,
    } = useInputNumberSpinner({
      currentValueRef: currentValue,
      max: props.max,
      min: props.min,
    });

    // Update modelValue on new changedValue
    watch(changedValue, (newValue) => {
      emit("update:modelValue", newValue);
    });

    return {
      maskRef,
      isDisabledButtonMinus,
      isDisabledButtonPlus,
      emitIncrement,
      emitDecrement,
    };
  },
};
</script>

<style scoped>
.container-number-spinner {
  width: 225px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.container-number-spinner__input {
  width: 50px;
  text-align: center;

  font: var(--h2--font);
}
.container-number-spinner__input--disabled {
  pointer-events: none;
  cursor: default;
}

@media (max-width: 1200px) {
}
</style>
