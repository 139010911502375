import {
  ROUTE_OFFER_NANNY_CREATE_ADDRESS_NAME,
  ROUTE_OFFER_NANNY_CREATE_ADDRESS_PATH,
  ROUTE_OFFER_NANNY_CREATE_AUPAIR_NAME,
  ROUTE_OFFER_NANNY_CREATE_AUPAIR_PATH,
  ROUTE_OFFER_NANNY_CREATE_DETAILS_NAME,
  ROUTE_OFFER_NANNY_CREATE_DETAILS_PATH,
  ROUTE_OFFER_NANNY_CREATE_EMPLOYMENT_REGULARITY_NAME,
  ROUTE_OFFER_NANNY_CREATE_EMPLOYMENT_REGULARITY_PATH,
  ROUTE_OFFER_NANNY_CREATE_EMPLOYMENT_TYPE_NAME,
  ROUTE_OFFER_NANNY_CREATE_EMPLOYMENT_TYPE_PATH,
  ROUTE_OFFER_NANNY_CREATE_FINISH_NAME,
  ROUTE_OFFER_NANNY_CREATE_FINISH_PATH,
  ROUTE_OFFER_NANNY_CREATE_KIDS_NAME,
  ROUTE_OFFER_NANNY_CREATE_KIDS_PATH,
  ROUTE_OFFER_NANNY_CREATE_NAME,
  ROUTE_OFFER_NANNY_CREATE_PATH,
  ROUTE_OFFER_NANNY_CREATE_PERIOD_BABBY_SITTING_NAME,
  ROUTE_OFFER_NANNY_CREATE_PERIOD_BABBY_SITTING_PATH,
  ROUTE_OFFER_NANNY_CREATE_PERKS_NAME,
  ROUTE_OFFER_NANNY_CREATE_PERKS_PATH,
  ROUTE_OFFER_NANNY_CREATE_PHOTO_MAIN_NAME,
  ROUTE_OFFER_NANNY_CREATE_PHOTO_MAIN_PATH,
  ROUTE_OFFER_NANNY_CREATE_RESPONSIBILITIES_NAME,
  ROUTE_OFFER_NANNY_CREATE_RESPONSIBILITIES_PATH,
  ROUTE_OFFER_NANNY_CREATE_SCHEDULE_NAME,
  ROUTE_OFFER_NANNY_CREATE_SCHEDULE_PATH,
} from "@/router/constants";
import { defineAsyncComponent } from "@vue/runtime-core";

const OfferNannyCreate = () =>
  import("@/views/Offer/Nanny/Create/OfferNannyCreate.vue");
const OfferNannyCreateAddress = () =>
  import("@/views/Offer/Nanny/Create/OfferNannyCreateAddress.vue");
const OfferNannyCreateAupair = () =>
  import("@/views/Offer/Nanny/Create/OfferNannyCreateAupair.vue");
const OfferNannyCreateKids = () =>
  import("@/views/Offer/Nanny/Create/OfferNannyCreateKids.vue");
const OfferNannyCreateEmploymentType = () =>
  import("@/views/Offer/Nanny/Create/OfferNannyCreateEmploymentType.vue");
const OfferNannyCreateEmploymentRegularity = () =>
  import("@/views/Offer/Nanny/Create/OfferNannyCreateEmploymentRegularity.vue");
const OfferNannyCreatePeriodBabbySitting = () =>
  import("@/views/Offer/Nanny/Create/OfferNannyCreatePeriodBabbySitting.vue");
const OfferNannyCreateSchedule = () =>
  import("@/views/Offer/Nanny/Create/OfferNannyCreateSchedule.vue");
const OfferNannyCreatePerks = () =>
  import("@/views/Offer/Nanny/Create/OfferNannyCreatePerks.vue");
const OfferNannyCreateResponsibilities = () =>
  import("@/views/Offer/Nanny/Create/OfferNannyCreateResponsibilities.vue");
const OfferNannyCreateDetails = () =>
  import("@/views/Offer/Nanny/Create/OfferNannyCreateDetails.vue");
const OfferNannyCreatePhotoMain = () =>
  import("@/views/Offer/Nanny/Create/OfferNannyCreatePhotoMain.vue");
const OfferNannyCreateFinish = () =>
  import("@/views/Offer/Nanny/Create/OfferNannyCreateFinish.vue");

export default {
  path: ROUTE_OFFER_NANNY_CREATE_PATH,
  name: ROUTE_OFFER_NANNY_CREATE_NAME,
  component: OfferNannyCreate,
  redirect: { name: ROUTE_OFFER_NANNY_CREATE_ADDRESS_NAME },
  meta: {
    layout: defineAsyncComponent({
      loader: () => import(`@/layouts/LayoutCreate.vue`),
      delay: 0,
    }),
    authGuard: true,
  },
  children: [
    {
      path: ROUTE_OFFER_NANNY_CREATE_ADDRESS_PATH,
      name: ROUTE_OFFER_NANNY_CREATE_ADDRESS_NAME,
      component: OfferNannyCreateAddress,
      meta: {
        progress: 0,
      },
    },
    {
      path: ROUTE_OFFER_NANNY_CREATE_AUPAIR_PATH,
      name: ROUTE_OFFER_NANNY_CREATE_AUPAIR_NAME,
      component: OfferNannyCreateAupair,
      meta: {
        progress: 22,
      },
    },
    {
      path: ROUTE_OFFER_NANNY_CREATE_KIDS_PATH,
      name: ROUTE_OFFER_NANNY_CREATE_KIDS_NAME,
      component: OfferNannyCreateKids,
      meta: {
        progress: 31,
      },
    },
    {
      path: ROUTE_OFFER_NANNY_CREATE_EMPLOYMENT_TYPE_PATH,
      name: ROUTE_OFFER_NANNY_CREATE_EMPLOYMENT_TYPE_NAME,
      component: OfferNannyCreateEmploymentType,
      meta: {
        progress: 42,
      },
    },
    {
      path: ROUTE_OFFER_NANNY_CREATE_EMPLOYMENT_REGULARITY_PATH,
      name: ROUTE_OFFER_NANNY_CREATE_EMPLOYMENT_REGULARITY_NAME,
      component: OfferNannyCreateEmploymentRegularity,
      meta: {
        progress: 55,
      },
    },
    {
      path: ROUTE_OFFER_NANNY_CREATE_PERIOD_BABBY_SITTING_PATH,
      name: ROUTE_OFFER_NANNY_CREATE_PERIOD_BABBY_SITTING_NAME,
      component: OfferNannyCreatePeriodBabbySitting,
      meta: {
        progress: 64,
      },
    },
    {
      path: ROUTE_OFFER_NANNY_CREATE_SCHEDULE_PATH,
      name: ROUTE_OFFER_NANNY_CREATE_SCHEDULE_NAME,
      component: OfferNannyCreateSchedule,
      meta: {
        progress: 64,
      },
    },
    {
      path: ROUTE_OFFER_NANNY_CREATE_PERKS_PATH,
      name: ROUTE_OFFER_NANNY_CREATE_PERKS_NAME,
      component: OfferNannyCreatePerks,
      meta: {
        progress: 73,
      },
    },
    {
      path: ROUTE_OFFER_NANNY_CREATE_RESPONSIBILITIES_PATH,
      name: ROUTE_OFFER_NANNY_CREATE_RESPONSIBILITIES_NAME,
      component: OfferNannyCreateResponsibilities,
      meta: {
        progress: 82,
      },
    },
    {
      path: ROUTE_OFFER_NANNY_CREATE_DETAILS_PATH,
      name: ROUTE_OFFER_NANNY_CREATE_DETAILS_NAME,
      component: OfferNannyCreateDetails,
      meta: {
        progress: 92,
      },
    },
    {
      path: ROUTE_OFFER_NANNY_CREATE_PHOTO_MAIN_PATH,
      name: ROUTE_OFFER_NANNY_CREATE_PHOTO_MAIN_NAME,
      component: OfferNannyCreatePhotoMain,
      meta: {
        progress: 97,
      },
    },
    {
      path: ROUTE_OFFER_NANNY_CREATE_FINISH_PATH,
      name: ROUTE_OFFER_NANNY_CREATE_FINISH_NAME,
      component: OfferNannyCreateFinish,
      meta: {
        progress: 100,
      },
    },
  ],
};
