import BaseButton from "@/components/UI/Base/BaseButton";
import ButtonCta from "@/components/UI/Base/ButtonCta";
import ButtonPrimary from "@/components/UI/Base/ButtonPrimary";
import ButtonBasic from "@/components/UI/Base/ButtonBasic";
import ButtonTertiary from "@/components/UI/Base/ButtonTertiary";
import ButtonLink from "@/components/UI/Base/ButtonLink";
import ButtonAttachment from "@/components/UI/Base/ButtonAttachment";
import ButtonCircle from "@/components/UI/Base/ButtonCircle";

import InputRadio from "@/components/UI/Base/InputRadio";
import InputRadioGroupHorizontal from "@/components/UI/Base/InputRadioGroupHorizontal";
import InputRadioGroupVertical from "@/components/UI/Base/InputRadioGroupVertical";
import InputTabGroupSlider from "@/components/UI/Base/InputTabGroupSlider";

import InputCheckbox from "@/components/UI/Base/InputCheckbox";
import InputCheckboxButton from "@/components/UI/Base/InputCheckboxButton";
import InputCheckboxGroupSticked from "@/components/UI/Base/InputCheckboxGroupSticked";
import InputCheckboxGroupChips from "@/components/UI/Base/InputCheckboxGroupChips";
import InputCheckboxGroupRound from "@/components/UI/Base/InputCheckboxGroupRound";
import BaseInputNumberSpinner from "@/components/UI/Base/BaseInputNumberSpinner";

import SelectBlock from "@/components/UI/Base/SelectBlock";

import InputText from "@/components/UI/Base/InputText";
import InputTextTextarea from "@/components/UI/Base/InputTextTextarea";

import BaseH1 from "@/components/UI/Base/BaseH1";
import BaseH2 from "@/components/UI/Base/BaseH2";
import BaseH3 from "@/components/UI/Base/BaseH3";
import BaseText from "@/components/UI/Base/BaseText";

import BaseGroupIconLabelItem from "@/components/UI/Base/BaseGroupIconLabelItem";

import InputTextPassword from "@/components/UI/InputTextPassword";
import InputTextTelephone from "@/components/UI/InputTextTelephone";
import InputTextTelephoneCode from "@/components/UI/InputTextTelephoneCode";
import InputTextDOB from "@/components/UI/InputTextDOB";
import InputTextPostfixPrefix from "@/components/UI/Base/InputTextPostfixPrefix";
import InputTextFeeHour from "@/components/UI/InputTextFeeHour";
import InputTextFeeMonth from "@/components/UI/InputTextFeeMonth";
import InputTextGeo from "@/components/UI/InputTextGeo";

export default [
  {
    name: "BaseButton",
    component: BaseButton,
  },
  {
    name: "ButtonCta",
    component: ButtonCta,
  },
  {
    name: "ButtonPrimary",
    component: ButtonPrimary,
  },
  {
    name: "ButtonBasic",
    component: ButtonBasic,
  },
  {
    name: "ButtonTertiary",
    component: ButtonTertiary,
  },
  {
    name: "ButtonLink",
    component: ButtonLink,
  },
  {
    name: "ButtonAttachment",
    component: ButtonAttachment,
  },
  {
    name: "InputRadio",
    component: InputRadio,
  },
  {
    name: "InputRadioGroupHorizontal",
    component: InputRadioGroupHorizontal,
  },

  {
    name: "InputRadioGroupVertical",
    component: InputRadioGroupVertical,
  },
  {
    name: "InputTabGroupSlider",
    component: InputTabGroupSlider,
  },

  {
    name: "InputCheckbox",
    component: InputCheckbox,
  },
  {
    name: "InputCheckboxButton",
    component: InputCheckboxButton,
  },
  {
    name: "InputCheckboxGroupSticked",
    component: InputCheckboxGroupSticked,
  },
  {
    name: "InputCheckboxGroupChips",
    component: InputCheckboxGroupChips,
  },
  {
    name: "InputCheckboxGroupRound",
    component: InputCheckboxGroupRound,
  },
  {
    name: "BaseInputNumberSpinner",
    component: BaseInputNumberSpinner,
  },
  {
    name: "ButtonCircle",
    component: ButtonCircle,
  },

  {
    name: "InputText",
    component: InputText,
  },
  {
    name: "InputTextTextarea",
    component: InputTextTextarea,
  },
  {
    name: "InputTextPassword",
    component: InputTextPassword,
  },
  {
    name: "InputTextTelephone",
    component: InputTextTelephone,
  },
  {
    name: "InputTextTelephoneCode",
    component: InputTextTelephoneCode,
  },
  {
    name: "InputTextDOB",
    component: InputTextDOB,
  },
  {
    name: "InputTextPostfixPrefix",
    component: InputTextPostfixPrefix,
  },
  {
    name: "InputTextFeeHour",
    component: InputTextFeeHour,
  },
  {
    name: "InputTextFeeMonth",
    component: InputTextFeeMonth,
  },
  {
    name: "InputTextGeo",
    component: InputTextGeo,
  },
  {
    name: "SelectBlock",
    component: SelectBlock,
  },

  {
    name: "BaseH1",
    component: BaseH1,
  },
  {
    name: "BaseH2",
    component: BaseH2,
  },
  {
    name: "BaseH3",
    component: BaseH3,
  },
  {
    name: "BaseText",
    component: BaseText,
  },
  {
    name: "BaseGroupIconLabelItem",
    component: BaseGroupIconLabelItem,
  },
];
