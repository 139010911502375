<template>
  <InputText
    v-model="dob.string"
    @focus="onFocus"
    :type="'text'"
    :name="'DOB'"
    :label="'Data urodzenia'"
    :placeholder="'dd / mm / yyyy'"
    :autocomplete="'off'"
    :spellcheck="false"
    :small="true"
    :disabled="disabled"
    :mask="maskRef"
    class="text-input-parent--dob"
  />
</template>

<script>
import useIMaskDOB from "@/composables/imask/useIMaskDOB";
import useActivateIMaskPattern from "@/composables/imask/useActivateIMaskPattern";

import InputText from "./Base/InputText.vue";
import { reactive, watch } from "vue";
import { dateToString, stringToDate } from "@/helpers";

export default {
  components: { InputText },

  props: {
    dobDate: [Date, null],
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update:dobDate"],

  setup(props, { emit }) {
    const { maskRef } = useIMaskDOB();
    const { activateIMaskPattern } = useActivateIMaskPattern();
    const onFocus = () => {
      activateIMaskPattern(maskRef);
    };

    const dob = reactive({
      string: "",
      date: props.dobDate,
    });

    // Inside
    watch(
      () => dob.string,
      (newDobString) => {
        // Validate new dob string
        if (/d|m|y|_/.test(newDobString) || newDobString.length === 0) return;

        if (dob.date && dateToString(dob.date) === newDobString) return;

        const newDobDate = stringToDate(newDobString);
        dob.date = newDobDate;
      }
    );

    // In
    watch(
      () => props.dobDate,
      (newDobDate) => {
        if (!newDobDate) return;

        dob.string = dateToString(newDobDate);
      },
      { immediate: true }
    );

    // Out
    watch(
      () => dob.date,
      (newDobDate) => {
        if (
          props.dobDate &&
          dateToString(props.dobDate) === dateToString(newDobDate)
        ) {
          return;
        }

        emit("update:dobDate", newDobDate);
      }
    );

    return {
      dob,
      maskRef,
      onFocus,
    };
  },
};
</script>

<style scoped>
@media (max-width: 1200px) {
  .text-input-parent--dob :deep(.text-input__field) {
    padding: 0 15px 2px 24px;
  }
  .text-input-parent--dob :deep(.text-input__field):hover {
    padding: 0 14px 2px 23px;
  }
  .text-input-parent--dob :deep(.text-input__field):focus {
    padding: 0 14px 2px 23px;
  }
}
</style>
