export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        try {
          return JSON.parse(JSON.stringify(value));
        } catch (error) {
          return;
        }
      }
      seen.add(value);
    }
    return value;
  };
};

// export getCircularReplacer = (key, value) {
//     if (typeof value === 'object' && value !== null) {
//       if (cache.has(value)) {
//         // Circular reference found
//         try {
//           // If this value does not reference a parent it can be deduped
//          return JSON.parse(JSON.stringify(value));
//         }
//         catch (err) {
//           // discard key if value cannot be deduped
//          return;
//         }
//       }
//       // Store value in our set
//       cache.add(value);
//     }
//     return value;
//   }
