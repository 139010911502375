import {
  ROUTE_LOGIN_EMAIL_NAME,
  ROUTE_LOGIN_EMAIL_PATH,
  ROUTE_LOGIN_NAME,
  ROUTE_LOGIN_PATH,
  ROUTE_LOGIN_START_NAME,
  ROUTE_LOGIN_START_PATH,
} from "@/router/constants";

const Login = () => import("@/views/Auth/Login/Login.vue");
const LoginStart = () => import("@/views/Auth/Login/LoginStart.vue");
const LoginEmail = () => import("@/views/Auth/Login/LoginEmail.vue");

const loginRoute = {
  path: ROUTE_LOGIN_PATH,
  name: ROUTE_LOGIN_NAME,
  component: Login,
  redirect: { name: ROUTE_LOGIN_START_NAME },
  children: [
    {
      path: ROUTE_LOGIN_START_PATH,
      name: ROUTE_LOGIN_START_NAME,
      component: LoginStart,
    },
    {
      path: ROUTE_LOGIN_EMAIL_PATH,
      name: ROUTE_LOGIN_EMAIL_NAME,
      component: LoginEmail,
    },
  ],
};

export default loginRoute;
