<template>
  <slot v-if="isAuthGuardPassed" />
</template>

<script>
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { ROUTE_LOGIN_NAME } from "@/router/constants";
import { storeToRefs } from "pinia";
import useEmailVerificationGuard from "@/composables/useEmailVerificationGuard";

export default {
  setup() {
    useEmailVerificationGuard();

    const isAuthGuardPassed = ref(false);
    const userStore = useUserStore();
    const { isAuth: isAuthRef } = storeToRefs(userStore);
    const route = useRoute();
    const router = useRouter();

    const guard = (isAuth, routeAuthGuard) => {
      if (!isAuth && routeAuthGuard) {
        isAuthGuardPassed.value = false;
        cachePathBeforeRedirect();
        return redirectToLogin();
      }
      // User passed the guard
      isAuthGuardPassed.value = true;
    };

    const redirectToLogin = () => {
      console.log(">>>AUTH_GUARD -> REDIRECT_TO_LOGIN<<<");
      router.push({ name: ROUTE_LOGIN_NAME });
    };

    const cachePathBeforeRedirect = () => {
      // Cache current route path to use it later after authentification
      userStore.routePathBeforeLogin = route.path;
    };

    watch(
      () => route.meta.authGuard,
      (authGuard) => {
        guard(isAuthRef.value, authGuard);
      },
      {
        immediate: true,
      }
    );

    watch(isAuthRef, (isAuth) => {
      guard(isAuth, route.meta.authGuard);
    });

    return {
      isAuthGuardPassed,
    };
  },
};
</script>
