// Offer Nanny --> Create
export const ROUTE_OFFER_NANNY_CREATE_NAME = "OfferNannyCreate";
export const ROUTE_OFFER_NANNY_CREATE_PATH = "/offer/nanny/create";

// Offer Nanny --> Create --> Address
export const ROUTE_OFFER_NANNY_CREATE_ADDRESS_NAME = "OfferNannyCreateAddress";
export const ROUTE_OFFER_NANNY_CREATE_ADDRESS_PATH = "address";

// Offer Nanny --> Create --> Aupair
export const ROUTE_OFFER_NANNY_CREATE_AUPAIR_PATH = "aupair";
export const ROUTE_OFFER_NANNY_CREATE_AUPAIR_NAME = "OfferNannyCreateAupair";

// Offer Nanny --> Create --> Kids
export const ROUTE_OFFER_NANNY_CREATE_KIDS_NAME = "OfferNannyCreateKids";
export const ROUTE_OFFER_NANNY_CREATE_KIDS_PATH = "kids";

// Offer Nanny --> Create --> Employment --> Type
export const ROUTE_OFFER_NANNY_CREATE_EMPLOYMENT_TYPE_PATH = "employment/type";
export const ROUTE_OFFER_NANNY_CREATE_EMPLOYMENT_TYPE_NAME =
  "OfferNannyCreateEmploymentType";

// Offer Nanny --> Create --> Employment --> Regularity
export const ROUTE_OFFER_NANNY_CREATE_EMPLOYMENT_REGULARITY_PATH =
  "employment/regularity";
export const ROUTE_OFFER_NANNY_CREATE_EMPLOYMENT_REGULARITY_NAME =
  "OfferNannyCreateEmploymentRegularity";

// Offer Nanny --> Create --> Babby-sitting period
export const ROUTE_OFFER_NANNY_CREATE_PERIOD_BABBY_SITTING_PATH =
  "babby-sitting-period";
export const ROUTE_OFFER_NANNY_CREATE_PERIOD_BABBY_SITTING_NAME =
  "OfferNannyCreatePeriodBabbySitting";

// Offer Nanny --> Create --> Schedule
export const ROUTE_OFFER_NANNY_CREATE_SCHEDULE_PATH = "schedule";
export const ROUTE_OFFER_NANNY_CREATE_SCHEDULE_NAME =
  "OfferNannyCreateSchedule";

// Offer Nanny --> Create --> Perks
export const ROUTE_OFFER_NANNY_CREATE_PERKS_PATH = "perks";
export const ROUTE_OFFER_NANNY_CREATE_PERKS_NAME = "OfferNannyCreatePerks";

// Offer Nanny --> Create --> Responsibilities
export const ROUTE_OFFER_NANNY_CREATE_RESPONSIBILITIES_PATH =
  "responsibilities";
export const ROUTE_OFFER_NANNY_CREATE_RESPONSIBILITIES_NAME =
  "OfferNannyCreateResponsibilities";

// Offer Nanny --> Create --> Schedule
export const ROUTE_OFFER_NANNY_CREATE_DETAILS_PATH = "details";
export const ROUTE_OFFER_NANNY_CREATE_DETAILS_NAME = "OfferNannyCreateDetails";

// Offer Nanny --> Create --> Photo main
export const ROUTE_OFFER_NANNY_CREATE_PHOTO_MAIN_PATH = "photo-main";
export const ROUTE_OFFER_NANNY_CREATE_PHOTO_MAIN_NAME =
  "OfferNannyCreatePhotoMain";

// Offer Nanny --> Create --> Finish
export const ROUTE_OFFER_NANNY_CREATE_FINISH_PATH = "finish";
export const ROUTE_OFFER_NANNY_CREATE_FINISH_NAME = "OfferNannyCreateFinish";
