export * from "./offerNannyCreate";

// Offer Nanny --> Update
export const ROUTE_OFFER_NANNY_UPDATE_NAME = "OfferNannyUpdate";
export const ROUTE_OFFER_NANNY_UPDATE_PATH = "/offer/nanny/update/id-:id";

// Offer Nanny --> List
export const ROUTE_OFFER_NANNY_LIST_PATH = "/offers/nanny";

// Offer Nanny --> Details
export const ROUTE_OFFER_NANNY_DETAILS_NAME = "OfferNannyDetails";
export const ROUTE_OFFER_NANNY_DETAILS_PATH = "/offers/nanny/id-:id";
