import {
  ROUTE_DOCS_COOKIES_NAME,
  ROUTE_DOCS_COOKIES_PATH,
  ROUTE_DOCS_PRIVACY_POLICY_NAME,
  ROUTE_DOCS_PRIVACY_POLICY_PATH,
  ROUTE_DOCS_TERMS_AND_CONDITIONS_NAME,
  ROUTE_DOCS_TERMS_AND_CONDITIONS_PATH,
} from "@/router/constants";

const DocsPrivacyPolicy = () => import("@/views/Docs/DocsPrivacyPolicy.vue");
const DocsTermsAndConditions = () =>
  import("@/views/Docs/DocsTermsAndConditions.vue");
const DocsCookies = () => import("@/views/Docs/DocsCookies.vue");

const docsRoutes = [
  {
    path: ROUTE_DOCS_PRIVACY_POLICY_PATH,
    name: ROUTE_DOCS_PRIVACY_POLICY_NAME,
    component: DocsPrivacyPolicy,
  },
  {
    path: ROUTE_DOCS_TERMS_AND_CONDITIONS_PATH,
    name: ROUTE_DOCS_TERMS_AND_CONDITIONS_NAME,
    component: DocsTermsAndConditions,
  },
  {
    path: ROUTE_DOCS_COOKIES_PATH,
    name: ROUTE_DOCS_COOKIES_NAME,
    component: DocsCookies,
  },
];

export default docsRoutes;
