const AuthRegistration = () =>
  import("@/views/Auth/Registration/AuthRegistration.vue");
const AuthRegistrationStart = () =>
  import("@/views/Auth/Registration/AuthRegistrationStart.vue");
const AuthRegistrationEmail = () =>
  import("@/views/Auth/Registration/AuthRegistrationEmail.vue");
const AuthRegistrationIdentification = () =>
  import("@/views/Auth/Registration/AuthRegistrationIdentification.vue");
const AuthRegistrationDetails = () =>
  import("@/views/Auth/Registration/AuthRegistrationDetails.vue");
const AuthRegistrationWelcome = () =>
  import("@/views/Auth/Registration/AuthRegistrationWelcome.vue");

import {
  ROUTE_REGISTRATION_NAME,
  ROUTE_REGISTRATION_PATH,
  ROUTE_REGISTRATION_START_NAME,
  ROUTE_REGISTRATION_START_PATH,
  ROUTE_REGISTRATION_EMAIL_NAME,
  ROUTE_REGISTRATION_EMAIL_PATH,
  ROUTE_REGISTRATION_IDENTIFICATION_NAME,
  ROUTE_REGISTRATION_IDENTIFICATION_PATH,
  ROUTE_REGISTRATION_DETAILS_NAME,
  ROUTE_REGISTRATION_DETAILS_PATH,
  ROUTE_REGISTRATION_WELCOME_NAME,
  ROUTE_REGISTRATION_WELCOME_PATH,
} from "@/router/constants";

const registrationRoute = {
  path: ROUTE_REGISTRATION_PATH,
  name: ROUTE_REGISTRATION_NAME,
  component: AuthRegistration,
  redirect: { name: ROUTE_REGISTRATION_START_NAME },
  children: [
    {
      // Starting page (choosing registration strategy google/local)
      path: ROUTE_REGISTRATION_START_PATH,
      name: ROUTE_REGISTRATION_START_NAME,
      component: AuthRegistrationStart,
    },
    {
      // Email and password registration page
      path: ROUTE_REGISTRATION_EMAIL_PATH,
      name: ROUTE_REGISTRATION_EMAIL_NAME,
      component: AuthRegistrationEmail,
    },
    {
      // Asking useType (client/contractor)
      path: ROUTE_REGISTRATION_IDENTIFICATION_PATH,
      name: ROUTE_REGISTRATION_IDENTIFICATION_NAME,
      component: AuthRegistrationIdentification,
    },
    {
      // Asking more details: name and ZIP, etc.
      path: ROUTE_REGISTRATION_DETAILS_PATH,
      name: ROUTE_REGISTRATION_DETAILS_NAME,
      component: AuthRegistrationDetails,
    },
    {
      // Registration succeded. Welcome page with links to the next steps
      path: ROUTE_REGISTRATION_WELCOME_PATH,
      name: ROUTE_REGISTRATION_WELCOME_NAME,
      component: AuthRegistrationWelcome,
    },
  ],
};

export default registrationRoute;
