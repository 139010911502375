<template>
  <InitializationGuard>
    <component :is="layoutComponent">
      <router-view #default="{ Component }">
        <AuthGuard>
          <KeepAlive :include="['ListNanny']">
            <component :is="Component" />
          </KeepAlive>
        </AuthGuard>
        <CookiesConsent />
      </router-view>
    </component>
  </InitializationGuard>
</template>

<script>
import { computed } from "@vue/reactivity";
import { defineAsyncComponent } from "@vue/runtime-core";
import { useRoute } from "vue-router";

import AuthGuard from "@/components/AuthGuard.vue";
import InitializationGuard from "@/components/InitializationGuard.vue";
import CookiesConsent from "./components/CookiesConsent/CookiesConsent.vue";

export default {
  components: { AuthGuard, InitializationGuard, CookiesConsent },

  setup() {
    const route = useRoute();

    const layoutComponent = computed(
      () =>
        route.meta.layout ||
        defineAsyncComponent({
          loader: () => import(`@/layouts/LayoutDefault.vue`),
          delay: 0,
        })
    );

    return {
      layoutComponent,
    };
  },
};
</script>

<style src="./assets/styles/reset.css"></style>
<style src="./assets/styles/style.css"></style>

<style src="./assets/styles/buttons-vars.css"></style>
<style src="./assets/styles/text-inputs-vars.css"></style>
