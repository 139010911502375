import {
  getApplicationNanny,
  updateApplicationNanny,
} from "@/api/applicationNanny";
import {
  CREATE_APPLICATION_NANNY,
  PUSH_IMAGE_TO_APPLICATION_NANNY_PHOTO_GALLERY,
  PULL_IMAGE_FROM_APPLICATION_NANNY_PHOTO_GALLERY,
  UPLOAD_APPLICATION_NANNY_PHOTO_MAIN,
} from "@/graphql/mutations/applicationNanny";
import { getFilenameExcludingExtension } from "@/helpers";
import { apolloClient } from "@/plugins/apollo";
import { defineStore, storeToRefs } from "pinia";
import { watch } from "vue";
import { useUserStore } from "./user";
import { useUserProfileStore } from "./userProfile";

export const APPLICATION_NANNY_STORE_NAME = "APPLICATION_NANNY_STORE";

export const useApplicationNannyStore = defineStore(
  APPLICATION_NANNY_STORE_NAME,
  {
    state: () => ({
      _id: null,
      user_id: null,
      user_profile_id: null,
      // user_profile_id_populated: {
      //   _id: "",
      //   user_id: "",
      //   nameFirst: "",
      //   nameLast: "",
      //   nameBusiness: null,
      //   zip: "",
      //   sex: null,
      //   dob: null,
      //   phone: null,
      //   lastSeen: null, // Date | String
      // },
      response_ids: [],
      // response_ids_populated: [],
      // outgoing_response_ids_populated: [],
      review_ids: [],
      // review_ids_populated: [],
      recommendation_ids: [],
      recommendation_ids_populated: [],
      meta: {
        visitor_user_ids: [],
        visitsAll: 0,
        visitsUnique: 0,
        clicksCtaUnique: 0,
        status: "draft", // paused, published, archived, draft
        // createdAt: null, // Date
        refreshedAt: () => Date.now(), // Date
      },
      details: {
        description: "",
        location: {
          city: "",
          cityMarket: "",
          address: null,
          point: {
            type: "Point",
            coordinates: [],
          },
        },
        rate: {
          hour: 0,
          month: null,
        },
        photoMain: {
          src: "",
          file: null, // local field
          originalSrc: "",
          originalFile: null, // local field
          originalFilename: "",
          originalMimetype: "",
          alt: "",
        },
        // isPhotoMainUpdated: false, // local field
        photoGallery: [],
        // isPhotoGalleryUpdated: false, // local field
        aupair: "",
        languages: [],
      },
      employment: {
        types: [],
        regularities: [],
      },
      responsibilities: {
        careChild: [],
        careHome: [],
        activities: [],
      },
      schedule: {
        value: [],
        comment: null,
        dateStart: "", // Date
      },
      perks: [],
      experience: {
        working: {
          isPresent: false,
          years: null,
          kidsAges: [],
          comment: null,
        },
        life: [],
      },
      education: {
        degree: "",
        types: [],
        courses: [],
        comment: null,
      },
      storeMeta: {
        isInitializing: false,
        isInitialized: false,
        isLoading: false,
        isUpdating: false,
        error: null,
        watcherStopHandlers: [],
      },
    }),

    getters: {},

    actions: {
      async reset() {
        console.log("RESET_APPLICATION_NANNY_STORE");

        this.storeMeta.watcherStopHandlers.forEach((stopWatch) => stopWatch());

        this.$reset();
      },

      async createApplication() {
        this.storeMeta.isLoading = true;

        const user = useUserStore();
        const userId = user._id;

        if (userId === null) {
          this.storeMeta.isLoading = false;
          throw new Error(
            "User misses '_id' property. Probably user was not created!"
          );
        }

        this.user_id = userId;

        const userProfile = useUserProfileStore();
        const userProfileId = userProfile._id;

        if (userProfileId === null) {
          this.storeMeta.isLoading = false;
          throw new Error(
            "UserProfile misses '_id' property. Probably user profile was not created!"
          );
        }

        this.user_profile_id = userProfileId;

        const { data } = await apolloClient.mutate({
          mutation: CREATE_APPLICATION_NANNY,
          variables: {
            payload: {
              user_id: this.user_id,
              user_profile_id: this.user_profile_id,
              meta: {
                status: this.meta.status,
                createdAt: () => Date.now(),
                refreshedAt: this.meta.refreshedAt,
              },
              details: {
                description: this.details.description,
                location: {
                  city: this.details.location.city,
                  cityMarket: this.details.location.cityMarket,
                  address: this.details.location.address,
                  point: {
                    type: this.details.location.point.type,
                    coordinates: this.details.location.point.coordinates,
                  },
                },
                rate: {
                  hour: this.details.rate.hour,
                  month: this.details.rate.month,
                },
                aupair: this.details.aupair,
                languages: this.details.languages,
              },
              employment: {
                types: this.employment.types,
                regularities: this.employment.regularities,
              },
              responsibilities: {
                careChild: this.responsibilities.careChild,
                careHome: this.responsibilities.careHome,
                activities: this.responsibilities.activities,
              },
              schedule: {
                value: this.schedule.value,
                comment: this.schedule.comment,
                dateStart: this.schedule.dateStart,
              },
              perks: this.perks,
              experience: {
                working: {
                  isPresent: this.experience.working.isPresent,
                  years: this.experience.working.years,
                  kidsAges: this.experience.working.kidsAges,
                  comment: this.experience.working.comment,
                },
                life: this.experience.life,
              },
              education: {
                degree: this.education.degree,
                types: this.education.types,
                courses: this.education.courses,
                comment: this.education.comment,
              },
            },
          },
        });

        if (data === null) {
          this.storeMeta.isLoading = false;
          throw new Error("Response 'Data' is empty!");
        }

        if ("createApplicationNanny" in data === false) {
          this.storeMeta.isLoading = false;
          throw new Error(
            "Response 'Data' misesses 'createApplicationNanny' property!"
          );
        }

        if ("_id" in data.createApplicationNanny === false) {
          this.storeMeta.isLoading = false;
          throw new Error(
            "'Data.createApplicationNanny' misesses '_id' property!"
          );
        }

        const newApplicationId = data.createApplicationNanny._id;
        console.log("createApplicationNanny ID", newApplicationId);

        if (newApplicationId === null) {
          this.storeMeta.isLoading = false;
          throw new Error("Returned ApplicationNanny '_id' is null!");
        }

        this._id = newApplicationId;

        user.applications.nanny_id = newApplicationId;
        this.storeMeta.isLoading = false;
        return newApplicationId;
      },

      async updatePhotoMain() {
        try {
          this.storeMeta.isLoading = true;

          const application_id = this._id;
          const user_id = this.user_id;
          const croppedFile = this.details.photoMain.file;
          const originalFile = this.details.photoMain.originalFile;

          if (application_id === null) {
            throw new Error("Application id is null!");
          }

          if (user_id === null) {
            throw new Error("User id is null!");
          }

          if (!croppedFile && !originalFile) {
            throw new Error("No files provided!");
          }

          const { errors } = await apolloClient.mutate({
            mutation: UPLOAD_APPLICATION_NANNY_PHOTO_MAIN,
            variables: {
              applicationId: application_id,
              userId: user_id,
              fileOriginal: originalFile,
              fileCropped: croppedFile,
            },
          });

          if (errors) {
            console.log(errors);
            throw new Error("Graphql errors");
          }

          return "OK";
        } finally {
          this.storeMeta.isLoading = false;
        }
      },

      getIndexFromPhotoGalleryByFilename(filename) {
        const photoGallery = this.details.photoGallery;

        // Get index of removing image
        const indexSearching = photoGallery.findIndex((imgCurrent) => {
          const filenameCurrent = getFilenameExcludingExtension(
            imgCurrent.originalFilename
          );
          const filenameSearching = getFilenameExcludingExtension(filename);

          return filenameCurrent === filenameSearching;
        });

        return indexSearching;
      },

      async pushImageToPhotoGallery(image) {
        const uploadImageFileToServer = async (file) => {
          const application_id = this._id;
          const user_id = this.user_id;

          if (application_id === null) {
            throw new Error("Application_id is null! Please init application.");
          }

          if (user_id === null) {
            throw new Error("User id is null!");
          }

          const { errors } = await apolloClient.mutate({
            mutation: PUSH_IMAGE_TO_APPLICATION_NANNY_PHOTO_GALLERY,
            variables: {
              applicationId: application_id,
              userId: user_id,
              file: file,
            },
          });

          if (errors) {
            console.log(errors);
            throw new Error("Graphql errors");
          }

          return "OK";
        };

        try {
          const photoGallery = this.details.photoGallery;

          if (photoGallery === null) {
            throw new Error("PhotoGallery is null!");
          }

          if (image.isUploadError == null) {
            throw new Error("image.isUploadError is null!");
          }

          // In case of an error during the previous upload
          if (image.isUploadError === true) {
            // Set loading state to true
            const indexImgUploading = this.getIndexFromPhotoGalleryByFilename(
              image.originalFilename
            );
            if (indexImgUploading !== -1) {
              const imageUploading = photoGallery[indexImgUploading];
              imageUploading.isLoading = true;
            } else {
              console.warn(
                "Image has 'isUploadError === true', while it's not in the local state. Strange behaviour.."
              );
              image.isUploadError = false;
            }
          }

          // In case of a first try
          if (image.isUploadError === false) {
            // Set loading state to true
            image.isLoading = true;

            // Push image to the local state
            photoGallery.push(image);
          }

          // Upload image to the server
          await uploadImageFileToServer(image.file);

          // Set error state to false
          const indexImgUploading = this.getIndexFromPhotoGalleryByFilename(
            image.originalFilename
          );
          const imageUploading = photoGallery[indexImgUploading];
          imageUploading.isUploadError = false;
        } catch (error) {
          // Set error state to true
          const indexImgUploading = this.getIndexFromPhotoGalleryByFilename(
            image.originalFilename
          );
          if (indexImgUploading !== -1) {
            const imageUploading = this.details.photoGallery[indexImgUploading];
            imageUploading.isUploadError = true;
          }
          throw error;
        } finally {
          // Stop loading state
          const indexImgUploading = this.getIndexFromPhotoGalleryByFilename(
            image.originalFilename
          );
          if (indexImgUploading !== -1) {
            const imageUploading = this.details.photoGallery[indexImgUploading];
            imageUploading.isLoading = false;
          }
        }
      },

      async pullImageFromPhotoGallery(image) {
        const deleteImageFromServer = async (filename) => {
          const application_id = this._id;

          if (application_id === null) {
            throw new Error("Application_id is null! Please init application.");
          }

          const { errors } = await apolloClient.mutate({
            mutation: PULL_IMAGE_FROM_APPLICATION_NANNY_PHOTO_GALLERY,
            variables: {
              applicationId: application_id,
              filename: filename,
            },
          });

          if (errors) {
            console.log(errors);
            throw new Error("Graphql errors");
          }

          return "OK";
        };

        try {
          if (image.isLoading) {
            throw new Error("Image is loading... Please try again later.");
          }

          const photoGallery = this.details.photoGallery;

          if (photoGallery === null) {
            throw new Error("PhotoGallery is null!");
          }

          // Set loading state to true
          let indexDeleting = this.getIndexFromPhotoGalleryByFilename(
            image.originalFilename
          );
          if (indexDeleting === -1) {
            throw new Error(
              "Cannot find image in local state. Was it already deleted?"
            );
          }
          const imageDeleting = photoGallery[indexDeleting];
          imageDeleting.isLoading = true;

          // Pull image from server
          await deleteImageFromServer(image.originalFilename);

          // Updating index of image (in case another image located before 'imageDeleting' was deleted earlier)
          // ??? Probably it's overkill...
          indexDeleting = this.getIndexFromPhotoGalleryByFilename(
            image.originalFilename
          );

          // Pull image from local state
          photoGallery.splice(indexDeleting, 1);
        } catch (error) {
          // Set image loading state to false
          const indexDeleting = this.getIndexFromPhotoGalleryByFilename(
            image.originalFilename
          );

          if (indexDeleting !== -1) {
            const photoGallery = this.details.photoGallery;
            const imageDeleting = photoGallery[indexDeleting];
            imageDeleting.isLoading = false;
          }

          throw error;
        }
      },

      async initApplicationDraft() {
        console.log("INIT_APPLICATION_NANNY_DRAFT");

        // Reset current state
        this.reset();

        const user = useUserStore();
        const userProfile = useUserProfileStore();

        const initDraft = () => {
          const user_id = user._id;
          const user_profile_id = userProfile._id;
          const locationCityMarket = user.websiteSettings.city;

          if (!user_id) {
            throw new Error("ApplicationNanny_id is missed!");
          }

          if (!user_profile_id) {
            throw new Error("ApplicationNanny_profile_id is missed!");
          }

          if (!locationCityMarket) {
            throw new Error("LocationCityMarket is missed!");
          }

          this.user_id = user_id;
          this.user_profile_id = user_profile_id;
          this.details.location.cityMarket = locationCityMarket;

          this.storeMeta.isInitialized = true;
        };

        const waitForPiniaInit = () => {
          const { _id: user_id } = storeToRefs(user);
          const { _id: user_profile_id } = storeToRefs(userProfile);

          let isApplicationDraftInitialized = false;
          // Wait for user and userProfile stores to load
          return watch(
            [user_id, user_profile_id],
            ([userId, profileId]) => {
              if (isApplicationDraftInitialized === true) return;

              if (userId && profileId) {
                isApplicationDraftInitialized = true;
                initDraft();
              }
            },
            { immediate: true }
          );
        };

        const stopHandler = waitForPiniaInit();

        this.storeMeta.watcherStopHandlers.push(stopHandler);
      },

      setDataToApplicationNannyStore(data) {
        if (data === null) {
          throw new Error("Response 'Data' is null!");
        }

        if ("applicationNanny" in data === false) {
          throw new Error(
            "Response 'Data' misesses 'applicationNanny' property!"
          );
        }

        const applicationNanny = JSON.parse(
          JSON.stringify(data.applicationNanny)
        );

        this._id = applicationNanny._id;
        this.user_id = applicationNanny.user_id;
        this.user_profile_id = applicationNanny.user_profile_id;
        this.response_ids = applicationNanny.response_ids;
        this.review_ids = applicationNanny.review_ids;
        this.recommendation_ids = applicationNanny.recommendation_ids;
        this.meta.visitor_user_ids = applicationNanny.meta.visitor_user_ids;
        this.meta.visitsAll = applicationNanny.meta.visitsAll;
        this.meta.visitsUnique = applicationNanny.meta.visitsUnique;
        this.meta.clicksCtaUnique = applicationNanny.meta.clicksCtaUnique;
        this.meta.status = applicationNanny.meta.status;
        this.meta.createdAt = new Date(applicationNanny.meta.createdAt);
        this.meta.refreshedAt = new Date(applicationNanny.meta.refreshedAt);
        this.details.description = applicationNanny.details.description;
        this.details.location.city = applicationNanny.details.location.city;
        this.details.location.cityMarket =
          applicationNanny.details.location.cityMarket;
        this.details.location.address =
          applicationNanny.details.location.address;
        this.details.location.point.type =
          applicationNanny.details.location.point.type;
        this.details.location.point.coordinates =
          applicationNanny.details.location.point.coordinates;
        this.details.rate.hour = applicationNanny.details.rate.hour;
        this.details.rate.month = applicationNanny.details.rate.month;

        if (applicationNanny.details.photoMain === null) {
          this.details.photoMain.src = "";
          this.details.photoMain.originalSrc = "";
          this.details.photoMain.originalFilename = "";
          this.details.photoMain.originalMimetype = "";
          this.details.photoMain.alt = "";
        } else {
          this.details.photoMain.src = applicationNanny.details.photoMain.src;
          this.details.photoMain.originalSrc =
            applicationNanny.details.photoMain.originalSrc;
          this.details.photoMain.originalFilename =
            applicationNanny.details.photoMain.originalFilename;
          this.details.photoMain.originalMimetype =
            applicationNanny.details.photoMain.originalMimetype;
          this.details.photoMain.alt = applicationNanny.details.photoMain.alt;
        }

        this.details.photoGallery = applicationNanny.details.photoGallery;
        this.details.aupair = applicationNanny.details.aupair;
        this.details.languages = applicationNanny.details.languages;
        this.employment.types = applicationNanny.employment.types;
        this.employment.regularities = applicationNanny.employment.regularities;

        this.responsibilities.careChild =
          applicationNanny.responsibilities.careChild;

        this.responsibilities.careHome =
          applicationNanny.responsibilities.careHome;

        this.responsibilities.activities =
          applicationNanny.responsibilities.activities;

        this.schedule.value = applicationNanny.schedule.value;
        this.schedule.comment = applicationNanny.schedule.comment;
        this.schedule.dateStart = new Date(applicationNanny.schedule.dateStart);
        this.perks = applicationNanny.perks;
        this.experience.working.isPresent =
          applicationNanny.experience.working.isPresent;
        this.experience.working.years =
          applicationNanny.experience.working.years;
        this.experience.working.kidsAges =
          applicationNanny.experience.working.kidsAges;

        this.experience.working.comment =
          applicationNanny.experience.working.comment;
        this.experience.life = applicationNanny.experience.life;
        this.education.degree = applicationNanny.education.degree;
        this.education.types = applicationNanny.education.types;
        this.education.courses = applicationNanny.education.courses;
        this.education.comment = applicationNanny.education.comment;
      },

      async initApplication(application_id) {
        try {
          if (this.storeMeta.isInitializing === true) {
            console.warn("ApplicationNanny is already initializing!");
            return;
          }

          if (this.storeMeta.isInitialized === true) {
            throw new Error("ApplicationNanny is already initialized!");
          }

          if (!application_id) {
            throw new Error(
              `Provide a correct application_id. Received '${application_id}'!`
            );
          }

          this.storeMeta.isInitializing = true;
          this.storeMeta.isLoading = true;

          const { data } = await getApplicationNanny({
            application_id,
          });

          this.setDataToApplicationNannyStore(data);

          this.storeMeta.isInitialized = true;

          return "OK";
        } finally {
          this.storeMeta.isInitializing = false;
          this.storeMeta.isLoading = false;
        }
      },

      async updateApplication() {
        try {
          this.storeMeta.isUpdating = true;

          const application_id = this._id;

          if (this.storeMeta.isInitialized === false) {
            throw new Error(`Init applicationNanny first!`);
          }

          if (application_id === null) {
            throw new Error(
              `Recieved 'null' instead of 'application_id'! BTW, store was already initialized...`
            );
          }

          const { data } = await updateApplicationNanny({
            application_id,
            applicationNannyStore: this,
          });

          if (data === null) {
            throw new Error("Response 'Data' is 'null'!");
          }

          if ("updateApplicationNanny" in data === false) {
            throw new Error(
              "Response 'Data' misesses 'updateApplicationNanny' property!"
            );
          }

          if ("_id" in data.updateApplicationNanny === false) {
            throw new Error(
              "'Data.updateApplicationNanny' misesses '_id' property!"
            );
          }
        } finally {
          this.storeMeta.isUpdating = false;
        }
      },
    },
  }
);
