// Application Nanny --> Create
export const ROUTE_APPLICATION_NANNY_CREATE_NAME = "ApplicationNannyCreate";
export const ROUTE_APPLICATION_NANNY_CREATE_PATH = "/application/nanny/create";

// Application Nanny --> Create --> Address
export const ROUTE_APPLICATION_NANNY_CREATE_ADDRESS_PATH = "address";
export const ROUTE_APPLICATION_NANNY_CREATE_ADDRESS_NAME =
  "ApplicationNannyCreateAddress";

// Application Nanny --> Create --> Aupair
export const ROUTE_APPLICATION_NANNY_CREATE_AUPAIR_PATH = "aupair";
export const ROUTE_APPLICATION_NANNY_CREATE_AUPAIR_NAME =
  "ApplicationNannyCreateAupair";

// Application Nanny --> Create --> Employment --> Type
export const ROUTE_APPLICATION_NANNY_CREATE_EMPLOYMENT_TYPE_PATH =
  "employment/type";
export const ROUTE_APPLICATION_NANNY_CREATE_EMPLOYMENT_TYPE_NAME =
  "ApplicationNannyCreateEmploymentType";

// Application Nanny --> Create --> Employment --> Regularity
export const ROUTE_APPLICATION_NANNY_CREATE_EMPLOYMENT_REGULARITY_PATH =
  "employment/regularity";
export const ROUTE_APPLICATION_NANNY_CREATE_EMPLOYMENT_REGULARITY_NAME =
  "ApplicationNannyCreateEmploymentRegularity";

// Application Nanny --> Create --> Schedule
export const ROUTE_APPLICATION_NANNY_CREATE_SCHEDULE_PATH = "schedule";
export const ROUTE_APPLICATION_NANNY_CREATE_SCHEDULE_NAME =
  "ApplicationNannyCreateSchedule";

// Application Nanny --> Create --> Salary
export const ROUTE_APPLICATION_NANNY_CREATE_SALARY_PATH = "salary";
export const ROUTE_APPLICATION_NANNY_CREATE_SALARY_NAME =
  "ApplicationNannyCreateSalary";

// Application Nanny --> Create --> Experience working
export const ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_WORKING_PATH =
  "working-experince";
export const ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_WORKING_NAME =
  "ApplicationNannyCreateExperienceWorking";

// Application Nanny --> Create --> Experience working --> Kids ages
export const ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_WORKING_KIDS_AGES_PATH =
  "working-experince/kids-ages";
export const ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_WORKING_KIDS_AGES_NAME =
  "ApplicationNannyCreateExperienceWorkingKidsAges";

// Application Nanny --> Create --> Experience working --> Years
export const ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_WORKING_YEARS_PATH =
  "working-experince/years";
export const ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_WORKING_YEARS_NAME =
  "ApplicationNannyCreateExperienceWorkingYears";

// Application Nanny --> Create --> Experience --> Own kids
export const ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_OWN_KIDS_PATH =
  "experience/own-kids";
export const ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_OWN_KIDS_NAME =
  "ApplicationNannyCreateExperienceOwnKids";

// Application Nanny --> Create --> Experience --> Twins
export const ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_TWINS_PATH =
  "experience/twins";
export const ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_TWINS_NAME =
  "ApplicationNannyCreateExperienceTwins";

// Application Nanny --> Create --> Experience --> Special care
export const ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_SPECIAL_CARE_PATH =
  "experience/special-care";
export const ROUTE_APPLICATION_NANNY_CREATE_EXPERIENCE_SPECIAL_CARE_NAME =
  "ApplicationNannyCreateExperienceSpecialCare";

// Application Nanny --> Create --> Education --> Degree
export const ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_DEGREE_PATH =
  "education/degree";
export const ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_DEGREE_NAME =
  "ApplicationNannyCreateEducationDegree";

// Application Nanny --> Create --> Education --> Type
export const ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_TYPE_PATH =
  "education/type";
export const ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_TYPE_NAME =
  "ApplicationNannyCreateEducationType";

// Application Nanny --> Create --> Education --> Student type
export const ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_TYPE_STUDENT_PATH =
  "education/student-type";
export const ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_TYPE_STUDENT_NAME =
  "ApplicationNannyCreateEducationTypeStudent";

// Application Nanny --> Create --> Education --> Courses
export const ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_COURSES_PATH =
  "education/courses";
export const ROUTE_APPLICATION_NANNY_CREATE_EDUCATION_COURSES_NAME =
  "ApplicationNannyCreateEducationCourses";

// Application Nanny --> Create --> Responsibilities
export const ROUTE_APPLICATION_NANNY_CREATE_RESPONSIBILITIES_PATH =
  "responsibilities";
export const ROUTE_APPLICATION_NANNY_CREATE_RESPONSIBILITIES_NAME =
  "ApplicationNannyCreateResponsibilities";

// Application Nanny --> Create --> Perks
export const ROUTE_APPLICATION_NANNY_CREATE_PERKS_PATH = "perks";
export const ROUTE_APPLICATION_NANNY_CREATE_PERKS_NAME =
  "ApplicationNannyCreatePerks";

// Application Nanny --> Create --> About me
export const ROUTE_APPLICATION_NANNY_CREATE_ABOUT_ME_PATH = "about-me";
export const ROUTE_APPLICATION_NANNY_CREATE_ABOUT_ME_NAME =
  "ApplicationNannyCreateAboutMe";

// Application Nanny --> Create --> Photo main
export const ROUTE_APPLICATION_NANNY_CREATE_PHOTO_MAIN_PATH = "photo-main";
export const ROUTE_APPLICATION_NANNY_CREATE_PHOTO_MAIN_NAME =
  "ApplicationNannyCreatePhotoMain";

// Application Nanny --> Create --> Photo gallery
export const ROUTE_APPLICATION_NANNY_CREATE_PHOTO_GALLERY_PATH =
  "photo-gallery";
export const ROUTE_APPLICATION_NANNY_CREATE_PHOTO_GALLERY_NAME =
  "ApplicationNannyCreatePhotoGallery";

// Application Nanny --> Create --> Recommendations
export const ROUTE_APPLICATION_NANNY_CREATE_RECOMMENDATIONS_PATH =
  "recommendations";
export const ROUTE_APPLICATION_NANNY_CREATE_RECOMMENDATIONS_NAME =
  "ApplicationNannyCreateRecommendations";

// Application Nanny --> Create --> Finish
export const ROUTE_APPLICATION_NANNY_CREATE_FINISH_PATH = "finish";
export const ROUTE_APPLICATION_NANNY_CREATE_FINISH_NAME =
  "ApplicationNannyCreateFinish";
