<template>
  <label class="radio-button">
    <input
      class="dot radio-button__dot"
      type="radio"
      :name="name"
      :value="value"
      @change="$emit('update:modelValue', value)"
      :checked="
        Array.isArray(modelValue)
          ? value == modelValue.join()
          : value === modelValue
      "
    />
    <span class="text-wrapper radio-button__text-wrapper">
      {{ label }}
      <slot name="datePicker"></slot>
    </span>
  </label>
</template>

<script>
export default {
  props: {
    modelValue: [String, Number, Array, Boolean],
    value: [String, Number, Array, Boolean],
    label: String,
    name: String,
  },

  emits: ["update:modelValue"],
};
</script>

<style scoped>
/* Radio Buttons */

.radio-button {
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 10px;
  border-radius: var(--button-border-radius);
}
.radio-button__text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 20px;
  width: var(--radio-button-width);
  height: var(--button-height);

  color: var(--button-font-color);
  font-family: var(--default-font-family);
  font-size: var(--default-font-size);
  font-weight: var(--button-font-weight);
  text-align: center;

  background-color: var(--button-tertiary-bg-color);
  border: var(--button-tertiary-border);
  box-sizing: border-box;
  border-radius: var(--button-border-radius);
}

.radio-button--width-small .radio-button__text-wrapper {
  width: var(--button-width);
}

.radio-button__text-wrapper:hover {
  border: var(--buton-tertiary-hover-border);
  cursor: pointer;
}
.radio-button__text-wrapper:active {
  border: var(--buton-tertiary-active-border);
  background-color: var(--buton-tertiary-active-bg-color);
}

.radio-button--height-small .radio-button__text-wrapper {
  height: var(--button-small-height);
  font-size: var(--button-small-font-size);
}

.radio-button__dot {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -40px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.radio-button--dot-hide .radio-button__dot {
  display: none;
}

.radio-button__dot:after {
  margin-top: auto;
  margin-bottom: auto;

  width: 20px;
  height: 20px;
  border-radius: 30px;
  box-sizing: border-box;

  position: absolute;

  background-color: var(--main-bg-color);
  content: "";
  display: block;
  visibility: visible;
  border: var(--button-tertiary-border);
}

.radio-button:hover .radio-button__dot:after {
  border: var(--buton-tertiary-hover-border);
}

.radio-button__dot:checked:after {
  border: var(--buton-tertiary-hover-border);
  background-color: var(--button-font-color);
  box-shadow: inset 0px 0px 0px 4px var(--main-bg-color);
}

.radio-button__dot:checked + .radio-button__text-wrapper {
  border: var(--buton-tertiary-hover-border);
  background-color: var(--buton-tertiary-active-bg-color);
}
</style>
