<template>
  <BaseButton
    :to="to"
    :disabled="disabled"
    class="circle-button"
    :class="buttonClass"
    @click="$emit('click', $event)"
  />
</template>

<script>
import { computed } from "vue";

import BaseButton from "./BaseButton.vue";

export default {
  components: { BaseButton },

  props: {
    to: {
      type: [String, Object],
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minus: {
      type: Boolean,
      default: false,
    },
    plus: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["click"],

  setup(props) {
    const buttonClass = computed(() => {
      return {
        "circle-button--minus": props.minus,
        "circle-button--plus": props.plus,
        "circle-button--small": props.small,
      };
    });
    return {
      buttonClass,
    };
  },
};
</script>

<style scoped>
/* CIRCLE BUTTON (+ / -) */

.circle-button {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: var(--circle-button-border);

  background-color: var(--circle-button-bg-color);
  background: unset;
}

.circle-button:hover {
  border: var(--circle-button-hover-border);
  cursor: pointer;
}

.circle-button:active {
  border: var(--circle-button-active-border);

  background-color: var(--circle-button-active-bg-color);
}

.circle-button--minus {
  background: center/20px no-repeat url(~@/assets/images/minus_20_20.svg);
}
.circle-button--plus {
  background: center/20px no-repeat url(~@/assets/images/plus_20_20.svg);
}

.circle-button--small {
  width: 50px;
  height: 50px;
}

.circle-button--small.circle-button--minus,
.circle-button--small.circle-button--plus {
  background-size: 16px;
}

@media (max-width: 1200px) {
  /* BUTTON CIRCLE (+ / -) MOBILE */

  .circle-button--small {
    width: 60px;
    height: 60px;
  }

  .circle-button--small.circle-button--minus,
  .circle-button--small.circle-button--plus {
    background-size: 20px;
  }
}
</style>
