// Auth --> Login
export const ROUTE_LOGIN_NAME = "Login";
export const ROUTE_LOGIN_PATH = "/login";

// Auth --> Login --> Start
export const ROUTE_LOGIN_START_NAME = "LoginStart";
export const ROUTE_LOGIN_START_PATH = "";

// Auth --> Login --> Email
export const ROUTE_LOGIN_EMAIL_NAME = "LoginEmail";
export const ROUTE_LOGIN_EMAIL_PATH = "email";
