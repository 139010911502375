import authService from "@/services/authService";
import jwtService from "@/services/jwtService";
import { useUserStore } from "@/stores/user";
import { useUserProfileStore } from "@/stores/userProfile";
import { useUserWishlistStore } from "@/stores/userWishlist";
import { ref } from "vue";
import { onMounted } from "vue";

export default function usePiniaInitStoresDB() {
  const isStoresInited = ref(false);
  // Stores saved in db
  const user = useUserStore();
  const userProfile = useUserProfileStore();
  // const userResponses = useUserResponsesStore();
  const userWishlist = useUserWishlistStore();

  const initStores = async () => {
    try {
      console.log(">>>INIT_USER_STORES_FROM_DB START<<<");

      const accessToken = jwtService.getAccessTokenFromLS();
      if (accessToken === null) {
        throw new Error("No access token found in local storage.");
      }
      const jwtPayloadDecoded = jwtService.decodeJwtToPayload(accessToken);
      const { user_id, user_profile_id, user_wishlist_id } = jwtPayloadDecoded;
      const storesInitPromises = [
        user.initUser(user_id),
        userProfile.initUserProfile(user_profile_id),
        userWishlist.initUserWishlist(user_wishlist_id),
      ];
      await Promise.all(storesInitPromises);
    } catch (error) {
      // Logout user
      authService.logout();
      console.log(error);
    } finally {
      isStoresInited.value = true;
      console.log(">>>INIT_USER_STORES_FROM_DB END<<<");
    }
  };

  onMounted(() => {
    initStores();
  });

  return {
    isStoresInited,
  };
}
