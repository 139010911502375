import {
  ROUTE_LANDING_NANNY_CLIENT_NAME,
  ROUTE_LANDING_NANNY_CLIENT_PATH,
  ROUTE_LANDING_NANNY_CONTRACTOR_NAME,
  ROUTE_LANDING_NANNY_CONTRACTOR_PATH,
} from "@/router/constants";
import { defineAsyncComponent } from "vue";

const LandingNannyClient = () =>
  import("@/views/Landing/Nanny/Client/LandingNannyClient.vue");
const LandingNannyContractor = () =>
  import("@/views/Landing/Nanny/Contractor/LandingNannyContractor.vue");

const landingRoutes = [
  {
    path: ROUTE_LANDING_NANNY_CLIENT_PATH,
    name: ROUTE_LANDING_NANNY_CLIENT_NAME,
    component: LandingNannyClient,
    meta: {
      headerDesktop: defineAsyncComponent(() =>
        import("@/components/Header/HeaderDesktopLanding.vue")
      ),
      headerMobile: null,
    },
  },
  {
    path: ROUTE_LANDING_NANNY_CONTRACTOR_PATH,
    name: ROUTE_LANDING_NANNY_CONTRACTOR_NAME,
    component: LandingNannyContractor,
    meta: {
      headerDesktop: defineAsyncComponent(() =>
        import("@/components/Header/HeaderDesktopLanding.vue")
      ),
      headerMobile: null,
    },
  },
];

export default landingRoutes;
