<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
  >
    <path
      d="M13 7.83C13.85 7.53 14.53 6.85 14.83 6H18L15 13C15 14.66 16.57 16 18.5 16C20.43 16 22 14.66 22 13L19 6H21V4H14.83C14.42 2.83 13.31 2 12 2C10.69 2 9.58 2.83 9.17 4H3V6H5L2 13C2 14.66 3.57 16 5.5 16C7.43 16 9 14.66 9 13L6 6H9.17C9.47 6.85 10.15 7.53 11 7.83V19H2V21H22V19H13V7.83ZM20.37 13H16.63L18.5 8.64L20.37 13ZM7.37 13H3.63L5.5 8.64L7.37 13ZM12 6C11.45 6 11 5.55 11 5C11 4.45 11.45 4 12 4C12.55 4 13 4.45 13 5C13 5.55 12.55 6 12 6Z"
      fill="black"
    />
  </svg>
</template>

<style scoped>
.svg-icon path {
  fill: var(--default-font-color);
}
</style>
