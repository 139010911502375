import {
  ROUTE_ACCOUNT_PREMIUM_FAILURE_NAME,
  ROUTE_ACCOUNT_PREMIUM_FAILURE_PATH,
  ROUTE_ACCOUNT_PREMIUM_NAME,
  ROUTE_ACCOUNT_PREMIUM_PATH,
  ROUTE_ACCOUNT_PREMIUM_SUCCESS_NAME,
  ROUTE_ACCOUNT_PREMIUM_SUCCESS_PATH,
} from "@/router/constants";

const AccountPremium = () =>
  import("@/views/Account/Premium/AccountPremium.vue");
const AccountPremiumSuccess = () =>
  import("@/views/Account/Premium/AccountPremiumSuccess.vue");
const AccountPremiumFailure = () =>
  import("@/views/Account/Premium/AccountPremiumFailure.vue");

const accountPremiumRoutes = [
  {
    path: ROUTE_ACCOUNT_PREMIUM_PATH,
    name: ROUTE_ACCOUNT_PREMIUM_NAME,
    component: AccountPremium,
  },
  {
    path: ROUTE_ACCOUNT_PREMIUM_SUCCESS_PATH,
    name: ROUTE_ACCOUNT_PREMIUM_SUCCESS_NAME,
    component: AccountPremiumSuccess,
  },
  {
    path: ROUTE_ACCOUNT_PREMIUM_FAILURE_PATH,
    name: ROUTE_ACCOUNT_PREMIUM_FAILURE_NAME,
    component: AccountPremiumFailure,
  },
];

export default accountPremiumRoutes;
