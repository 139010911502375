const prepareApplicationNannyStoreParsed = (storeParsed) => {
  // TODO: check out the work of files in Gallery !
  return {
    ...storeParsed,
    // meta: {
    //   ...storeParsed.meta,
    //   refreshedAt: new Date(storeParsed.meta.refreshedAt),
    // },
    // schedule: {
    //   ...storeParsed.schedule,
    //   dateStart: new Date(storeParsed.schedule.dateStart),
    // },
    storeMeta: {
      ...storeParsed.storeMeta,
      watcherStopHandlers: [],
      error: null,
    },
  };
};

const applicationNannyService = {
  prepareApplicationNannyStoreParsed,
};

export default applicationNannyService;
