export * from "./accountPremium";

// Account
export const ROUTE_ACCOUNT_NAME = "Account";
export const ROUTE_ACCOUNT_PATH = "/account";

// Account --> Profile
export const ROUTE_ACCOUNT_PROFILE_NAME = "AccountProfile";
export const ROUTE_ACCOUNT_PROFILE_PATH = "profile";

// Account --> Responses
export const ROUTE_ACCOUNT_RESPONSES_NAME = "AccountResponses";
export const ROUTE_ACCOUNT_RESPONSES_PATH = "responses";

// Account --> Offers
export const ROUTE_ACCOUNT_OFFERS_NAME = "AccountOffers";
export const ROUTE_ACCOUNT_OFFERS_PATH = "offers";

// Account --> Applications
export const ROUTE_ACCOUNT_APPLICATIONS_NAME = "AccountApplications";
export const ROUTE_ACCOUNT_APPLICATIONS_PATH = "applications";

// Account --> Purchases
export const ROUTE_ACCOUNT_PURCHASES_NAME = "AccountPurchases";
export const ROUTE_ACCOUNT_PURCHASES_PATH = "purchases";
