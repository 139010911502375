// Asking about email to send password reset link
// Auth --> Password reset --> Send link
export const ROUTE_PASSWORD_RESET_FORGOT_PASSWORD_PATH = "/forgot-password";
export const ROUTE_PASSWORD_RESET_FORGOT_PASSWORD_NAME =
  "AuthPasswordResetForgotPassword";

// Password link sent, do you want to resend link?
// Auth --> Password reset --> Resend link
export const ROUTE_PASSWORD_RESET_RESEND_LINK_PATH =
  "/password-reset-link-sent";
export const ROUTE_PASSWORD_RESET_RESEND_LINK_NAME =
  "AuthPasswordResetResendLink";

// User lands on this view from password reset link
// Auth --> Password reset --> Update password ( processing -> Update password / Failure )
export const ROUTE_PASSWORD_RESET_UPDATE_PASSWORD_PATH = "/reset-password";
export const ROUTE_PASSWORD_RESET_UPDATE_PASSWORD_NAME =
  "AuthPasswordResetUpdatePassword";

// Auth --> Password reset --> Success
export const ROUTE_PASSWORD_RESET_SUCCESS_PATH = "/reset-password/success";
export const ROUTE_PASSWORD_RESET_SUCCESS_NAME = "AuthPasswordResetSuccess";

// Auth --> Password reset --> Error (wrong key / link expired)
export const ROUTE_PASSWORD_RESET_ERROR_PATH = "/reset-password/failure";
export const ROUTE_PASSWORD_RESET_ERROR_NAME = "AuthPasswordResetError";
