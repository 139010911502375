<template>
  <BaseButton
    :to="to"
    :disabled="isDisabled"
    :class="['button-tertiary', sizeClass]"
  >
    <slot>{{ label }}</slot>
  </BaseButton>
</template>

<script>
import BaseButton from "@/components/UI/Base/BaseButton.vue";
import { computed } from "vue";

export default {
  components: { BaseButton },

  props: {
    to: {
      type: [String, Object],
      required: false,
    },
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "medium",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const sizeClass = computed(() => {
      const { size } = props;
      if (size === "large") return "button-tertiary--large";
      if (size === "small") return "button-tertiary--small";
      if (size === "x-small") return "button-tertiary--x-small";

      return "";
    });

    return {
      sizeClass,
    };
  },
};
</script>

<style scoped>
/* Tertiary Buttons  */
.button-tertiary {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 10px;
  min-width: var(--button-width);
  width: max-content;
  height: var(--button-height);

  color: var(--button-font-color);
  font-family: var(--default-font-family);
  font-size: var(--default-font-size);
  font-weight: var(--button-font-weight);
  text-align: center;

  background-color: var(--button-tertiary-bg-color);
  border: var(--button-tertiary-border);
  box-sizing: border-box;
  border-radius: var(--button-border-radius);
}

.button-tertiary:hover {
  border: var(--buton-tertiary-hover-border);
  cursor: pointer;
}
.button-tertiary:active {
  border: var(--buton-tertiary-active-border);
  background-color: var(--buton-tertiary-active-bg-color);
}

.button-tertiary--large {
  width: var(--button-large-width);
  font-size: var(--button-large-font-size);
  height: var(--button-large-height);
}

.button-tertiary--small {
  height: var(--button-small-height);
  min-width: var(--button-width);
  font-size: var(--button-small-font-size);
}

.button-tertiary--x-small {
  height: var(--button-x-small-height);
  min-width: var(--button-width);
  font-size: var(--button-x-small-font-size);
}
</style>
