import { ref } from "vue";
import IMask from "imask/esm/imask"; // imports only factory

export default function useIMaskDOB() {
  const maskRef = ref({
    mask: "dd {/} `mm {/} `yyyy",
    lazy: true,
    overwrite: true,
    blocks: {
      dd: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        maxLength: 2,
        autofix: true, // bound value
        placeholderChar: "d",
      },
      mm: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        maxLength: 2,
        autofix: true, // bound value
        placeholderChar: "m",
      },
      yyyy: {
        mask: IMask.MaskedRange,
        from: 1900,
        to: 2100,
        maxLength: 4,
        autofix: true, // bound value
        placeholderChar: "y",
      },
    },
  });

  return {
    maskRef,
  };
}
