<template>
  <h2 class="h2">
    <slot>{{ text }}</slot>
  </h2>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.h2 {
  display: block;
  font: var(--h2--font);
  line-height: normal;
  text-align: left;
}
</style>
