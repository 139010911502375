<template>
  <!-- CHIPS CHECKBOXES -->
  <div class="checkbox-chips-container">
    <label
      v-for="option in options"
      :key="option.value || option.slug"
      class="checkbox-chip checkbox-chips-container__checkbox-chip"
    >
      <input
        v-model="result"
        @change="$emit('update:modelValue', result)"
        :value="option.value || option.slug"
        class="dot checkbox-chip__dot checkbox-chip__dot--hide"
        type="checkbox"
        :name="name"
      />
      <span class="text-wrapper checkbox-chip__text-wrapper">{{
        option.label
      }}</span>
    </label>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
export default {
  props: {
    modelValue: {
      type: [Array],
      default: () => [],
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      required: true,
      type: Array,
    },
  },
  setup(props) {
    const result = ref(props.modelValue);

    watch(
      () => props.modelValue,
      () => {
        result.value = props.modelValue;
      }
    );

    return {
      result,
    };
  },
};
</script>

<style scoped>
/* CHECKBOX CHIPS */

.checkbox-chips-container {
  width: 560px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.checkbox-chips-container__checkbox-chip {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 10px;
  border-radius: var(--button-border-radius);
}

.checkbox-chip__text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 var(--checkbox-chip-padding);
  min-width: fit-content;
  width: 100%;
  height: var(--checkbox-chip-height);

  color: var(--checkbox-chip-font-color);
  font-family: var(--default-font-family);
  font-size: var(--checkbox-chip-font-size);
  font-weight: var(--checkbox-chip-font-weight);
  text-align: center;

  background-color: var(--button-tertiary-bg-color);
  border: var(--button-tertiary-border);
  border-radius: var(--button-border-radius);
  box-sizing: border-box;
}

.checkbox-chip__text-wrapper:hover {
  border: var(--checkbox-chip-hover-border);
  padding: 0 calc(var(--checkbox-chip-padding) - 1px);
  cursor: pointer;
}

.checkbox-chip__dot--hide {
  display: none;
}

.checkbox-chip__dot:checked + .checkbox-chip__text-wrapper {
  border: var(--checkbox-chip-active-border);
  background-color: var(--checkbox-chip-active-bg-color);
  padding: 0 calc(var(--checkbox-chip-padding) - 1px);
}

/* CHECKBOX CHIPS */
@media (max-width: 1200px) {
  .checkbox-chips-container {
    width: 345px;
    gap: 15px;
  }
}
</style>
