// Account --> Premium
export const ROUTE_ACCOUNT_PREMIUM_NAME = "AccountPremium";
export const ROUTE_ACCOUNT_PREMIUM_PATH = "premium";

// Account --> Premium --> Success
export const ROUTE_ACCOUNT_PREMIUM_SUCCESS_NAME = "AccountPremiumSuccess";
export const ROUTE_ACCOUNT_PREMIUM_SUCCESS_PATH = "premium/success";

// Account --> Premium --> Failure
export const ROUTE_ACCOUNT_PREMIUM_FAILURE_NAME = "AccountPremiumFailure";
export const ROUTE_ACCOUNT_PREMIUM_FAILURE_PATH = "premium/failure";
