import { getOfferNanny } from "@/api/offerNanny";

const offerNannyService = {
  prepareOfferNannyStoreParsed(storeParsed) {
    // TODO: check out the work of files in photoMain and Gallery !
    return {
      ...storeParsed,
      // meta: {
      //   ...storeParsed.meta,
      //   refreshedAt: new Date(storeParsed.meta.refreshedAt),
      // },
      // schedule: {
      //   ...storeParsed.schedule,
      //   dateStart: new Date(storeParsed.schedule.dateStart),
      // },
      storeMeta: {
        ...storeParsed.storeMeta,
        watcherStopHandlers: [],
        error: null,
      },
    };
  },

  async getUserProfile(offer_id) {
    const offer = await getOfferNanny({
      offer_id,
      populatePath: "user_profile_id_populated",
    });
    return offer.user_profile_id_populated;
  },
};

export default offerNannyService;
