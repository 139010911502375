import {
  LOGIN_GOOGLE,
  LOGIN_LOCAL,
  PRE_REGISTER_LOCAL,
  REGISTER_GOOGLE,
  REGISTER_LOCAL,
} from "@/graphql/mutations/auth";
import { apolloClient } from "@/plugins/apollo";

const authApi = {
  async loginLocal(email, password) {
    const { data, errors } = await apolloClient.mutate({
      mutation: LOGIN_LOCAL,
      variables: {
        payload: {
          loginEmail: email,
          password: password,
        },
      },
    });

    if (errors && errors.length > 0) {
      errors.forEach((error) => console.log(error));
      throw new Error("Failed to login! Please try again.");
    }

    return data.loginLocal;
  },

  async loginGoogle(idToken) {
    const { data, errors } = await apolloClient.mutate({
      mutation: LOGIN_GOOGLE,
      variables: {
        payload: {
          idToken: idToken,
        },
      },
    });

    if (errors && errors.length > 0) {
      errors.forEach((error) => console.log(error));
      throw new Error("Failed to login! Please try again.");
    }

    return data.loginGoogle;
  },

  async preRegisterLocal({ loginEmail, password }) {
    const { data, errors } = await apolloClient.mutate({
      mutation: PRE_REGISTER_LOCAL,
      variables: {
        payload: {
          loginEmail,
          password,
        },
      },
    });

    if (errors && errors.length > 0) {
      errors.forEach((error) => console.log(error));
      throw new Error("Failed to register! Please try again.");
    }

    return data.preRegisterLocal;
  },

  async registerLocal(oneTimeCodeValue, userStore, userProfileStore) {
    const { data, errors } = await apolloClient.mutate({
      mutation: REGISTER_LOCAL,
      variables: {
        payloadAuth: {
          oneTimeCode: oneTimeCodeValue,
        },
        payloadUser: {
          role: userStore.role,
          userType: userStore.userType,
          accountType: userStore.accountType,
          websiteSettings: {
            language: userStore.websiteSettings.language,
            country: userStore.websiteSettings.country,
            city: userStore.websiteSettings.city,
          },
          phoneVerifAttemptsLeft: userStore.phoneVerifAttemptsLeft,
        },
        payloadUserProfile: {
          nameFirst: userProfileStore.nameFirst,
          nameLast: userProfileStore.nameLast,
          nameBusiness: userProfileStore.nameBusiness,
          zip: userProfileStore.zip,
          zipLocationPoint: userProfileStore.zipLocationPoint,
          sex: userProfileStore.sex,
          dob: userProfileStore.dob,
          phone: userProfileStore.phone,
          lastSeen: userProfileStore.lastSeen,
        },
      },
    });

    if (errors && errors.length > 0) {
      errors.forEach((error) => console.log(error));
      throw new Error("Failed to register! Please try again.");
    }

    return data.registerLocal;
  },

  async registerGoogle(idToken, userStore, userProfileStore) {
    const { data, errors } = await apolloClient.mutate({
      mutation: REGISTER_GOOGLE,
      variables: {
        payloadAuth: {
          idToken: idToken,
        },
        payloadUser: {
          role: userStore.role,
          userType: userStore.userType,
          accountType: userStore.accountType,
          websiteSettings: {
            language: userStore.websiteSettings.language,
            country: userStore.websiteSettings.country,
            city: userStore.websiteSettings.city,
          },
          phoneVerifAttemptsLeft: userStore.phoneVerifAttemptsLeft,
        },
        payloadUserProfile: {
          nameFirst: userProfileStore.nameFirst,
          nameLast: userProfileStore.nameLast,
          nameBusiness: userProfileStore.nameBusiness,
          zip: userProfileStore.zip,
          zipLocationPoint: userProfileStore.zipLocationPoint,
          sex: userProfileStore.sex,
          dob: userProfileStore.dob,
          phone: userProfileStore.phone,
          lastSeen: userProfileStore.lastSeen,
        },
      },
    });

    if (errors && errors.length > 0) {
      errors.forEach((error) => console.log(error));
      throw new Error("Failed to register! Please try again.");
    }

    return data.registerGoogle;
  },
};

export default authApi;
