<template>
  <slot v-if="isInitialized" />
  <div v-else>
    Loading... Loading... Loading... Loading... Loading... Loading... Loading...
  </div>
</template>

<script>
import { computed } from "vue";

import usePiniaPersistLS from "@/composables/usePiniaPersistLS";
import usePiniaInitStoresDB from "@/composables/usePiniaInitStoresDB";

export default {
  setup() {
    const { isPiniaStateInitedFromLS } = usePiniaPersistLS();
    const { isStoresInited } = usePiniaInitStoresDB();

    const isInitialized = computed(() => {
      return isPiniaStateInitedFromLS.value && isStoresInited.value;
    });

    return {
      isInitialized,
    };
  },
};
</script>
