<template>
  <input-text
    :type="inputType"
    :name="'password'"
    :placeholder="placeholder"
    :label="label"
  >
    <template #caption>
      <slot name="caption" />
    </template>
    <template #after-input>
      <input
        @change="passwordVisibilityToggler"
        type="checkbox"
        class="pswrd-toggler text-input__pswrd-toggler"
      />
    </template>
  </input-text>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    label: {
      type: String,
      default: "Haslo",
    },
    placeholder: {
      type: String,
      default: "Twoj haslo...",
    },
  },

  setup() {
    // Password visiility toggler
    let inputType = ref("password");
    const passwordVisibilityToggler = () => {
      if (inputType.value === "password") {
        inputType.value = "text";
      } else {
        inputType.value = "password";
      }
    };

    return {
      inputType,
      passwordVisibilityToggler,
    };
  },
};
</script>

<style scoped>
/* PASSWORD INPUT  */

.text-input__pswrd-toggler {
  position: absolute;
  top: 45px;
  right: 45px;
}
.text-input__pswrd-toggler:after {
  display: block;
  width: 35px;
  height: 30px;
  position: absolute;
  content: "";

  background: center no-repeat url(~@/assets/images/crossed-eye.svg);
}

@media (max-width: 1200px) {
  .text-input__pswrd-toggler {
    top: 42px;
  }
}
</style>
