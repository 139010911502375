<template>
  <InputTextPostfixPrefix
    v-model="fee"
    @focus="isError = false"
    @blur="checkError"
    :prefix="prefix"
    :postfix="'zł/godzinę'"
    :name="'hour_fee'"
    :placeholder="placeholder"
    :maxlength="3"
    :mask="maskRef"
    :small="true"
    :error="isError"
  />
</template>

<script>
import useIMaskNumber from "@/composables/imask/useIMaskNumber";
import { ref, computed } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

export default {
  props: {
    modelValue: String,
    prefix: {
      type: String,
      default: "Od",
    },
    placeholder: {
      type: String,
      default: "20",
    },
  },

  emits: ["update:modelValue", "update:isValidFee"],

  setup(props, { emit }) {
    const { maskRef } = useIMaskNumber({ min: 1, max: 999, scale: 0 });

    const fee = ref(props.modelValue);
    watch(fee, (newFee) => emit("update:modelValue", newFee));
    watch(
      () => props.modelValue,
      (newFee) => (fee.value = newFee)
    );

    const isValidFee = computed(() => {
      return !!fee.value;
    });
    watch(isValidFee, (newIsValid) => emit("update:isValidFee", newIsValid), {
      immediate: true,
    });

    // Check out errors
    const isError = ref(false);
    const checkError = () => {
      isError.value = !isValidFee.value;
    };

    return {
      maskRef,
      fee,
      isError,
      checkError,
      isValidFee,
    };
  },
};
</script>

<style scoped></style>
