<template>
  <BaseButton class="circle-attachment-button" :class="buttonClass" />
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const buttonClass = computed(() => {
      return {
        "circle-attachment-button--small": props.small,
      };
    });
    return {
      buttonClass,
    };
  },
};
</script>

<style scoped>
/* CIRCLE ATTACHMENT BUTTON  */

.circle-attachment-button {
  width: var(--button-width);
  height: var(--button-width);

  border-radius: 50%;
  border: var(--circle-attachment-button-border);
  box-shadow: var(--circle-attachment-button-primary-shadow);

  background: center/40px no-repeat
    url(~@/assets/images/plus_attach_primary.svg);
  background-color: var(--circle-attachment-button-primary-bg-color);

  cursor: pointer;
}

.circle-attachment-button--small {
  width: 75px;
  height: 75px;

  box-shadow: var(--circle-attachment-button-small-shadow);

  background: center/29px no-repeat url(~@/assets/images/plus_attach_small.svg);
  background-color: var(--circle-attachment-button-small-bg-color);
}

@media (max-width: 1200px) {
  /* CIRCLE ATTACHMENT BUTTON MOBILE */

  .circle-attachment-button--small {
    width: 79px;
    height: 79px;
  }
}
</style>
