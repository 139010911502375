<template>
  <!-- DROPDOWN SELECT INPUT -->
  <div
    class="text-input text-input--select-dropdown"
    :class="containerClass"
    :disabled="disabled"
  >
    <select
      @change="$emit('update:modelValue', $event.target.value)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      :value="modelValue"
      :name="name"
      :id="name"
      class="field text-input__field text-input__field--select-dropdown"
      :required="required"
      :autocomplete="autocomplete"
    >
      <option disabled value="">Wyberz</option>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
    <label v-if="label" :for="name" class="label text-input__label">
      <span v-if="asterisk" class="asterisk label__asterisk">*</span>
      {{ label }}
    </label>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    modelValue: {
      type: String,
    },
    name: String,
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    small: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: "on",
    },
    asterisk: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update:modelValue", "focus", "blur"],

  setup(props) {
    const containerClass = computed(() => {
      return {
        "text-input--small": props.small,
        "text-input--error": props.error,
        "text-input--success": props.success,
      };
    });

    return {
      containerClass,
    };
  },
};
</script>

<style scoped>
/* TEXT INPUT GENERAL  */

.text-input {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: var(--text-input--width);
}

.text-input--small {
  width: var(--text-input__small--width);
}

.text-input__label {
  width: fit-content;
  position: relative;
  order: -1;
  padding: 0 0 7px 10px;

  font: var(--input__label--font);

  cursor: pointer;
}

/* .text-input__label--hide {
  display: none;
} */

.text-input__caption {
  font: var(--text-input__caption--font);
  margin: 7px 10px 0 10px;
}

.label__asterisk {
  display: block;
  position: absolute;
  font-size: 26px;
  font-weight: 400;
  color: #fe881c;
  left: -7px;
  top: -3px;
}

/* .text-input__field:required + .text-input__label .label__asterisk {
  display: block;
} */

.text-input__field {
  height: var(--text-input__field--height);
  padding: 0 var(--text-input__field--padding) 2px
    var(--text-input__field--padding);

  font: var(--text-input__field--font);
  color: var(--text-input__field--font-color);

  border: var(--text-input__field--border);
  border-radius: var(--text-input__field--border-radius);
}

.text-input__field:hover {
  border: var(--text-input__field--hover--border);
  padding: 0 calc(var(--text-input__field--padding) - 1px) 2px
    calc(var(--text-input__field--padding) - 1px);
}

.text-input__field:focus {
  border: var(--text-input__field--focus--border);
  padding: 0 calc(var(--text-input__field--padding) - 1px) 2px
    calc(var(--text-input__field--padding) - 1px);
}

.text-input--error,
.text-input--error .text-input__field {
  border-color: rgba(195, 63, 63, 0.5);
  color: rgba(195, 63, 63, 1);
}

.text-input--error .text-input__field:hover {
  border-color: rgba(195, 63, 63, 0.5);
}
.text-input--error .text-input__field:focus {
  border-color: rgba(195, 63, 63, 0.8);
}

/* *Success and valid  */
.text-input--success,
.text-input--success .text-input__field {
  border-color: rgba(112, 164, 111, 0.5);
  color: rgba(112, 164, 111, 1);
}

.text-input--success .text-input__field:hover {
  border-color: rgba(112, 164, 111, 0.5);
}
.text-input--success .text-input__field:focus {
  border-color: rgba(112, 164, 111, 0.8);
}

.text-input__field:required:valid,
.text-input__field:required:valid ~ .text-input__label,
.text-input__field:required:valid ~ .text-input__caption {
  border-color: rgba(112, 164, 111, 0.5);
  color: rgba(112, 164, 111, 1);
}
.text-input__field:required:valid:hover,
.text-input__field:required:valid:hover ~ .text-input__label,
.text-input__field:required:valid:hover ~ .text-input__caption {
  border-color: rgba(112, 164, 111, 0.5);
}
.text-input__field:required:valid:focus,
.text-input__field:required:valid:focus ~ .text-input__label,
.text-input__field:required:valid:focus ~ .text-input__caption {
  border-color: rgba(112, 164, 111, 0.8);
}

/* * INPUT PLACEHOLDERS  */

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font: var(--text-input__placeholder--font);
  color: var(--text-input__placeholder--color);
  opacity: var(--text-input__placeholder--opacity);
}
::-moz-placeholder {
  /* Firefox 19+ */
  font: var(--text-input__placeholder--font);
  color: var(--text-input__placeholder--color);
  opacity: var(--text-input__placeholder--opacity);
}
:-ms-input-placeholder {
  /* IE 10+ */
  font: var(--text-input__placeholder--font);
  color: var(--text-input__placeholder--color);
  opacity: var(--text-input__placeholder--opacity);
}
:-moz-placeholder {
  /* Firefox 18- */
  font: var(--text-input__placeholder--font);
  color: var(--text-input__placeholder--color);
  opacity: var(--text-input__placeholder--opacity);
}

/* * INPUT PLACEHOLDERS END */

/* SELECT DROPDOWN (GENDER)  */
.text-input__field--select-dropdown {
  display: flex;
  align-items: center;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url(~@/assets/images/dropdown-arrow.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) calc(1em - 1px);

  cursor: pointer;
}
.text-input__field--select-dropdown:hover {
  background-position: calc(100% - 14px) calc(1em - 2px);
}
.text-input__field--select-dropdown:focus {
  background-position: calc(100% - 14px) calc(1em - 2px);
}

/* Disabled style */
.text-input[disabled="true"] {
  pointer-events: none !important;
}
.text-input[disabled="true"] .text-input__field {
  border: unset !important;
  pointer-events: none !important;
  background-color: transparent !important;
  opacity: 0.5 !important;
  font-weight: 300 !important;
  background-image: unset;
}
</style>
