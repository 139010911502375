import gql from "graphql-tag";

export const CREATE_OFFER_NANNY = gql`
  mutation CreateOfferNanny($payload: CreateOfferNannyInput!) {
    createOfferNanny(payload: $payload) {
      _id
    }
  }
`;

export const UPDATE_OFFER_NANNY = gql`
  mutation UpdateOfferNanny($id: ID!, $payload: UpdateOfferNannyInput!) {
    updateOfferNanny(_id: $id, payload: $payload) {
      _id
    }
  }
`;

export const DELETE_OFFER_NANNY = gql`
  mutation DeleteOfferNanny($id: ID!) {
    deleteOfferNanny(_id: $id) {
      _id
    }
  }
`;

export const VISIT_OFFER_NANNY = gql`
  mutation VisitApplicationNanny($id: ID!, $visitorUserId: ID) {
    visitOfferNanny(_id: $id, visitor_user_id: $visitorUserId)
  }
`;

export const UPLOAD_OFFER_NANNY_PHOTO_MAIN = gql`
  mutation UploadOfferNannyPhotoMain(
    $offerId: ID!
    $userId: ID!
    $fileCropped: Upload!
    $fileOriginal: Upload
  ) {
    uploadOfferNannyPhotoMain(
      offer_id: $offerId
      user_id: $userId
      fileCropped: $fileCropped
      fileOriginal: $fileOriginal
    )
  }
`;
