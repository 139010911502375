import { ROUTE_LIST_NANNY_PATH } from "@/router/constants";
import authService from "@/services/authService";
import lStorageService from "@/services/localStorageService";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

export default function useAuth() {
  const userStore = useUserStore();
  const router = useRouter();
  const nextLoginRoutePath =
    userStore.routePathBeforeLogin || ROUTE_LIST_NANNY_PATH;
  const { isAuth } = storeToRefs(userStore);

  const AUTH_ID_KEY = "AUTH_ID";
  const AUTH_TTL = 24 * 36e5; // ttl is 24 hours
  const AUTH_STRATEGY_KEY = "AUTH_STRATEGY";

  const handleLoginLocal = async (email, password) => {
    try {
      await authService.loginLocal(email, password);
      router.push(nextLoginRoutePath);
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  const handleLoginGoogle = async (idToken) => {
    try {
      await authService.loginGoogle(idToken);
      router.push(nextLoginRoutePath);
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  const handleGoogleResponseOnPreRegistration = (res) => {
    const { credential: idToken } = res;
    // save google idToken token to localStorage
    lStorageService.setWithExpiry(AUTH_ID_KEY, idToken, AUTH_TTL);
    // save strategy type ('google') to localStorage
    lStorageService.setWithExpiry(AUTH_STRATEGY_KEY, "google", AUTH_TTL);
  };

  const handleLocalPreRegistration = async (loginEmail, password) => {
    const { oneTimeCode } = await authService.preRegisterLocal(
      loginEmail,
      password
    );
    // save oneTimeCode for local strategy to localStorage
    lStorageService.setWithExpiry(AUTH_ID_KEY, oneTimeCode, AUTH_TTL);
    // save strategy type ('local') to localStorage
    lStorageService.setWithExpiry(AUTH_STRATEGY_KEY, "local", AUTH_TTL);
  };

  const handleRegistration = async () => {
    const strategy = getAuthStrategy();

    if (!strategy) {
      throw new Error("No actual auth strategy found");
    }

    if (strategy === "google") {
      return _handleRegistrationGoogle();
    }

    if (strategy === "local") {
      return _handleRegistrationLocal();
    }

    throw new Error("Wrong registration strategy");
  };

  const handleLogout = () => {
    authService.logout();
  };

  const getAuthStrategy = () => {
    return lStorageService.getWithExpiry(AUTH_STRATEGY_KEY);
  };

  const _handleRegistrationGoogle = async () => {
    const idToken = lStorageService.getWithExpiry(AUTH_ID_KEY);
    if (!idToken) {
      throw new Error("No google idToken found");
    }
    await authService.registerGoogle(idToken);
  };

  const _handleRegistrationLocal = async () => {
    const oneTimeCode = lStorageService.getWithExpiry(AUTH_ID_KEY);
    if (!oneTimeCode) {
      throw new Error("No local oneTimeCode found");
    }
    if (!_validateOneTimeCode(oneTimeCode)) {
      throw new Error("oneTimeCode is expired");
    }
    await authService.registerLocal(oneTimeCode.value);
  };

  const _validateOneTimeCode = (oneTimeCode) => {
    const currentTime = new Date().getTime();
    return currentTime < parseInt(oneTimeCode.expiryTimeString);
  };

  return {
    isAuth,
    handleLoginLocal,
    handleLoginGoogle,
    handleGoogleResponseOnPreRegistration,
    handleLocalPreRegistration,
    handleRegistration,
    handleLogout,
    getAuthStrategy,
  };
}
