import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/router";
import { createPinia } from "pinia";

import layouts from "@/layouts";
import UIBase from "@/components/UI/Base";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { apolloClient } from "./plugins/apollo";

const app = createApp(App);
export const pinia = createPinia();

layouts.forEach((layout) => {
  app.component(layout.name, layout.component);
});

UIBase.forEach((ui) => {
  app.component(ui.name, ui.component);
});

app
  .provide(DefaultApolloClient, apolloClient)
  .use(pinia)
  .use(router)
  .mount("#app");
