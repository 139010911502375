import { ROUTE_LOGOUT_NAME, ROUTE_LOGOUT_PATH } from "@/router/constants";

const AuthLogout = () => import("@/views/Auth/AuthLogout.vue");

const logoutRoute = {
  path: ROUTE_LOGOUT_PATH,
  name: ROUTE_LOGOUT_NAME,
  component: AuthLogout,
};

export default logoutRoute;
