import gql from "graphql-tag";

export const CREATE_APPLICATION_NANNY = gql`
  mutation CreateApplicationNanny($payload: CreateApplicationNannyInput!) {
    createApplicationNanny(payload: $payload) {
      _id
    }
  }
`;

export const UPDATE_APPLICATION_NANNY = gql`
  mutation UpdateApplicationNanny(
    $id: ID!
    $payload: UpdateApplicationNannyInput!
  ) {
    updateApplicationNanny(_id: $id, payload: $payload) {
      _id
    }
  }
`;

export const DELETE_APPLICATION_NANNY = gql`
  mutation DeleteApplicationNanny($id: ID!) {
    deleteApplicationNanny(_id: $id) {
      _id
    }
  }
`;

export const VISIT_APPLICATION_NANNY = gql`
  mutation VisitApplicationNanny($id: ID!, $visitorUserId: ID) {
    visitApplicationNanny(_id: $id, visitor_user_id: $visitorUserId)
  }
`;

export const UPLOAD_APPLICATION_NANNY_PHOTO_MAIN = gql`
  mutation UploadApplicationNannyPhotoMain(
    $applicationId: ID!
    $userId: ID!
    $fileCropped: Upload!
    $fileOriginal: Upload
  ) {
    uploadApplicationNannyPhotoMain(
      application_id: $applicationId
      user_id: $userId
      fileOriginal: $fileOriginal
      fileCropped: $fileCropped
    )
  }
`;

export const PUSH_IMAGE_TO_APPLICATION_NANNY_PHOTO_GALLERY = gql`
  mutation pushImageToApplicationNannyPhotoGallery(
    $applicationId: ID!
    $userId: ID!
    $file: Upload!
  ) {
    pushImageToApplicationNannyPhotoGallery(
      application_id: $applicationId
      user_id: $userId
      file: $file
    )
  }
`;

export const PULL_IMAGE_FROM_APPLICATION_NANNY_PHOTO_GALLERY = gql`
  mutation pullImageFromApplicationNannyPhotoGallery(
    $applicationId: ID!
    $filename: String!
  ) {
    pullImageFromApplicationNannyPhotoGallery(
      application_id: $applicationId
      filename: $filename
    )
  }
`;
