<template>
  <input-text
    @update:model-value="$emit('update:address', $event)"
    :model-value="address"
    @focus="isFocused = true"
    @blur="isFocused = false"
    :geo="true"
    :is-suggestions="(suggestions.length && suggestions.length > 0) === true"
    :name="'geo'"
    :placeholder="'Type your address here...'"
    :autocomplete="'off'"
    :spellcheck="false"
  >
    <template #after-input>
      <div
        @click="$emit('autoGeolocation')"
        class="geo-auto-detect text-input__geo-auto-detect"
      ></div>
      <transition>
        <ul
          v-show="isFocused && suggestions.length"
          class="suggestions text-input__suggestions"
        >
          <li
            v-for="suggestion in suggestions"
            :key="suggestion.label"
            @click="$emit('setSuggestion', suggestion)"
            class="item suggestions__item"
          >
            {{ suggestion.label }}
          </li>
        </ul>
      </transition>
    </template>
  </input-text>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    address: String,
    suggestions: {
      type: Array,
      default: () => [],
    },
  },

  emits: ["update:address", "setSuggestion", "autoGeolocation"],

  setup() {
    let isFocused = ref(false);
    return {
      isFocused,
    };
  },
};
</script>

<style scoped>
.v-leave-active,
.v-enter-active {
  transition: opacity 0.25s, border-radius 0.25s;
}
.v-leave-to,
.v-enter-from {
  opacity: 0;
  border-radius: 20px !important;
}
.text-input__suggestions {
  margin: -2px 0 0 0;
  padding: 0;

  position: absolute;
  top: var(--text-input__field--height);

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  border: var(--text-input__field--border);
  border-radius: 0 0 var(--text-input__field--border-radius)
    var(--text-input__field--border-radius);

  background-color: #fff;
  overflow: hidden;

  z-index: 9;
}

.text-input--geo .text-input__field:hover ~ .text-input__suggestions {
  border: var(--text-input__field--hover--border);
}

.text-input--geo .text-input__field:focus ~ .text-input__suggestions {
  border: var(--text-input__field--focus--border);
}

.suggestions__item {
  width: 100%;
  min-height: var(--text-input__field--height);
  padding: 7px var(--text-input__field--padding);

  display: flex;
  align-items: center;

  border-bottom: var(--suggestions__item--border-bottom);
}

.suggestions__item:last-child {
  border-bottom: unset;
}

.suggestions__item:hover {
  background-color: var(--suggestions__item--hover--bg-color);
  cursor: pointer;
}

.text-input__geo-auto-detect {
  width: 21px;
  height: var(--text-input__field--height);
  position: absolute;
  right: 14px;
  display: block;
  background: center no-repeat url(~@/assets/images/geo-detect-icon.svg);
  cursor: pointer;
}
</style>
