<template>
  <div class="layout-view-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.layout-view-wrapper {
  min-height: var(--layout-view-wrapper--min-height);
  width: var(--layout-view-wrapper--width);
  margin: var(--layout-view-wrapper--margin);
  padding: var(--layout-view-wrapper--padding);

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;

  background-color: var(--layout-view-wrapper--bg-color);
  border-radius: var(--layout-view-wrapper--border-radius);
}
</style>
