<template>
  <!-- ROUND CHECKBOXES (POMOC DOMOWA)-->
  <div class="round-checkbox-container">
    <label
      v-for="option in options"
      :key="option.value || option.slug"
      class="checkbox-wrapper round-checkbox-container__checkbox-wrapper"
    >
      <input
        v-model="result"
        @change="$emit('update:modelValue', result)"
        type="checkbox"
        :name="name"
        :value="option.value || option.slug"
        class="dot checkbox-wrapper__dot checkbox-wrapper__dot--hide"
      />
      <div
        class="circle-icon checkbox-wrapper__circle-icon"
        :style="`background-image: url(${option.iconSrc})`"
      ></div>
      <span class="text-wrapper checkbox-wrapper__text-wrapper">{{
        option.label
      }}</span>
    </label>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";

export default {
  props: {
    modelValue: {
      type: [Array],
      default: () => [],
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const result = ref(props.modelValue);

    watch(
      () => props.modelValue,
      () => {
        result.value = props.modelValue;
      }
    );

    const circleIconClasses = computed(() => {
      return {
        laundry: "checkbox-wrapper__circle-icon--laundry",
        ironing: "checkbox-wrapper__circle-icon--ironing",
        cleaning: "checkbox-wrapper__circle-icon--cleaning",
        maintaining_cleaniness:
          "checkbox-wrapper__circle-icon--maintaining-cleaniness",
        cooking: "checkbox_wrapper__circle-icon--cooking",
        pet_care: "checkbox_wrapper__circle-icon--pet-care",
        plant_care: "checkbox_wrapper__circle-icon--plant-care",
        shopping: "checkbox-wrapper__circle-icon--shopping",
      };
    });

    return {
      result,
      circleIconClasses,
    };
  },
};
</script>

<style scoped>
/* ROUND CHECKBOX with ICON */

.round-checkbox-container {
  width: 560px;
  display: flex;
  gap: 20px 0;
  flex-wrap: wrap;
  justify-content: space-between;
}

.round-checkbox-container__checkbox-wrapper {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  /* todo border-radius: var(--button-border-radius); */
}

.checkbox-wrapper__circle-icon {
  width: var(--round-checkbox-circle-radius);
  height: var(--round-checkbox-circle-radius);
  display: flex;
  align-items: center;
  justify-content: center;

  background: center/35% no-repeat;
  border-radius: 50%;
  border: var(--round-checkbox-border);
}

.checkbox-wrapper__circle-icon:hover {
  border: var(--round-checkbox-hover-border);
  cursor: pointer;
}

.checkbox-wrapper__dot:checked ~ .checkbox-wrapper__circle-icon {
  border: var(--round-checkbox-active-border);
  background-color: var(--round-checkbox-active-bg-color);
}
/* * home care icons */
/* .checkbox-wrapper__circle-icon--laundry {
  background: center/35% no-repeat
    url(~@/assets/images/icons/home-care/basket_1f9fa.png);
}
.checkbox-wrapper__circle-icon--ironing {
  background: center/35% no-repeat
    url(~@/assets/images/icons/home-care/necktie_1f454.png);
}
.checkbox-wrapper__circle-icon--cleaning {
  background: center/40% no-repeat
    url(~@/assets/images/icons/home-care/broom_1f9f9.png);
}
.checkbox-wrapper__circle-icon--maintaining-cleaniness {
  background: center/35% no-repeat
    url(~@/assets/images/icons/home-care/sponge_1f9fd.png);
}
.checkbox_wrapper__circle-icon--cooking {
  background: center/35% no-repeat
    url(~@/assets/images/icons/home-care/cooking_1f373.png);
}
.checkbox_wrapper__circle-icon--pet-care {
  background: center/35% no-repeat
    url(~@/assets/images/icons/home-care/paw-prints_1f43e.png);
}
.checkbox_wrapper__circle-icon--plant-care {
  background: center/35% no-repeat
    url(~@/assets/images/icons/home-care/potted-plant_1fab4.png);
}
.checkbox-wrapper__circle-icon--shopping {
  background: center/35% no-repeat
    url(~@/assets/images/icons/home-care/shopping-cart_1f6d2.png);
} */
/* * home care icons END */

.checkbox-wrapper__text-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  margin-top: 9px;
  max-width: var(--round-checkbox-circle-radius);
  height: fit-content;

  color: var(--round-checkbox-font-color);
  font-family: var(--default-font-family);
  font-size: var(--round-checkbox-font-size);
  font-weight: var(--round-checkbox-font-weight);
  text-align: center;
}
.checkbox-wrapper__text-wrapper:hover {
  cursor: pointer;
}

.checkbox-wrapper__dot--hide {
  display: none;
}

.checkbox-wrapper__dot:checked ~ .checkbox-wrapper__text-wrapper {
  font-weight: var(--round-checkbox-active-font-weight);
}

/* ROUND CHECKBOX with ICON MOBILE */
@media (max-width: 1200px) {
  .round-checkbox-container {
    width: 345px;
    gap: 15px 0;
  }

  .checkbox-wrapper__text-wrapper {
    margin-top: 6px;
  }
}
</style>
