import {
  ROUTE_ACCOUNT_APPLICATIONS_NAME,
  ROUTE_ACCOUNT_APPLICATIONS_PATH,
  ROUTE_ACCOUNT_OFFERS_NAME,
  ROUTE_ACCOUNT_OFFERS_PATH,
  ROUTE_ACCOUNT_PROFILE_NAME,
  ROUTE_ACCOUNT_PROFILE_PATH,
  ROUTE_ACCOUNT_PURCHASES_NAME,
  ROUTE_ACCOUNT_PURCHASES_PATH,
  ROUTE_ACCOUNT_RESPONSES_NAME,
  ROUTE_ACCOUNT_RESPONSES_PATH,
  ROUTE_MOBILE_MENU_NAME,
  ROUTE_MOBILE_MENU_PATH,
} from "@/router/constants";
import accountPremiumRoutes from "@/router/routes/account/accountPremium";

// Mobile specific
const AccountMobileMenu = () => import("@/views/Account/AccountMobileMenu.vue");
const Account = () => import("@/views/Account/Account.vue");
const AccountResponses = () => import("@/views/Account/AccountResponses.vue");
const AccountOffers = () => import("@/views/Account/AccountOffers.vue");
const AccountApplications = () =>
  import("@/views/Account/AccountApplications.vue");
const AccountProfile = () =>
  import("@/views/Account/Profile/AccountProfile.vue");
const AccountPurchases = () => import("@/views/Account/AccountPurchases.vue");

export default [
  // Account --> Mobile specific
  {
    path: ROUTE_MOBILE_MENU_PATH,
    name: ROUTE_MOBILE_MENU_NAME,
    component: AccountMobileMenu,
  },
  // Account
  {
    path: "/account",
    name: "Account",
    component: Account,
    redirect: { name: "AccountProfile" },
    meta: {
      authGuard: true,
    },
    children: [
      {
        path: ROUTE_ACCOUNT_PROFILE_PATH,
        name: ROUTE_ACCOUNT_PROFILE_NAME,
        component: AccountProfile,
      },
      {
        path: ROUTE_ACCOUNT_RESPONSES_PATH,
        name: ROUTE_ACCOUNT_RESPONSES_NAME,
        component: AccountResponses,
      },
      {
        path: ROUTE_ACCOUNT_OFFERS_PATH,
        name: ROUTE_ACCOUNT_OFFERS_NAME,
        component: AccountOffers,
      },
      {
        path: ROUTE_ACCOUNT_APPLICATIONS_PATH,
        name: ROUTE_ACCOUNT_APPLICATIONS_NAME,
        component: AccountApplications,
      },
      {
        path: ROUTE_ACCOUNT_PURCHASES_PATH,
        name: ROUTE_ACCOUNT_PURCHASES_NAME,
        component: AccountPurchases,
      },
      ...accountPremiumRoutes,
    ],
  },
];
