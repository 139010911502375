import { pinia } from "@/main";
import applicationNannyService from "@/services/applicationNanny";
import listStoreService from "@/services/listStore";
import offerNannyService from "@/services/offerNanny";
import piniaService from "@/services/piniaService";
import {
  APPLICATION_NANNY_STORE_NAME,
  useApplicationNannyStore,
} from "@/stores/applicationNanny";
import { LIST_STORE_NAME, useListStore } from "@/stores/list";
import {
  OFFER_NANNY_STORE_NAME,
  useOfferNannyStore,
} from "@/stores/offerNanny";
import { onMounted, ref, watch } from "vue";

export default function usePiniaPersistLS() {
  // Local stores
  const list = useListStore();
  const applicationNanny = useApplicationNannyStore();
  // const applicationNannyRecommendation =
  //   useApplicationNannyRecommendationStore();
  const offerNanny = useOfferNannyStore();

  const isPiniaStateInitedFromLS = ref(false);

  watch(
    pinia.state,
    (state) => {
      if (!isPiniaStateInitedFromLS.value) return;
      console.log("WATCHER PINIA -> LS");
      piniaService.setStateToLS(state);
    },
    { deep: true }
  );

  const initStoresFromLS = () => {
    try {
      console.log("GET_PINIA_STATE_FROM_LS");
      const piniaStateLS = piniaService.getStateFromLS();

      if (piniaStateLS === null) return;

      const isStateActual = piniaService.checkStateVersion();
      if (isStateActual === false) {
        console.log("CHECK_FAILED");
        piniaService.removeStateFromLS();
        return;
      }

      console.log("CHECK_PASSED -> SET_PINIA_STATE_FROM_LS");

      const {
        [APPLICATION_NANNY_STORE_NAME]: applicationNannyStoreParsed,
        [OFFER_NANNY_STORE_NAME]: offerNannyStoreParsed,
        [LIST_STORE_NAME]: listStoreParsed,
      } = JSON.parse(piniaStateLS);

      if (applicationNannyStoreParsed !== undefined) {
        const applicationNannyStore =
          applicationNannyService.prepareApplicationNannyStoreParsed(
            applicationNannyStoreParsed
          );
        applicationNanny.$patch(applicationNannyStore);
      }

      if (offerNannyStoreParsed !== undefined) {
        const offerNannyStore = offerNannyService.prepareOfferNannyStoreParsed(
          offerNannyStoreParsed
        );
        offerNanny.$patch(offerNannyStore);
      }

      if (listStoreParsed !== undefined) {
        const listStore = listStoreService.prepareListStoreParsed(
          listStoreParsed,
          { ...list }
        );

        console.log({ listStore: listStore });

        list.$patch(listStore);
      }
    } finally {
      isPiniaStateInitedFromLS.value = true;
      console.log(">>>INIT_PINIA_STATE_FROM_LS END<<<");
    }
  };

  onMounted(() => {
    console.log(">>>INIT_PINIA_STATE_FROM_LS START<<<");
    initStoresFromLS();
  });

  return {
    isPiniaStateInitedFromLS,
  };
}
