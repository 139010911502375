import { ref, computed } from "vue";
import { addEventListenerToDocumentOnce } from "@/helpers";

export default function useInputNumberSpinner({
  currentValueRef,
  max = null,
  min = null,
}) {
  const changedValue = ref(null);
  const isDisabledButtonMinus = computed(() => {
    if (typeof min === "number") {
      return currentValueRef.value <= min;
    } else return false;
  });
  const isDisabledButtonPlus = computed(() => {
    if (typeof max === "number") {
      return currentValueRef.value >= max;
    } else return false;
  });

  const emitIncrement = () => {
    emitNewInputNumberValue({
      currentValue: currentValueRef.value,
      step: 1,
      buttonDisabledRef: isDisabledButtonPlus,
      mouseDownSetInterval: true,
    });
  };

  const emitDecrement = () => {
    emitNewInputNumberValue({
      currentValue: currentValueRef.value,
      step: -1,
      buttonDisabledRef: isDisabledButtonMinus,
      mouseDownSetInterval: true,
    });
  };

  const intervalId = ref(null);
  const timeoutId = ref(null);

  const stopInterval = () => {
    clearTimeout(timeoutId.value);
    clearInterval(intervalId.value);
    intervalId.value = null;
    timeoutId.value = null;
  };

  // Change current value by Step
  const emitNewInputNumberValue = ({
    currentValue,
    step,
    buttonDisabledRef = { value: false },
    mouseDownSetInterval = false,
  }) => {
    // Solving issue when mouseup not fired out of buttons
    addEventListenerToDocumentOnce("mouseup", stopInterval);

    // Ensure button is enabled
    if (!buttonDisabledRef.value) {
      //Change current value by Step
      currentValue = currentValue + step;
      changedValue.value = currentValue;
      if (mouseDownSetInterval) {
        timeoutId.value = setTimeout(() => {
          // setIncrementInterval for continious value change
          setIncrementInterval();
        }, 500);
      }
    }

    const setIncrementInterval = () => {
      if (intervalId.value === null) {
        intervalId.value = setInterval(() => {
          if (!buttonDisabledRef.value) {
            currentValue = currentValue + step;
            changedValue.value = currentValue;
          }
        }, 100);
      }
    };
  };

  return {
    changedValue,
    isDisabledButtonMinus,
    isDisabledButtonPlus,
    emitIncrement,
    emitDecrement,
  };
}
