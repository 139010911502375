import {
  ROUTE_PASSWORD_RESET_ERROR_NAME,
  ROUTE_PASSWORD_RESET_ERROR_PATH,
  ROUTE_PASSWORD_RESET_FORGOT_PASSWORD_NAME,
  ROUTE_PASSWORD_RESET_FORGOT_PASSWORD_PATH,
  ROUTE_PASSWORD_RESET_RESEND_LINK_NAME,
  ROUTE_PASSWORD_RESET_RESEND_LINK_PATH,
  ROUTE_PASSWORD_RESET_SUCCESS_NAME,
  ROUTE_PASSWORD_RESET_SUCCESS_PATH,
  ROUTE_PASSWORD_RESET_UPDATE_PASSWORD_NAME,
  ROUTE_PASSWORD_RESET_UPDATE_PASSWORD_PATH,
} from "@/router/constants";

const AuthPasswordResetForgotPassword = () =>
  import("@/views/Auth/PasswordReset/AuthPasswordResetForgotPassword.vue");
const AuthPasswordResetResendLink = () =>
  import("@/views/Auth/PasswordReset/AuthPasswordResetResendLink.vue");
const AuthPasswordResetUpdatePassword = () =>
  import("@/views/Auth/PasswordReset/AuthPasswordResetUpdatePassword.vue");
const AuthPasswordResetSuccess = () =>
  import("@/views/Auth/PasswordReset/AuthPasswordResetSuccess.vue");
const AuthPasswordResetError = () =>
  import("@/views/Auth/PasswordReset/AuthPasswordResetError.vue");

const passwordResetRoutes = [
  {
    // Asking about email to send password reset link
    path: ROUTE_PASSWORD_RESET_FORGOT_PASSWORD_PATH,
    name: ROUTE_PASSWORD_RESET_FORGOT_PASSWORD_NAME,
    component: AuthPasswordResetForgotPassword,
  },
  {
    // Password link sent, do you want to resend link?
    path: ROUTE_PASSWORD_RESET_RESEND_LINK_PATH,
    name: ROUTE_PASSWORD_RESET_RESEND_LINK_NAME,
    component: AuthPasswordResetResendLink,
  },
  {
    // User lands on this view from password reset link
    path: ROUTE_PASSWORD_RESET_UPDATE_PASSWORD_PATH,
    name: ROUTE_PASSWORD_RESET_UPDATE_PASSWORD_NAME,
    component: AuthPasswordResetUpdatePassword,
  },
  {
    // Auth --> Password reset --> Success
    path: ROUTE_PASSWORD_RESET_SUCCESS_PATH,
    name: ROUTE_PASSWORD_RESET_SUCCESS_NAME,
    component: AuthPasswordResetSuccess,
  },
  {
    // Auth --> Password reset --> Error (wrong key / link expired)
    path: ROUTE_PASSWORD_RESET_ERROR_PATH,
    name: ROUTE_PASSWORD_RESET_ERROR_NAME,
    component: AuthPasswordResetError,
  },
];

export default passwordResetRoutes;
