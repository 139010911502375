import gql from "graphql-tag";

export const CREATE_USER_WISHLIST = gql`
  mutation CreateUserWishlist($payload: CreateUserWishlistInput!) {
    createUserWishlist(payload: $payload) {
      _id
    }
  }
`;

export const UPDATE_USER_WISHLIST = gql`
  mutation UpdateUserWishlist($id: ID!, $payload: UpdateUserWishlistInput!) {
    updateUserWishlist(_id: $id, payload: $payload) {
      _id
    }
  }
`;
