<template>
  <input-text
    :type="'text'"
    :name="name"
    :label="prefix"
    :class="'text-input--prefix'"
    :placeholder="placeholder"
    :autocomplete="'off'"
  >
    <template #after-input>
      <label :for="name" class="postfix text-input__postfix">
        {{ postfix }}
      </label>
    </template>
  </input-text>
</template>

<script>
export default {
  props: {
    prefix: {
      type: String,
      default: "Do",
    },
    postfix: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
/* For styles loook into InputText < style > block */

.text-input__postfix {
  position: absolute;
  right: var(--text-input__field--padding);
  width: 1;

  font: var(--text-input__postfix--font);
}
</style>
