import { prepareFullName, getSlugsFromDict } from "@/helpers";
import useOfferNannyDictionary from "@/composables/useOfferNannyDictionary";
import { haversine } from "./haversine";
import { useUserProfileStore } from "@/stores/userProfile";

const { DICTIONARY } = useOfferNannyDictionary();

const prepareGeoLabel = ({ city, distanceKm }) => {
  if (typeof distanceKm !== "number") {
    throw Error("Distance is not a Number!");
  }
  if (distanceKm < 1) {
    distanceKm = "<1 km";
  } else {
    distanceKm = distanceKm.toFixed(0) + " km";
  }
  return city + ", " + distanceKm;
};

const getDateStart = (dateStart) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const nowUtcMs = Date.now();

  const stDayYear = dateStart.getFullYear();
  const stDayMonth = dateStart.getMonth();
  const stDayDay = dateStart.getDate();

  const startUtcMs = Date.UTC(stDayYear, stDayMonth, stDayDay);

  const diffMs = startUtcMs - nowUtcMs;
  const diffDays = Math.floor(diffMs / _MS_PER_DAY);

  const dateString = `${stDayDay.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}.${(stDayMonth + 1).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}.${stDayYear}`;

  const dateLabel = diffDays > 0 ? dateString : "niezwłocznie";

  return {
    dateUTC: startUtcMs,
    string: dateString,
    label: dateLabel,
    daysLeftBeforeStart: diffDays,
  };
};

const prepareKids = (kids) => {
  let readyKids = [];
  kids.forEach((kid) => {
    readyKids.push({
      ...DICTIONARY.kids.sex[kid.sex],
      label: kid.age === 0 ? `<1 roku` : `${kid.age} lata`,
    });
  });
  return readyKids;
};

export const prepareOfferNannyInstance = (instance) => {
  return {
    _id: instance._id,
    get id() {
      return this._id;
    },
    user_id: instance.user_id,
    user_profile_id: instance.user_profile_id,
    user_profile_id_populated: {
      _id: instance.user_profile_id_populated?._id,
      user_id: instance.user_profile_id_populated?.user_id,
      nameFirst: instance.user_profile_id_populated?.nameFirst,
      get nameFull() {
        if (this.nameFirst == null) {
          return null;
        }

        return prepareFullName({
          fullName: this.nameFirst,
        });
      },
      nameBusiness: instance.user_profile_id_populated?.nameBusiness,
      zip: instance.user_profile_id_populated?.zip,
      lastSeen: instance.user_profile_id_populated?.lastSeen, // Date
    },
    response_ids: instance.response_ids,
    // response_ids_populated: [],
    // outgoing_response_ids_populated: [],
    meta: {
      visitor_user_ids: instance.meta.visitor_user_ids,
      visitsAll: instance.meta.visitsAll,
      visitsUnique: instance.meta.visitsUnique,
      clicksCtaUnique: instance.meta.clicksCtaUnique,
      status: instance.meta.status,
      createdAt: instance.meta.createdAt,
      refreshedAt: instance.meta.refreshedAt,
      get actuality() {
        const todayDate = new Date();
        todayDate.setHours(0, 0, 0);
        const todayInTime = todayDate.getTime();
        const refreshedDate = new Date(this.refreshedAt);
        refreshedDate.setHours(0, 0, 0);
        const refreshedInTime = refreshedDate.getTime();
        const adAgeInDays = Math.round(
          (todayInTime - refreshedInTime) / (1000 * 3600 * 24)
        );

        if (adAgeInDays === 0) {
          return { slug: "actual", label: "dzisiaj" };
        }
        if (adAgeInDays === 1) {
          return { slug: "actual", label: "wczoraj" };
        }
        if (adAgeInDays === 2) {
          return { slug: "actual", label: "przedwczoraj" };
        }
        if (adAgeInDays >= 3 && adAgeInDays <= 6) {
          return { slug: "actual", label: `${adAgeInDays} dni temu` };
        }
        if (adAgeInDays === 7) {
          return { slug: "actual", label: "tydzień temu" };
        }
        if (adAgeInDays >= 8 && adAgeInDays <= 13) {
          return { slug: "actual", label: "ponad tydznień temu" };
        }
        if (adAgeInDays === 14) {
          return { slug: "actual", label: "2 tygodnie temu" };
        }
        if (adAgeInDays >= 15 && adAgeInDays <= 29) {
          return { slug: "actual", label: "ponad 2 tygodnie temu" };
        }
        if (adAgeInDays === 30) {
          return { slug: "actual", label: "miesiąc temu" };
        }
        if (adAgeInDays >= 31) {
          return { slug: "outdated", label: "ponad miesiąc temu" };
        }

        // Default return
        return { slug: "actual", label: `${adAgeInDays} dni temu` };
      },
    },
    details: {
      title: instance.details.title,
      description: instance.details.description,
      location: {
        city: instance.details.location.city,
        cityMarket: instance.details.location.cityMarket,
        point: {
          type: instance.details.location.point.type,
          coordinates: instance.details.location.point.coordinates,
        },
        get distanceFromUserKm() {
          // Get calculcated distance from mongo aggregation stage (relevant for List view)
          const distanceMongoInM = instance?.distance?.calculated;
          if (distanceMongoInM != null) {
            return distanceMongoInM / 1000;
          }

          const userProfile = useUserProfileStore();
          const userLocation = userProfile.getUserLocation({
            adTypePriority: "application",
            adClass: "nanny",
          });

          // Calculate distanse with haversine formulae
          const distanceHaversineInKm = haversine(
            userLocation.coordinates,
            this.point.coordinates
          );

          // Return by default
          return distanceHaversineInKm;
        },
        get label() {
          const distKm = this.distanceFromUserKm;
          const citySlug = this.city;

          return prepareGeoLabel({
            city: citySlug,
            distanceKm: distKm,
          });
        },
      },
      rate: {
        hour: instance.details.rate.hour,
        month: instance.details.rate.month,
      },
      photoMain: instance.details.photoMain,
      get photoMainColorNum() {
        const createdDate = new Date(instance.meta.createdAt);
        const sec = createdDate.getSeconds();
        const lastNum = Number(sec.toString().slice(-1));
        return lastNum;
      },
      phone: instance.details.phone,
      aupair: instance.details.aupair
        ? DICTIONARY.aupair[instance.details.aupair]
        : null,
      kids: prepareKids(instance.details.kids),
      isSpecialCare: instance.details.isSpecialCare,
      get specialCare() {
        if (this.isSpecialCare) {
          return DICTIONARY.special_care;
        }
        return null;
      },
    },
    employment: {
      type: DICTIONARY.employment.types[instance.employment.type],
      regularity: getSlugsFromDict(
        instance.employment.regularity,
        DICTIONARY.employment.regularities
      ),
    },
    schedule: {
      value: instance.schedule.value,
      comment: instance.schedule.comment ?? "",
      dateStart: getDateStart(new Date(instance.schedule.dateStart)),
      period: {
        term: getSlugsFromDict(
          instance.schedule.period.term,
          DICTIONARY.period.term
        ),
        measure: getSlugsFromDict(
          instance.schedule.period.measure,
          DICTIONARY.period.measure
        ),
        amount: instance.schedule.period.amount,
      },
    },
    requirements: {
      languages: getSlugsFromDict(
        instance.requirements.languages,
        DICTIONARY.languages
      ),
      responsibilities: {
        careChild: getSlugsFromDict(
          instance.requirements.responsibilities.careChild,
          DICTIONARY.responsibilities.childCare
        ),
        careHome: getSlugsFromDict(
          instance.requirements.responsibilities.careHome,
          DICTIONARY.responsibilities.homeCare
        ),
        activities: getSlugsFromDict(
          instance.requirements.responsibilities.activities,
          DICTIONARY.responsibilities.activities
        ),
      },
      perks: getSlugsFromDict(instance.requirements.perks, DICTIONARY.perks),
    },
  };
};
