import { prepareFullName, getSlugsFromDict } from "@/helpers";
import useApplicationNannyDictionary from "@/composables/useApplicationNannyDictionary";
import { useUserProfileStore } from "@/stores/userProfile";
import { haversine } from "./haversine";

const { DICTIONARY } = useApplicationNannyDictionary();

const getKidsAges = (slugs) => {
  if (slugs === null) {
    return null;
  }

  if (slugs.length === 0) {
    return [];
  }

  const i1 = slugs.includes("0_3") ? [0, "_", 3] : [];
  const i2 = slugs.includes("4_7") ? [4, "_", 7] : [];
  const i3 = slugs.includes("8_10") ? [8, "_", 10] : [];
  const i4 = slugs.includes("10_") ? [10, "_"] : [];

  let splittedSlugs = [...i1, ...i2, ...i3, ...i4];

  [...i1, ...i2, ...i3, ...i4].reduce((prevVal, currentVal, currentIndex) => {
    if (
      typeof currentVal === "number" &&
      typeof prevVal === "number" &&
      prevVal === currentVal - 1
    ) {
      splittedSlugs[currentIndex - 1] = undefined;
      splittedSlugs[currentIndex] = undefined;
      splittedSlugs[currentIndex + 1] = undefined;
    }

    return currentVal;
  });

  splittedSlugs = splittedSlugs.filter((item) => item !== undefined);

  const newSlugs = [];
  splittedSlugs.forEach((item, index, items) => {
    if (item === "_") {
      if (index === items.length - 1) {
        let lastNewSlug = newSlugs[newSlugs.length - 1];
        if (lastNewSlug && lastNewSlug.includes("10")) {
          // If previous interval includes 10 then it is the edge (like "8-10+ years")
          newSlugs[newSlugs.length - 1] = lastNewSlug + "_";
        } else {
          // Else 10+ is independent interval (like "10+ years")
          newSlugs.push(splittedSlugs[index - 1] + "_");
        }
      } else {
        newSlugs.push(
          splittedSlugs[index - 1] + "_" + splittedSlugs[index + 1]
        );
      }
    }
  });

  const result = getSlugsFromDict(
    newSlugs,
    DICTIONARY.experience.working.kidsAges
  );

  return result;
};

const prepareRecommendations = (recommendations) => {
  const readyRecommendations = [];

  // const verifyRecommendation = ({ proofName, proofContact }) => {
  //   return proofName && proofContact ? true : false;
  // };

  recommendations.forEach((recommendation) => {
    readyRecommendations.push({
      id: recommendation._id,
      source: recommendation.source,
      text: recommendation.text,
      rating: recommendation.rating,
      proofName: recommendation.proofName
        ? prepareFullName({ fullName: recommendation.proofName })
        : "Anonymus",
      proofContact: recommendation.proofContact,
      isVerified: recommendation.isVerified,
      // isVerified: verifyRecommendation({
      //   proofName: recommendation.proofName,
      //   proofContact: recommendation.proofContact,
      // }),
    });
  });

  return readyRecommendations;
};

const prepareGeoLabel = ({ city, distanceKm }) => {
  if (typeof distanceKm !== "number") {
    throw Error("Distance is not a Number!");
  }

  if (distanceKm < 1) {
    distanceKm = "<1 km";
  } else {
    distanceKm = distanceKm.toFixed(0) + " km";
  }

  return city + ", " + distanceKm;
};

const getAgeFromDob = (dobDate) => {
  const _MS_PER_YEAR = 1000 * 60 * 60 * 24 * 365.25;
  const nowUtcMs = Date.now();
  const dobUtcMs = Date.UTC(
    dobDate.getFullYear(),
    dobDate.getMonth(),
    dobDate.getDate()
  );

  const diffMs = nowUtcMs - dobUtcMs;
  const diffYrs = Math.floor(diffMs / _MS_PER_YEAR);

  return {
    years: diffYrs,
    postfix: "lat",
  };
};

const getDateStart = (dateStart) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const nowUtcMs = Date.now();

  const stDayYear = dateStart.getFullYear();
  const stDayMonth = dateStart.getMonth();
  const stDayDay = dateStart.getDate();

  const startUtcMs = Date.UTC(stDayYear, stDayMonth, stDayDay);

  const diffMs = startUtcMs - nowUtcMs;
  const diffDays = Math.floor(diffMs / _MS_PER_DAY);

  const dateString =
    diffDays > 0
      ? `${stDayDay.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}.${(stDayMonth + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}.${stDayYear}`
      : "niezwłocznie";

  return {
    dateUTC: startUtcMs,
    string: dateString,
    daysLeftBeforeStart: diffDays,
  };
};

export const prepareApplicationNannyInstance = (instance) => {
  return {
    _id: instance._id,
    get id() {
      return this._id;
    },
    user_id: instance.user_id,
    user_profile_id: instance.user_profile_id,
    user_profile_id_populated: {
      _id: instance.user_profile_id_populated._id,
      user_id: instance.user_profile_id_populated.user_id,
      nameFirst: instance.user_profile_id_populated.nameFirst,
      nameLast: instance.user_profile_id_populated.nameLast,
      nameFull: prepareFullName({
        firstName: instance.user_profile_id_populated.nameFirst,
        lastName: instance.user_profile_id_populated.nameLast,
      }),
      nameBusiness: instance.user_profile_id_populated.nameBusiness,
      zip: instance.user_profile_id_populated.zip,
      sex: instance.user_profile_id_populated.sex,
      dob: new Date(instance.user_profile_id_populated.dob),
      get age() {
        return getAgeFromDob(this.dob);
      },
      phone: instance.user_profile_id_populated.phone,
      lastSeen: new Date(instance.user_profile_id_populated.lastSeen), // Date
      activeness: getSlugsFromDict("actual", DICTIONARY.activeness), // Todo: refactor this
    },
    response_ids: instance.response_ids,
    // response_ids_populated: [],
    // outgoing_response_ids_populated: [],
    review_ids: instance.review_ids,
    // review_ids_populated: [],
    recommendation_ids: instance.recommendation_ids,
    get recommendation_ids_populated() {
      if (
        instance.recommendation_ids_populated &&
        instance.recommendation_ids_populated.length > 0
      ) {
        return prepareRecommendations(instance.recommendation_ids_populated);
      }

      return [];
    },
    meta: {
      visitor_user_ids: instance.meta.visitor_user_ids,
      visitsAll: instance.meta.visitsAll,
      visitsUnique: instance.meta.visitsUnique,
      clicksCtaUnique: instance.meta.clicksCtaUnique,
      status: instance.meta.status,
      createdAt: new Date(instance.meta.createdAt),
      refreshedAt: new Date(instance.meta.refreshedAt),
    },
    details: {
      description: instance.details.description,
      location: {
        city: instance.details.location.city,
        cityMarket: instance.details.location.cityMarket,
        point: {
          type: instance.details.location.point.type,
          coordinates: instance.details.location.point.coordinates,
        },
        get distanceFromUserKm() {
          // Get calculcated distance from mongo aggregation stage (relevant for List view)
          const distanceMongoInM = instance?.distance?.calculated;
          if (distanceMongoInM != null) {
            return distanceMongoInM / 1000;
          }

          const userProfile = useUserProfileStore();
          const userLocation = userProfile.getUserLocation({
            adTypePriority: "offer",
            adClass: "nanny",
          });

          // Calculate distanse with haversine formulae
          const distanceHaversineInKm = haversine(
            userLocation.coordinates,
            this.point.coordinates
          );

          // Return by default
          return distanceHaversineInKm;
        },
        get label() {
          const distKm = this.distanceFromUserKm;
          const citySlug = this.city;

          return prepareGeoLabel({
            city: citySlug,
            distanceKm: distKm,
          });
        },
      },
      rate: {
        hour: instance.details.rate.hour,
        month: instance.details.rate.month,
      },
      photoMain: instance.details.photoMain,
      get photoMainColorNum() {
        const createdDate = new Date(instance.meta.createdAt);
        const sec = createdDate.getSeconds();
        const lastNum = Number(sec.toString().slice(-1));
        return lastNum;
      },
      photoGallery: instance.details.photoGallery,
      aupair: instance.details.aupair
        ? DICTIONARY.aupair[instance.details.aupair]
        : null,
      languages: getSlugsFromDict(
        instance.details.languages,
        DICTIONARY.languages
      ),
    },
    employment: {
      types: getSlugsFromDict(
        instance.employment.types,
        DICTIONARY.employment.types
      ),
      regularities: getSlugsFromDict(
        instance.employment.regularities,
        DICTIONARY.employment.regularities
      ),
    },
    responsibilities: {
      careChild: getSlugsFromDict(
        instance.responsibilities.careChild,
        DICTIONARY.responsibilities.childCare
      ),
      careHome: getSlugsFromDict(
        instance.responsibilities.careHome,
        DICTIONARY.responsibilities.homeCare
      ),
      activities: getSlugsFromDict(
        instance.responsibilities.activities,
        DICTIONARY.responsibilities.activities
      ),
    },
    schedule: {
      value: instance.schedule.value,
      comment: instance.schedule.comment ?? "",
      dateStart: getDateStart(new Date(instance.schedule.dateStart)),
    },
    perks: getSlugsFromDict(instance.perks, DICTIONARY.perks),
    advantages: getSlugsFromDict(
      instance.perks.concat(instance.experience.life),
      DICTIONARY.advantages
    ),
    experience: {
      working: {
        isPresent: instance.experience.working.isPresent,
        years: instance.experience.working.years,
        kidsAges: getKidsAges(instance.experience.working.kidsAges),
        comment: instance.experience.working.comment ?? "",
      },
      life: getSlugsFromDict(
        instance.experience.life,
        DICTIONARY.experience.life
      ),
    },
    education: {
      degree: getSlugsFromDict(
        instance.education.degree,
        DICTIONARY.education.degrees
      ),
      types: getSlugsFromDict(
        instance.education.types,
        DICTIONARY.education.types
      ),
      courses: getSlugsFromDict(
        instance.education.courses,
        DICTIONARY.education.courses
      ),
      comment: instance.education.comment ?? "",
    },
  };
};
