export const dateToString = (date) => {
  if (date == null) {
    throw new Error(`Provide a valid date. Received: '${date}'`);
  }

  if (typeof date === "string") {
    date = new Date(date);
  }

  console.log("date to string!", date);
  //  Check this day!!!!!!!!!!!!!
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  console.log(day);
  day = day.toString().length === 1 ? "0" + day : day;
  month = month.toString().length === 1 ? "0" + month : month;
  return `${day} / ${month} / ${year}`;
};

export const stringToDate = (string) => {
  console.log(string);
  const dobArray = string.split(" / ");

  const day = Number(dobArray[0]);
  console.log(day);
  const month = Number(dobArray[1]) - 1;
  console.log(month);
  const year = Number(dobArray[2]);
  const date = new Date(year, month, day);
  // console.log(date);

  if (isNaN(date)) return null;

  return date;
};
