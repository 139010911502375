import { getApplicationsNannyFeed } from "@/api/applicationNanny";
import { getOffersNannyFeed } from "@/api/offerNanny";
import {
  prepareApplicationNannyInstance,
  prepareOfferNannyInstance,
} from "@/helpers";
import { defineStore } from "pinia";
import { useUserProfileStore } from "./userProfile";
import debounce from "lodash.debounce";
import { useUserStore } from "./user";

export const LIST_STORE_NAME = "LIST_STORE";

export const useListStore = defineStore(LIST_STORE_NAME, {
  state: () => ({
    offers: {
      nanny: {
        value: [],
        totalCount: 0,
        isNext: true,
        isFetching: false,
        isRefetching: false,
        isWaitingRefetching: false,
        filter: {
          settings: {
            aupair: null,
            employmentType: null,
            kidsAmountMax: null,
            languages: [],
          },
          allSeen: "all",
        },
        windowScrollY: 0,
      },
    },
    applications: {
      nanny: {
        value: [],
        totalCount: 0,
        isNext: true,
        isFetching: false,
        isRefetching: false,
        isWaitingRefetching: false,
        filter: {
          settings: {
            aupair: null,
            employmentType: null,
            employmentRegularity: null,
            experiences: [],
            activities: [],
            homeCare: [],
            languages: [],
            perks: [],
            feeHour: null,
            withPhotoOnly: null,
          },
          allSeen: "all",
        },
        windowScrollY: 0,
      },
    },
    class: "nanny",
    type: "offer",
    limit: 20,
    maxDistance: 30000, // in meters
    storeMeta: {
      // isInitializing: false,
      // isInitialized: false,
      // isFetching: false,
      error: null,
      // subscriptions: [],
      // watcherStopHandlers: [],
    },
  }),

  getters: {
    currentListItems() {
      const listType = this.type;
      const listClass = this.class;

      if (listType === "application") {
        if (listClass === "nanny") {
          return {
            items: this.applications.nanny.value,
            totalCount: this.applications.nanny.totalCount,
            class: listClass,
            type: listType,
            isInitialized: this.applications.nanny.isInitialized,
            isFetching: this.applications.nanny.isFetching,
          };
        }
      }

      if (listType === "offer") {
        if (listClass === "nanny") {
          return {
            items: this.offers.nanny.value,
            totalCount: this.offers.nanny.totalCount,
            class: listClass,
            type: listType,
            isInitialized: this.offers.nanny.isInitialized,
            isFetching: this.offers.nanny.isFetching,
          };
        }
      }

      return null;
    },

    preparedOfferNannyFilters() {
      const filtersPrepared = [{ meta_DOT_status: "published" }];
      const filters = this.offers.nanny.filter.settings;

      const user = useUserStore();
      const user_id = user._id;
      if (this.offers.nanny.filter.allSeen === "seen" && user_id) {
        filtersPrepared.push(
          // { meta_DOT_visitor_user_ids: { _OP_exists: true } },
          { meta_DOT_visitor_user_ids: user_id }
        );
      }

      if (filters?.aupair) {
        filtersPrepared.push({ details_DOT_aupair: filters.aupair });
      }

      if (filters?.employmentType) {
        filtersPrepared.push({ employment_DOT_type: filters.employmentType });
      }

      if (
        filters?.kidsAmountMax &&
        Number.isInteger(filters.kidsAmountMax) &&
        filters.kidsAmountMax === 1
      ) {
        filtersPrepared.push({
          details_DOT_kids: {
            _OP_exists: true,
            _OP_size: filters.kidsAmountMax,
          },
        });
      }

      if (
        filters?.kidsAmountMax &&
        Number.isInteger(filters.kidsAmountMax) &&
        filters.kidsAmountMax === 2
      ) {
        filtersPrepared.push(
          { details_DOT_kids: { _OP_exists: true } },
          {
            _OP_or: [
              { details_DOT_kids: { _OP_size: 1 } },
              { details_DOT_kids: { _OP_size: 2 } },
            ],
          }
        );
      }

      if (
        filters?.languages &&
        Array.isArray(filters.languages) &&
        filters.languages.length > 0
      ) {
        filtersPrepared.push({
          requirements_DOT_languages: { _OP_in: filters.languages },
        });
      }

      if (filtersPrepared.length === 0) {
        return {};
      }

      return {
        _OP_and: [...filtersPrepared],
      };

      // Ready filters example:
      //
      // const preparedOfferNannyFilters =
      // {
      //   $and: [
      //     { "details.aupair": filters.aupair },
      //     { "employment.type": filters.employmentType },
      //     { "details.kids": { $exists: true } },
      //     { "details.kids": { $lte: filters.kidsAmountMax } },
      //     {
      //       "details.languages": { $in: filters.languages },
      //     },
      //   ];
      // }
    },

    preparedApplicationNannyFilters() {
      const filtersPrepared = [{ meta_DOT_status: "published" }];
      const filters = this.applications.nanny.filter.settings;

      const user = useUserStore();
      const user_id = user._id;
      if (this.applications.nanny.filter.allSeen === "seen" && user_id) {
        filtersPrepared.push(
          // { meta_DOT_visitor_user_ids: { _OP_exists: true,  } },
          { meta_DOT_visitor_user_ids: user_id }
        );
      }

      if (filters?.aupair) {
        filtersPrepared.push({ details_DOT_aupair: filters.aupair });
      }

      if (filters?.employmentType) {
        filtersPrepared.push({ employment_DOT_types: filters.employmentType });
      }

      if (filters?.employmentRegularity) {
        filtersPrepared.push({
          employment_DOT_regularities: filters.employmentRegularity,
        });
      }

      if (
        filters?.experiences &&
        Array.isArray(filters.experiences) &&
        filters.experiences.length > 0
      ) {
        const filtersExpLife = filters.experiences.filter(
          (exp) => exp !== "working_experience"
        );

        if (filtersExpLife.length > 0) {
          filtersPrepared.push({
            experience_DOT_life: { _OP_all: filtersExpLife },
          });
        }

        const filtersIsExpWorking =
          filters.experiences.includes("working_experience");
        if (filtersIsExpWorking) {
          filtersPrepared.push({
            experience_DOT_working_DOT_isPresent: true,
          });
        }
      }

      if (
        filters?.activities &&
        Array.isArray(filters.activities) &&
        filters.activities.length > 0
      ) {
        filtersPrepared.push({
          responsibilities_DOT_activities: { _OP_all: filters.activities },
        });
      }

      if (
        filters?.homeCare &&
        Array.isArray(filters.homeCare) &&
        filters.homeCare.length > 0
      ) {
        filtersPrepared.push({
          responsibilities_DOT_careHome: { _OP_all: filters.homeCare },
        });
      }

      if (
        filters?.languages &&
        Array.isArray(filters.languages) &&
        filters.languages.length > 0
      ) {
        filtersPrepared.push({
          details_DOT_languages: { _OP_in: filters.languages },
        });
      }

      if (
        filters?.perks &&
        Array.isArray(filters.perks) &&
        filters.perks.length > 0
      ) {
        filtersPrepared.push({
          perks: { _OP_all: filters.perks },
        });
      }

      if (filters?.feeHour) {
        filtersPrepared.push({
          details_DOT_rate_DOT_hour: { _OP_lte: Number(filters.feeHour) },
        });
      }

      if (filters?.withPhotoOnly) {
        filtersPrepared.push({
          details_DOT_photoMain: { _OP_exists: true, _OP_ne: null },
        });
      }

      if (filtersPrepared.length === 0) {
        return {};
      }

      return {
        _OP_and: [...filtersPrepared],
      };

      // Ready filters example:
      //
      // const preparedApplicationNannyFilters =
      // {
      //   $and: [
      //     { "details.aupair": filters.aupair },
      //     { "employment.types": filters.employmentType },
      //     {
      //       "employment.regularities": filters.employmentRegularity,
      //     },
      //     {
      //       "experience.life": { $all: filtersExpLife },
      //     },
      //     {
      //       "experience.working.isPresent": true,
      //     },
      //     {
      //       "responsibilities.activities": { $all: filters.activities },
      //     },
      //     {
      //       "responsibilities.careHome": { $all: filters.homeCare },
      //     },
      //     {
      //       "details.languages": { $in: filters.languages },
      //     },
      //     {
      //       perks: { $all: filters.perks },
      //     },
      //     {
      //       "details.rate.hour": { $lte: filters.feeHour },
      //     },
      //     {
      //       "details.photoMain": { $exists: true, $ne: null },
      //     },
      //   ],
      // };
    },
  },

  actions: {
    async setOffersNannyToStore({ offers, offersTotalCount, offset }) {
      console.log("SET_OFFERS_NANNY_TO_STORE");

      if (offers.length === 0) return;

      // Preparing new offers
      const newOffersReadyPromises = [];
      offers.forEach((offer) => {
        newOffersReadyPromises.push(
          (async () => {
            return prepareOfferNannyInstance(offer);
          })()
        );
      });

      const newOffersReady = await Promise.all(newOffersReadyPromises);

      // Updating the store with new ready offers
      newOffersReady.forEach((offer, i) => {
        this.offers.nanny.value[offset + i] = offer;
      });

      this.offers.nanny.totalCount = offersTotalCount;
    },

    async setApplicationsNannyToStore({
      applications,
      applicationsTotalCount,
      offset,
    }) {
      console.log("SET_APPLICATIONS_NANNY_TO_STORE");

      console.log({
        applications,
        applicationsTotalCount,
        offset,
      });

      if (applications.length === 0) return;

      // Preparing new applications
      const newApplicationsReadyPromises = [];
      applications.forEach((application) => {
        newApplicationsReadyPromises.push(
          (async () => prepareApplicationNannyInstance(application))()
        );
      });

      const newApplicationsReady = await Promise.all(
        newApplicationsReadyPromises
      );

      // Updating the store with new ready applications
      newApplicationsReady.forEach((application, i) => {
        this.applications.nanny.value[offset + i] = application;
      });

      this.applications.nanny.totalCount = applicationsTotalCount;
    },

    async fetchOffersNanny() {
      try {
        if (this.offers.nanny.isNext === false) {
          return;
        }

        if (this.offers.nanny.isFetching === true) {
          return;
        }

        this.offers.nanny.isFetching = true;

        const userProfile = useUserProfileStore();
        const userLocation = userProfile.getUserLocation({
          adTypePriority: "application",
          adClass: "nanny",
        });

        const offset = this.offers.nanny.value.length;
        const limit = this.limit;
        const maxDistance = this.maxDistance;

        const { data } = await getOffersNannyFeed({
          filters: this.preparedOfferNannyFilters,
          userLocation: userLocation,
          maxDistance: maxDistance, // in meters
          populatePath: "user_profile_id_populated",
          offset: offset,
          limit: limit,
        });

        // Unfreeze data
        const offersNannyFeed = JSON.parse(
          JSON.stringify(data.offersNannyFeed)
        );

        await this.setOffersNannyToStore({
          offers: offersNannyFeed.value,
          offersTotalCount: offersNannyFeed.totalCount,
          offset: offersNannyFeed.offset,
        });

        if (offersNannyFeed.value.length < limit) {
          this.offers.nanny.isNext = false;
        }
      } finally {
        this.offers.nanny.isFetching = false;
      }
    },

    async fetchApplicationsNanny() {
      try {
        if (this.applications.nanny.isNext === false) {
          return;
        }

        if (this.applications.nanny.isFetching === true) {
          return;
        }

        this.applications.nanny.isFetching = true;

        const userProfile = useUserProfileStore();
        const userLocation = userProfile.getUserLocation({
          adTypePriority: "offer",
          adClass: "nanny",
        });

        const offset = this.applications.nanny.value.length;
        const limit = this.limit;
        const maxDistance = this.maxDistance;

        const { data } = await getApplicationsNannyFeed({
          filters: this.preparedApplicationNannyFilters,
          userLocation: userLocation,
          maxDistance: maxDistance, // in meters
          populatePath: "user_profile_id_populated",
          offset: offset,
          limit: limit,
        });

        // Unfreeze data
        const applicationsNannyFeed = JSON.parse(
          JSON.stringify(data.applicationsNannyFeed)
        );

        await this.setApplicationsNannyToStore({
          applications: applicationsNannyFeed.value,
          applicationsTotalCount: applicationsNannyFeed.totalCount,
          offset: applicationsNannyFeed.offset,
        });

        if (applicationsNannyFeed.value.length < limit) {
          this.applications.nanny.isNext = false;
        }
      } finally {
        this.applications.nanny.isFetching = false;
      }
    },

    clearOffersNanny() {
      console.log("CLEAR_OFFERS_NANNY");
      this.offers.nanny.isNext = true;
      this.offers.nanny.value = [];
    },

    clearApplicationsNanny() {
      console.log("CLEAR_APPLICATIONS_NANNY");
      this.applications.nanny.isNext = true;
      this.applications.nanny.value = [];
    },

    async refetchOffersNanny() {
      if (this.offers.nanny.isRefetching === true) {
        return;
      }

      if (this.offers.nanny.isWaitingRefetching === true) {
        return;
      }

      try {
        this.offers.nanny.isRefetching = true;

        this.clearOffersNanny();
        await this.fetchOffersNanny();
      } finally {
        this.offers.nanny.isRefetching = false;
      }
    },

    async refetchApplicationsNanny() {
      if (this.applications.nanny.isRefetching === true) {
        return;
      }

      if (this.applications.nanny.isWaitingRefetching === true) {
        return;
      }

      try {
        this.applications.nanny.isRefetching = true;

        this.clearApplicationsNanny();
        await this.fetchApplicationsNanny();
      } finally {
        this.applications.nanny.isRefetching = false;
      }
    },

    getRefetchOffersNannyDebounced() {
      this.offers.nanny.isWaitingRefetching = true;
      return debounce(() => {
        this.offers.nanny.isWaitingRefetching = false;
        this.refetchOffersNanny();
      }, 2000);
    },

    getRefetchApplicationsNannyDebounced() {
      this.applications.nanny.isWaitingRefetching = true;
      return debounce(() => {
        this.applications.nanny.isWaitingRefetching = false;
        this.refetchApplicationsNanny();
      }, 2000);
    },
  },
});
