import {
  archiveApplicationNanny,
  pauseApplicationNanny,
  refreshApplicationNanny,
  runApplicationNanny,
} from "@/api/applicationNanny";
import {
  archiveOfferNanny,
  pauseOfferNanny,
  refreshOfferNanny,
  runOfferNanny,
} from "@/api/offerNanny";
import { getUserWatchQueryObservable, refetchUser } from "@/api/user";
import { UPDATE_USER } from "@/graphql/mutations/user";
import {
  prepareApplicationNannyInstance,
  prepareOfferNannyInstance,
} from "@/helpers";
import { apolloClient } from "@/plugins/apollo";
import { useUserResponsesStore } from "@/stores/userResponses";
import { defineStore } from "pinia";
import { watch } from "vue";

export const USER_STORE_NAME = "USER_STORE";

export const useUserStore = defineStore(USER_STORE_NAME, {
  state: () => ({
    _id: null,
    user_profile_id: null,
    wishlist_id: null,
    // loginEmail: null,
    // loginGoogleId: null,
    // loginFacebookId: null,
    // password: null,
    email: "",
    emailIsVerified: false,
    emailIsVerificationLinkSent: false,
    role: "",
    userType: "client",
    accountType: "",
    websiteSettings: {
      language: "pl",
      country: "PL",
      city: "wro", //Default cityMarket
    },
    phoneVerifAttemptsLeft: 5,
    applications: {
      nanny_id: null,
      nanny_id_populated: null,
    },
    offers: {
      nanny_ids: [],
      nanny_ids_populated: [],
    },
    premiumPlans: {
      nanny: {
        plan_id: null,
        expirationDate: new Date(Date.now() + 3600000).toISOString(),
      },
    },

    // Local
    storeMeta: {
      isInitializing: false,
      isInitialized: false,
      isLoading: false,
      isUpdating: false,
      error: null,
      userQueryObservable: null,
    },
    routePathBeforeLogin: null,
    googleIdToken: null,
  }),

  getters: {
    hasPremiumNanny() {
      const expirationDate = this.premiumPlans.nanny.expirationDate;
      return (
        expirationDate !== null &&
        new Date(expirationDate).getTime() > new Date().getTime()
      );
    },

    isAuth() {
      return Boolean(this._id);
    },

    offersNannyReady() {
      const offersNannyRaw = this.offers.nanny_ids_populated;

      let result = [];

      if (Array.isArray(offersNannyRaw) === true) {
        result = offersNannyRaw.flatMap((offerNannyRaw) => {
          try {
            return prepareOfferNannyInstance({ ...offerNannyRaw });
          } catch (error) {
            console.log(error);
            return [];
          }
        });
      }

      return {
        value: result,
        class: "nanny",
        type: "offer",
        isFetching: this.storeMeta.isLoading,
      };
    },

    applicationNannyReady() {
      const applicationNannyRaw = this.applications.nanny_id_populated;
      let result = null;

      if (applicationNannyRaw !== null) {
        try {
          result = prepareApplicationNannyInstance({ ...applicationNannyRaw });
        } catch (error) {
          console.log(error);
          result = null;
        }
      }

      return {
        value: result,
        class: "nanny",
        type: "application",
        isFetching: this.storeMeta.isLoading,
      };
    },
  },

  actions: {
    resetStore() {
      console.log("RESET_USER_STORE");
      const userObservableQuery = this.storeMeta.userQueryObservable;
      if (
        userObservableQuery !== null &&
        userObservableQuery.observers?.length
      ) {
        userObservableQuery.observers.forEach((observer) =>
          observer.unsubscribe()
        );
      }
      this.$reset();
    },

    setDataToUserStore(data) {
      if (data === null) {
        throw new Error("Response 'Data' is null!");
      }

      if ("user" in data === false) {
        throw new Error("Response 'Data' misesses 'user' property!");
      }

      console.log("SET_DATA_TO_USER_STORE", data);

      // Unfreeze data
      const user = JSON.parse(JSON.stringify(data.user));

      this._id = user._id;
      this.user_profile_id = user.user_profile_id;
      this.wishlist_id = user.wishlist_id;
      this.email = user.email;
      this.emailIsVerified = user.emailIsVerified;
      this.emailIsVerificationLinkSent = user.emailIsVerificationLinkSent;
      this.role = user.role;
      this.userType = user.userType;
      this.accountType = user.accountType;
      this.websiteSettings.country = user.websiteSettings.country;
      this.websiteSettings.language = user.websiteSettings.language;
      this.websiteSettings.city = user.websiteSettings.city;
      this.phoneVerifAttemptsLeft = user.phoneVerifAttemptsLeft;
      this.applications.nanny_id = user.applications.nanny_id;
      this.applications.nanny_id_populated =
        user.applications.nanny_id_populated;

      this.offers.nanny_ids = user.offers.nanny_ids;

      if (user.offers.nanny_ids_populated === null) {
        this.offers.nanny_ids_populated = [];
      } else {
        this.offers.nanny_ids_populated = user.offers.nanny_ids_populated;
      }
    },

    async initUser(user_id) {
      try {
        if (this.storeMeta.isInitializing === true) {
          console.error("User is already initializing!");
          return null;
        }

        if (this.storeMeta.isInitialized === true) {
          throw new Error("User is already initialized!");
        }

        if (user_id === null) {
          throw new Error(
            `Received 'null' instead of 'user_id'! BTW, user was already initialized...`
          );
        }

        this.storeMeta.isInitializing = true;
        this.storeMeta.isLoading = true;

        // TODO: add polling!!!
        const userObservable = getUserWatchQueryObservable({
          user_id: user_id,
        });
        userObservable.subscribe(
          (result) => {
            this.setDataToUserStore(result.data);
            if (this.storeMeta.isInitialized === false) {
              this.storeMeta.isInitialized = true;
            }
            if (this.storeMeta.isInitializing === true) {
              this.storeMeta.isInitializing = false;
            }
          },
          (error) => {
            throw error;
          }
        );

        this.storeMeta.userQueryObservable = userObservable;

        this.initWatchers();

        return "OK";
      } finally {
        this.storeMeta.isLoading = false;
      }
    },

    initWatchers() {
      /**
       * Watch for user offers update
       */
      watch(
        () => this.offers.nanny_ids_populated,
        (newOffers, oldOffers) => {
          const newResponseIds = newOffers.flatMap(
            (offer) => offer.response_ids
          );
          const oldResponseIds = oldOffers.flatMap(
            (offer) => offer.response_ids
          );

          /**
           * New incoming responses on user offers -> fetch them to responses store
           */
          if (newResponseIds.length !== oldResponseIds.length) {
            const responsesStore = useUserResponsesStore();
            responsesStore.fetchIncomingApplicationNannyResponses(newOffers);
          }
        }
      );
    },

    async refetchUserStore() {
      const userQuery = this.storeMeta.userQueryObservable;
      if (userQuery === null) return;
      console.log("REFETCH_USER_STORE");

      try {
        this.storeMeta.isLoading = true;
        await refetchUser();
      } finally {
        this.storeMeta.isLoading = false;
      }
    },

    async updateUser() {
      this.storeMeta.isUpdating = true;

      const user_id = this._id;

      if (this.storeMeta.isInitialized === false) {
        this.storeMeta.isUpdating = false;
        throw new Error(`Init user first!`);
      }

      if (user_id === null) {
        this.storeMeta.isUpdating = false;
        throw new Error(
          `Recieved 'null' instead of 'user_id'! BTW, store was already initialized...`
        );
      }

      const { data } = await apolloClient.mutate({
        mutation: UPDATE_USER,
        variables: {
          id: user_id,
          payload: {
            user_profile_id: this.user_profile_id,
            wishlist_id: this.wishlist_id,
            email: this.email,
            role: this.role,
            userType: this.userType,
            // accountType: this.accountType,
            websiteSettings: {
              language: this.websiteSettings.language,
              country: this.websiteSettings.country,
              city: this.websiteSettings.city,
            },
            phoneVerifAttemptsLeft: this.phoneVerifAttemptsLeft,
            applications: {
              nanny_id: this.applications.nanny_id,
            },
            offers: {
              nanny_ids: this.offers.nanny_ids,
            },
          },
        },
      });

      if (data === null) {
        this.storeMeta.isUpdating = false;
        throw new Error("Response 'Data' is 'null'!");
      }

      if ("updateUser" in data === false) {
        this.storeMeta.isUpdating = false;
        throw new Error("Response 'Data' misesses 'updateUser' property!");
      }

      if ("_id" in data.updateUser === false) {
        this.storeMeta.isUpdating = false;
        throw new Error("'Data.updateUser' misesses '_id' property!");
      }

      this.storeMeta.isUpdating = false;
    },

    async refreshOffer({ id, offerClass }) {
      if (offerClass === "nanny") {
        await refreshOfferNanny({ offer_id: id });
        return;
      }

      throw new Error(
        `There is no refreshOffer method for offerClass === '${offerClass}' !`
      );
    },

    async refreshApplication({ id, applicationClass }) {
      if (applicationClass === "nanny") {
        await refreshApplicationNanny({ application_id: id });
        return;
      }

      throw new Error(
        `There is no refreshApplication method for applicationClass === '${applicationClass}' !`
      );
    },

    setOfferMetaStatusLocally({ offer_id, offerClass, newStatus }) {
      let offers = null;

      switch (offerClass) {
        case "nanny":
          offers = this.offers.nanny_ids_populated;
          break;

        default:
          throw new Error(
            `There is no setOfferMetaStatusLocally method for offerClass === '${offerClass}' !`
          );
      }

      if (Array.isArray(offers) === false) {
        throw new Error(
          "Offer's status wasn't changed locally, because typeof 'user.offers.****_ids_populated' !== 'array'"
        );
      }
      if (offers.length === 0) {
        throw new Error(
          "Offer's status wasn't changed locally, because there is no offers in the local array!"
        );
      }

      const targetOfferIndex = offers.findIndex(
        (offer) => offer._id === offer_id
      );
      const targetOffer = offers[targetOfferIndex];

      const updatedOffer = {
        ...targetOffer,
        meta: {
          ...targetOffer.meta,
          status: newStatus,
        },
      };

      offers[targetOfferIndex] = updatedOffer;
    },

    setApplicationMetaStatusLocally({ applicationClass, newStatus }) {
      let targetApplication = null;

      switch (applicationClass) {
        case "nanny":
          targetApplication = this.applications.nanny_id_populated;
          break;

        default:
          throw new Error(
            `There is no setApplicationMetaStatusLocally method for applicationClass === '${applicationClass}' !`
          );
      }

      if (targetApplication === null) {
        throw new Error(
          "Application status wasn't changed locally, because target application === null !"
        );
      }

      const updatedApplication = {
        ...targetApplication,
        meta: {
          ...targetApplication.meta,
          status: newStatus,
        },
      };

      this.applications.nanny_id_populated = updatedApplication;
    },

    async archiveOffer({ id, offerClass }) {
      if (id == null) {
        throw new Error(`Provide offer id! Received '${id}'`);
      }

      if (offerClass == null) {
        throw new Error(`Provide offer class! Received '${offerClass}'`);
      }

      if (offerClass === "nanny") {
        await archiveOfferNanny({ offer_id: id });
        this.setOfferMetaStatusLocally({
          offer_id: id,
          offerClass: offerClass,
          newStatus: "archived",
        });

        return;
      }

      throw new Error(
        `There is no archiveOffer method for offerClass === '${offerClass}' !`
      );
    },

    async runOffer({ id, offerClass }) {
      if (id == null) {
        throw new Error(`Provide offer id! Received '${id}'`);
      }

      if (offerClass == null) {
        throw new Error(`Provide offer class! Received '${offerClass}'`);
      }

      if (offerClass === "nanny") {
        await runOfferNanny({ offer_id: id });
        this.setOfferMetaStatusLocally({
          offer_id: id,
          offerClass: offerClass,
          newStatus: "published",
        });

        return;
      }

      throw new Error(
        `There is no runOffer method for offerClass === '${offerClass}' !`
      );
    },

    async pauseOffer({ id, offerClass }) {
      if (id == null) {
        throw new Error(`Provide offer id! Received '${id}'`);
      }

      if (offerClass == null) {
        throw new Error(`Provide offer class! Received '${offerClass}'`);
      }

      if (offerClass === "nanny") {
        await pauseOfferNanny({ offer_id: id });
        this.setOfferMetaStatusLocally({
          offer_id: id,
          offerClass: offerClass,
          newStatus: "paused",
        });

        return;
      }

      throw new Error(
        `There is no pauseOffer method for offerClass === '${offerClass}' !`
      );
    },

    async archiveApplication({ id, applicationClass }) {
      if (id == null) {
        throw new Error(`Provide application id! Received '${id}'`);
      }

      if (applicationClass == null) {
        throw new Error(
          `Provide application class! Received '${applicationClass}'`
        );
      }

      if (applicationClass === "nanny") {
        await archiveApplicationNanny({ application_id: id });
        this.setApplicationMetaStatusLocally({
          application_id: id,
          applicationClass: applicationClass,
          newStatus: "archived",
        });

        return;
      }

      throw new Error(
        `There is no archiveApplication method for applicationClass === '${applicationClass}' !`
      );
    },

    async runApplication({ id, applicationClass }) {
      if (id == null) {
        throw new Error(`Provide application id! Received '${id}'`);
      }

      if (applicationClass == null) {
        throw new Error(
          `Provide application class! Received '${applicationClass}'`
        );
      }

      if (applicationClass === "nanny") {
        await runApplicationNanny({ application_id: id });
        this.setApplicationMetaStatusLocally({
          application_id: id,
          applicationClass: applicationClass,
          newStatus: "published",
        });

        return;
      }

      throw new Error(
        `There is no runApplication method for applicationClass === '${applicationClass}' !`
      );
    },

    async pauseApplication({ id, applicationClass }) {
      if (id == null) {
        throw new Error(`Provide application id! Received '${id}'`);
      }

      if (applicationClass == null) {
        throw new Error(
          `Provide application class! Received '${applicationClass}'`
        );
      }

      if (applicationClass === "nanny") {
        await pauseApplicationNanny({ application_id: id });
        this.setApplicationMetaStatusLocally({
          application_id: id,
          applicationClass: applicationClass,
          newStatus: "paused",
        });

        return;
      }

      throw new Error(
        `There is no pauseApplication method for applicationClass === '${applicationClass}' !`
      );
    },

    async deleteUser() {},
  },
});
