<template>
  <div class="radio-button-container">
    <InputRadio
      v-for="option in options"
      :key="option.value ?? option.slug"
      v-model="result"
      :name="name"
      :value="option.value ?? option.slug"
      :label="option.label"
      :class="buttonClass"
    />
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";

import InputRadio from "./InputRadio.vue";

export default {
  components: { InputRadio },

  props: {
    modelValue: [String, Number, Array, Boolean],
    options: {
      required: true,
      type: Array,
    },
    name: {
      type: String,
    },
    widthSmall: {
      type: Boolean,
      default: false,
    },
    heightSmall: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const result = ref(props.modelValue);

    watch(result, (newResult) => {
      emit("update:modelValue", newResult);
    });

    watch(
      () => props.modelValue,
      (newResult) => {
        result.value = newResult;
      }
    );

    const buttonClass = computed(() => {
      return {
        "radio-button--height-small": props.heightSmall,
        "radio-button--width-small": props.widthSmall,
      };
    });

    return {
      buttonClass,
      result,
    };
  },
};
</script>

<style scoped>
.radio-button-container {
  display: flex;
  flex-flow: column;
  row-gap: 30px;
}
</style>
