import gql from "graphql-tag";

export const GET_OFFER_NANNY = gql`
  query OfferNanny($id: ID!, $populatePath: String) {
    offerNanny(_id: $id, populatePath: $populatePath) {
      _id
      user_id
      user_profile_id
      user_profile_id_populated {
        _id
        user_id
        nameFirst
        nameLast
        nameBusiness
        zip
        zipLocationPoint {
          type
          coordinates
        }
        sex
        dob
        phone
        lastSeen
      }
      response_ids
      meta {
        visitor_user_ids
        visitsAll
        visitsUnique
        clicksCtaUnique
        status
        createdAt
        refreshedAt
      }
      details {
        title
        description
        location {
          city
          cityMarket
          point {
            type
            coordinates
          }
        }
        rate {
          hour
          month
        }
        photoMain {
          src
          srcsetJpg
          srcsetWebp
          originalSrc
          originalFilename
          originalMimetype
          alt
        }
        phone
        aupair
        kids {
          id
          age
          sex
        }
        isSpecialCare
      }
      employment {
        type
        regularity
      }
      schedule {
        value
        comment
        dateStart
        period {
          term
          measure
          amount
        }
      }
      requirements {
        languages
        responsibilities {
          careChild
          careHome
          activities
        }
        perks
      }
    }
  }
`;

export const GET_OFFERS_NANNY = gql`
  query OffersNanny(
    $ids: [ID!]
    $filters: ListOfferNannyInput
    $populatePath: String
  ) {
    offersNanny(ids: $ids, filters: $filters, populatePath: $populatePath) {
      _id
      user_id
      user_profile_id
      user_profile_id_populated {
        _id
        user_id
        nameFirst
        nameLast
        nameBusiness
        zip
        zipLocationPoint {
          type
          coordinates
        }
        sex
        dob
        phone
        lastSeen
      }
      response_ids
      meta {
        visitor_user_ids
        visitsAll
        visitsUnique
        clicksCtaUnique
        status
        createdAt
        refreshedAt
      }
      details {
        title
        description
        location {
          city
          cityMarket
          point {
            type
            coordinates
          }
        }
        rate {
          hour
          month
        }
        photoMain {
          src
          srcsetJpg
          srcsetWebp
          originalSrc
          originalFilename
          originalMimetype
          alt
        }
        phone
        aupair
        kids {
          id
          age
          sex
        }
        isSpecialCare
      }
      employment {
        type
        regularity
      }
      schedule {
        value
        comment
        dateStart
        period {
          term
          measure
          amount
        }
      }
      requirements {
        languages
        responsibilities {
          careChild
          careHome
          activities
        }
        perks
      }
    }
  }
`;

export const GET_OFFERS_NANNY_FEED = gql`
  query OffersNannyFeed(
    $userLocation: FeedLocationPointInput!
    $maxDistance: Int!
    $offset: Int!
    $limit: Int!
    $populatePath: String
    $filters: FiltersOfferNannyInput
  ) {
    offersNannyFeed(
      userLocation: $userLocation
      maxDistance: $maxDistance
      offset: $offset
      limit: $limit
      populatePath: $populatePath
      filters: $filters
    ) {
      value {
        _id
        user_id
        user_profile_id
        user_profile_id_populated {
          _id
          user_id
          nameFirst
          nameLast
          nameBusiness
          zip
          zipLocationPoint {
            type
            coordinates
          }
          sex
          dob
          phone
          lastSeen
        }
        response_ids
        meta {
          visitor_user_ids
          visitsAll
          visitsUnique
          clicksCtaUnique
          status
          createdAt
          refreshedAt
        }
        details {
          title
          description
          location {
            city
            cityMarket
            address
            point {
              type
              coordinates
            }
          }
          rate {
            hour
            month
          }
          photoMain {
            src
            srcsetJpg
            srcsetWebp
            alt
            originalFilename
            originalSrc
            originalMimetype
          }
          phone
          aupair
          kids {
            id
            age
            sex
          }
          isSpecialCare
        }
        employment {
          type
          regularity
        }
        schedule {
          value
          comment
          dateStart
          period {
            term
            measure
            amount
          }
        }
        requirements {
          languages
          responsibilities {
            careChild
            careHome
            activities
          }
          perks
        }
        distance {
          calculated
          location {
            type
            coordinates
          }
        }
      }
      offset
      limit
      totalCount
    }
  }
`;
