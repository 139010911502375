<template>
  <h3 class="h3">
    <slot>{{ text }}</slot>
  </h3>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.h3 {
  display: block;
  font: var(--h3--font);
  line-height: normal;
  text-align: left;
}
</style>
