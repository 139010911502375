<template>
  <div class="layout-view-block-12">
    <div class="x1 layout-view-block-12__x1">
      <slot name="left"></slot>
    </div>
    <div class="x2 layout-view-block-12__x2">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.layout-view-block-12 {
  width: 100%;

  display: flex;
  column-gap: var(--layout-view-block-21--column-gap);
}
.layout-view-block-12__x1 {
  width: var(--layout-view-block-21--column-width);

  display: flex;
  justify-content: center;
}
.layout-view-block-12__x2 {
  width: calc(
    var(--layout-view-block-21--column-width) * 2 +
      var(--layout-view-block-21--column-gap)
  );

  display: flex;
  justify-content: center;
}

@media (max-width: 1200px) {
  .layout-view-block-12 {
    flex-wrap: wrap;
  }
  .layout-view-block-12__x2 {
    width: var(--layout-view-block-11--column-width);
  }
}
</style>
