<template>
  <div class="item-icon-label">
    <component :is="iconComponent" class="icon item-icon-label__icon" />
    <label class="label item-icon-label__label"> {{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    iconComponent: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.item-icon-label {
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
}
.item-icon-label__icon {
  min-width: 24px;
}
.item-icon-label__label {
  font: var(--font-small);
}
</style>
