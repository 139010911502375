import { fileToDataURLAsync } from "@/helpers";

// Getting validated and resized Img in base64 format
export const getImageSrcReadyFromFileAsync = async (file, requirements) => {
  // Checking browser compatibility
  if (typeof FileReader !== "function") {
    alert("Sorry, FileReader API not supported");
    throw new Error("FileReader API not supported");
  }

  // Reading file
  const imgSrcOriginal = await fileToDataURLAsync(file);

  // Converting file to image for more traction
  const img = new Image();
  img.src = imgSrcOriginal;

  // Waiting for img to load
  await img.decode();

  // Validation Started...

  let imgWidth = img.width;
  let imgHeight = img.height;
  const minWidth = requirements.minWidth;
  const minHeight = requirements.minHeight;
  const maxWidth = requirements.maxWidth;
  const maxHeight = requirements.maxHeight;

  // Ensure image width is more than minWidth
  if (imgWidth < minWidth) {
    let e = Error("Use another photo that matches minimal dimensions!");
    return e;
  }
  //  Ensure image height is more than minHeight
  if (imgHeight < minHeight) {
    let e = Error("Use another photo that matches minimal dimensions!");
    return e;
  }

  // Validation passed

  // Resizing image if its smallest side is larger than maxHeight OR maxWidth (to save memory)
  let canvas = document.createElement("canvas");
  if (imgWidth < imgHeight) {
    if (imgWidth > maxWidth) {
      imgHeight *= maxWidth / imgWidth;
      imgWidth = maxWidth;
    }
  } else {
    if (imgHeight > maxHeight) {
      imgWidth *= maxHeight / imgHeight;
      imgHeight = maxHeight;
    }
  }

  // Getting prepared image src
  canvas.width = imgWidth;
  canvas.height = imgHeight;
  canvas.getContext("2d").drawImage(img, 0, 0, imgWidth, imgHeight);
  let imgSrcReady = canvas.toDataURL(file.type);

  // Returning prepared image src
  return imgSrcReady;
};
